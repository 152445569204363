import 'react-toastify/dist/ReactToastify.css'

import { useCheckTokenLazyQuery } from '@graphql/generated/types'
import { Suspense, useEffect } from 'react'
import { ToastContainer } from 'react-toastify'

import { Environment, FullscreenLoader } from '@/components'
import { useLoader } from '@/core/hooks'
import { htmlScripts } from '@/core/services/htmlScript'
import { session } from '@/core/services/session'
import { AppRoutes } from '@/pages/AppRoutes'
import { useApp } from '@/store/app'
import { useAuthUser } from '@/store/user'
import { CssGlobal, CssReset } from '@/styles'

export function App() {
  const { setUser } = useAuthUser()
  const { setLoading, isLoading } = useApp()

  const { loadingAnimation, loadingPersist } = useLoader(isLoading)

  const endLoading = () => {
    setLoading(false)
  }

  const [checkTokenQuery] = useCheckTokenLazyQuery({
    onCompleted({ checkToken }) {
      setUser(checkToken)
      endLoading()
    },
    onError() {
      endLoading()
    },
  })

  useEffect(() => {
    htmlScripts.init()

    if (!session.get().accessToken) {
      endLoading()
      return
    }

    checkTokenQuery()
  }, [])

  return (
    <>
      {loadingPersist && (
        <FullscreenLoader isFadeOut={!isLoading} {...loadingAnimation} />
      )}
      <ToastContainer hideProgressBar autoClose={3000} />
      <CssReset />
      <CssGlobal />
      <Suspense fallback={<FullscreenLoader />}>
        {!isLoading && (
          <>
            {/* {user && <UserFeedback />}
            {!user && <UserFeedbackUnauth />} */}
            <AppRoutes />
          </>
        )}
      </Suspense>
      <Environment />
    </>
  )
}
