import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import downloadImg from '@/assets/icons/download.svg'
import { Loader } from '@/components'
import type { ReportsMetricOutput } from '@/graphql/generated/types'
import {
  useGetCsvForSubscriptionStatusLazyQuery,
  useGetCsvForVerifiedEducatorsLazyQuery,
  useMetricReportDataQuery,
} from '@/graphql/generated/types'

import type { DateQuery } from '../Overview'
import {
  DownloadButton,
  DownloadIcon,
  FlexColumn,
  FlexRow,
  MetricCard,
  MetricTitle,
  MetricValue,
  Row,
} from './MetricData.styled'

export function MetricData({ date }: { date: DateQuery }) {
  const { data: metricData, loading } = useMetricReportDataQuery({
    variables: { ...date },
  })

  const [
    getCsvForSubscriptionStatusTrial,
    { loading: isloadingGetCsvForTrial },
  ] = useGetCsvForSubscriptionStatusLazyQuery({
    variables: {
      status: 'Trial',
      ...date,
    },
    fetchPolicy: 'no-cache',
    onCompleted({ getCsvForSubscriptionStatus }) {
      downloadCSV(getCsvForSubscriptionStatus, 'trial.csv')
    },
  })

  const [
    getCsvForSubscriptionStatusCanceled,
    { loading: isloadingGetCsvForCanceled },
  ] = useGetCsvForSubscriptionStatusLazyQuery({
    variables: {
      status: 'Canceled',
      ...date,
    },
    fetchPolicy: 'no-cache',
    onCompleted({ getCsvForSubscriptionStatus }) {
      downloadCSV(getCsvForSubscriptionStatus, 'canceled.csv')
    },
  })

  const [
    getCsvForSubscriptionStatusPastDue,
    { loading: isloadingGetCsvForPastDue },
  ] = useGetCsvForSubscriptionStatusLazyQuery({
    variables: {
      status: 'PastDue',
      ...date,
    },
    fetchPolicy: 'no-cache',
    onCompleted({ getCsvForSubscriptionStatus }) {
      downloadCSV(getCsvForSubscriptionStatus, 'pastdue.csv')
    },
  })

  const [
    getCsvForVerifiedEducatorsActive,
    { loading: isLoadingGetCsvForVerifiedEducatorsActive },
  ] = useGetCsvForVerifiedEducatorsLazyQuery({
    variables: {
      status: 'Active',
      ...date,
    },
    fetchPolicy: 'no-cache',
    onCompleted({ getCsvForVerifiedEducators }) {
      downloadCSV(getCsvForVerifiedEducators, 'verified-active.csv')
    },
  })

  const [
    getCsvForVerifiedEducatorsInactive,
    { loading: isLoadingGetCsvForVerifiedEducatorsInactive },
  ] = useGetCsvForVerifiedEducatorsLazyQuery({
    variables: {
      status: 'Inactive',
      ...date,
    },
    fetchPolicy: 'no-cache',
    onCompleted({ getCsvForVerifiedEducators }) {
      downloadCSV(getCsvForVerifiedEducators, 'verified-inactive.csv')
    },
  })

  function downloadCSV(array: any[], filename: string) {
    const csv = convertArrayOfObjectsToCSV(array)
    const blob = new Blob([csv], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  function convertArrayOfObjectsToCSV(array: any[]) {
    const header = Object.keys(array[0]).join(',')
    const csv = array.map((obj) => Object.values(obj).join(',')).join('\n')
    return `${header}\n${csv}`
  }

  const metrics: ReportsMetricOutput = useMemo(() => {
    if (metricData) return metricData.metricReportData

    return {
      newActive: 0,
      activeAccounts: 0,
      deadAccounts: 0,
      newCancel: 0,
      newPastDue: 0,
      newTrial: 0,
      verifiedAccounts: 0,
      newlyRegistered: 0,
      renewalActive: 0,
      renewalCancel: 0,
      renewalPastDue: 0,
      renewalTrial: 0,
    }
  }, [metricData])

  const { t } = useTranslation('admin', { keyPrefix: 'overView' })

  return (
    <>
      <FlexColumn>
        <MetricValue>{t('educatorStats')}</MetricValue>
        <FlexRow>
          <MetricCard>
            {loading ? (
              <FlexRow>
                <Loader color="codeCurrent" size="2em" />
              </FlexRow>
            ) : (
              <>
                <MetricValue>{metrics.newlyRegistered}</MetricValue>
                <MetricTitle>{t('educators.registrations')}</MetricTitle>
              </>
            )}
          </MetricCard>
          <MetricCard>
            {loading ? (
              <FlexRow>
                <Loader color="codeCurrent" size="2em" />
              </FlexRow>
            ) : (
              <>
                <MetricValue>{metrics.verifiedAccounts}</MetricValue>
                <MetricTitle>{t('educators.active')}</MetricTitle>
              </>
            )}
          </MetricCard>
          <MetricCard>
            {loading ? (
              <FlexRow>
                <Loader color="codeCurrent" size="2em" />
              </FlexRow>
            ) : (
              <>
                <MetricValue>{metrics.activeAccounts}</MetricValue>
                <Row>
                  <MetricTitle>{t('educators.activeAccounts')}</MetricTitle>
                  <DownloadButton
                    disabled={metrics.activeAccounts === 0}
                    loading={isLoadingGetCsvForVerifiedEducatorsActive}
                    onClick={() => {
                      getCsvForVerifiedEducatorsActive()
                    }}
                  >
                    <DownloadIcon src={downloadImg} />
                  </DownloadButton>
                </Row>
              </>
            )}
          </MetricCard>
          <MetricCard>
            {loading ? (
              <FlexRow>
                <Loader color="codeCurrent" size="2em" />
              </FlexRow>
            ) : (
              <>
                <MetricValue>{metrics.deadAccounts}</MetricValue>
                <Row>
                  <MetricTitle>{t('educators.deadAccounts')}</MetricTitle>
                  <DownloadButton
                    disabled={metrics.deadAccounts === 0}
                    loading={isLoadingGetCsvForVerifiedEducatorsInactive}
                    onClick={() => {
                      getCsvForVerifiedEducatorsInactive()
                    }}
                  >
                    <DownloadIcon src={downloadImg} />
                  </DownloadButton>
                </Row>
              </>
            )}
          </MetricCard>

          <MetricCard>
            {loading ? (
              <FlexRow>
                <Loader color="codeCurrent" size="2em" />
              </FlexRow>
            ) : (
              <>
                <MetricValue>{metrics.newTrial}</MetricValue>

                <Row>
                  <MetricTitle>{t('educators.trialSub')}</MetricTitle>
                  <DownloadButton
                    disabled={metrics.newTrial === 0}
                    loading={isloadingGetCsvForTrial}
                    onClick={() => {
                      getCsvForSubscriptionStatusTrial()
                    }}
                  >
                    <DownloadIcon src={downloadImg} />
                  </DownloadButton>
                </Row>
              </>
            )}
          </MetricCard>
          <MetricCard>
            {loading ? (
              <FlexRow>
                <Loader color="codeCurrent" size="2em" />
              </FlexRow>
            ) : (
              <>
                <MetricValue>{metrics.newActive}</MetricValue>
                <MetricTitle>{t('educators.activeSub')}</MetricTitle>
              </>
            )}
          </MetricCard>
          <MetricCard>
            {loading ? (
              <FlexRow>
                <Loader color="codeCurrent" size="2em" />
              </FlexRow>
            ) : (
              <>
                <MetricValue>{metrics.newCancel}</MetricValue>
                <Row>
                  <MetricTitle>{t('educators.cancelledSub')}</MetricTitle>
                  <DownloadButton
                    disabled={metrics.newCancel === 0}
                    loading={isloadingGetCsvForCanceled}
                    onClick={() => {
                      getCsvForSubscriptionStatusCanceled()
                    }}
                  >
                    <DownloadIcon src={downloadImg} />
                  </DownloadButton>
                </Row>
              </>
            )}
          </MetricCard>

          <MetricCard>
            {loading ? (
              <FlexRow>
                <Loader color="codeCurrent" size="2em" />
              </FlexRow>
            ) : (
              <>
                <MetricValue>{metrics.newPastDue}</MetricValue>
                <Row>
                  <MetricTitle>{t('educators.pasSub')}</MetricTitle>
                  <DownloadButton
                    disabled={metrics.newPastDue === 0}
                    loading={isloadingGetCsvForPastDue}
                    onClick={() => {
                      getCsvForSubscriptionStatusPastDue()
                    }}
                  >
                    <DownloadIcon src={downloadImg} />
                  </DownloadButton>
                </Row>
              </>
            )}
          </MetricCard>
        </FlexRow>
      </FlexColumn>
      <FlexColumn>
        <MetricValue>{t('studentStats')}</MetricValue>
        <FlexRow>
          <MetricCard>
            {loading ? (
              <FlexRow>
                <Loader color="codeCurrent" size="2em" />
              </FlexRow>
            ) : (
              <>
                <MetricValue>{metrics.renewalTrial}</MetricValue>
                <MetricTitle>{t('students.trial')}</MetricTitle>
              </>
            )}
          </MetricCard>
          <MetricCard>
            {loading ? (
              <FlexRow>
                <Loader color="codeCurrent" size="2em" />
              </FlexRow>
            ) : (
              <>
                <MetricValue>{metrics.renewalActive}</MetricValue>
                <MetricTitle>{t('students.active')}</MetricTitle>
              </>
            )}
          </MetricCard>
        </FlexRow>
        <FlexRow>
          <MetricCard>
            {loading ? (
              <FlexRow>
                <Loader color="codeCurrent" size="2em" />
              </FlexRow>
            ) : (
              <>
                <MetricValue>{metrics.renewalCancel}</MetricValue>
                <MetricTitle>{t('students.cancelled')}</MetricTitle>
              </>
            )}
          </MetricCard>
          <MetricCard>
            {loading ? (
              <FlexRow>
                <Loader color="codeCurrent" size="2em" />
              </FlexRow>
            ) : (
              <>
                <MetricValue>{metrics.renewalPastDue}</MetricValue>
                <MetricTitle>{t('students.pasSub')}</MetricTitle>
              </>
            )}
          </MetricCard>
        </FlexRow>
      </FlexColumn>
    </>
  )
}
