import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import Logo from '@/assets/images/logo-with-rocket.png'
import type { IconName } from '@/components'
import { Icon, Tooltiped } from '@/components'
import { useUserParentProfileQuery } from '@/graphql/generated/types'
import { route } from '@/pages/routes'
import { useChild } from '@/store/parent/child'
import { useParentProfile } from '@/store/user'

import { Cart } from './components/Cart'
import { useNotifications } from './components/Notifications/useNotifications'
import { ProfileDropdown } from './components/ProfileDropdown'
import {
  Button,
  Counter,
  Header,
  Link,
  Links,
  LinkText,
  Logo as LogoStyled,
  Nav,
  RightPanel,
} from './Navbar.styled'

type LinkType = {
  icon: IconName
  textKey: string
  to: string
  isDisabled: boolean
  tooltipKey?: string
}

export function Navbar() {
  const { setProfile, profile } = useParentProfile()
  const navigate = useNavigate()
  const { t } = useTranslation('parent')
  const { pathname } = useLocation()
  const { data, loading } = useUserParentProfileQuery({
    fetchPolicy: 'no-cache',
  })
  const { current } = useChild()

  useEffect(() => {
    if (data && !loading) {
      setProfile(data.userParentProfile)
    }
  }, [data, loading])

  const isChildSubscribed = Boolean(current?.isSubscribed)

  const isOnFreemium = Boolean(current?.isOnFreemium)

  const { unreadCount, onRead } = useNotifications()

  const links: LinkType[] = [
    {
      icon: 'student',
      textKey: 'navbar.myStudents',
      to: route.parent.default(),
      isDisabled: false,
    },
    {
      icon: 'dashboard',
      textKey: 'navbar.dashboard',
      to: route.parent.analytics(),
      isDisabled: !isChildSubscribed && !isOnFreemium,
      tooltipKey: 'navbar.subscribeFirstAnalytics',
    },
    {
      icon: 'marketplace',
      textKey: 'navbar.marketplace',
      to: route.parent.marketplace(),
      isDisabled: !isChildSubscribed && !isOnFreemium,
      tooltipKey: 'navbar.subscribeFirstMarketplace',
    },
    {
      icon: 'resources',
      textKey: 'navbar.resources',
      to: route.parent.resources(),
      isDisabled: false,
    },
  ]

  return (
    <Header>
      <Nav>
        <LogoStyled
          role="button"
          tabIndex={0}
          src={Logo}
          alt="schoolio logo"
          onClick={() => {
            navigate(route.parent.default())
          }}
        />
        <Links>
          {links.map(({ textKey, to, icon, isDisabled, tooltipKey }) => (
            <Link
              key={textKey}
              to={to}
              $active={isDisabled ? false : to === pathname}
              $isDisabled={isDisabled}
              onClick={(event) => {
                if (isDisabled) {
                  event.preventDefault()
                }
              }}
            >
              <Tooltiped
                content={isDisabled && tooltipKey ? t(tooltipKey) : undefined}
              >
                <Icon icon={icon} />
                <LinkText>{t(textKey)}</LinkText>
              </Tooltiped>
            </Link>
          ))}
        </Links>

        <RightPanel>
          <Button
            onClick={(event) => {
              event.stopPropagation()
              navigate(route.parent.notifications())
              onRead()
            }}
          >
            <Icon icon="notification" />{' '}
            {unreadCount > 0 && <Counter>{unreadCount}</Counter>}
          </Button>

          <Cart
            isChildSubscribed={isChildSubscribed}
            isChildOnFreemium={isOnFreemium}
          />
          <ProfileDropdown imageSrc={profile?.profilePic} />
        </RightPanel>
      </Nav>
    </Header>
  )
}
