import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  Upload: any;
};

export type AccountTypeOutput = {
  __typename?: 'AccountTypeOutput';
  accountType?: Maybe<Scalars['String']>;
};

export type AddChildInput = {
  autoPilot?: Scalars['Boolean'];
  dateOfBirth: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  grade: Scalars['String'];
  image?: InputMaybe<Scalars['Upload']>;
  lastName: Scalars['String'];
  password: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  studentId?: InputMaybe<Scalars['String']>;
  userName: Scalars['String'];
};

export type AddEducatorRequiredInfoInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  countryCode: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  state: Scalars['String'];
};

export type AdsInfo = {
  __typename?: 'AdsInfo';
  adUrl: Scalars['String'];
  savedDate: Scalars['Date'];
  utmCampaign?: Maybe<Scalars['String']>;
  utmSource?: Maybe<Scalars['String']>;
};

export type AgreementOutput = {
  __typename?: 'AgreementOutput';
  content: Scalars['String'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

/** Assigned course to a particular student */
export type AssignCourse = {
  __typename?: 'AssignCourse';
  _id: Scalars['String'];
  allUnitsLoaded?: Maybe<Scalars['Boolean']>;
  assign_date: Scalars['Date'];
  child_finish_date?: Maybe<Scalars['Date']>;
  child_id?: Maybe<User>;
  child_id_del?: Maybe<User>;
  child_id_inac?: Maybe<User>;
  course_autoLoad: Scalars['Boolean'];
  course_description: Scalars['String'];
  course_duration: Scalars['Float'];
  course_grade?: Maybe<Grade>;
  course_image: Scalars['String'];
  course_isCreated: Scalars['Boolean'];
  course_name: Scalars['String'];
  course_price: Scalars['Float'];
  course_subject?: Maybe<Subject>;
  createdAt: Scalars['Date'];
  current_grade: Scalars['String'];
  current_lesson_id?: Maybe<AssignLesson>;
  current_tutorial_id?: Maybe<IdOnly>;
  finish_date?: Maybe<Scalars['Date']>;
  hide: Scalars['Boolean'];
  icon?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  isBundleCourse: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  iscurrent: Scalars['Boolean'];
  isfinished: Scalars['Boolean'];
  isstarted: Scalars['Boolean'];
  parent_id?: Maybe<User>;
  planet?: Maybe<Scalars['String']>;
  pregress: Scalars['Float'];
  schedule_date: Scalars['Date'];
  schedule_day: ScheduleDay;
  status: Status;
  taken_course_id?: Maybe<Course>;
  updatedAt: Scalars['Date'];
};

export type AssignCourseDetailsOutput = {
  __typename?: 'AssignCourseDetailsOutput';
  _id: Scalars['String'];
  completedLessons?: Maybe<Scalars['Float']>;
  currentLessonName: Scalars['String'];
  grade?: Maybe<ChildCoursesCourseGrade>;
  image: Scalars['String'];
  isCustom: Scalars['Boolean'];
  isDeletable: Scalars['Boolean'];
  isHidden: Scalars['Boolean'];
  isRecurringExist: Scalars['Boolean'];
  lessons?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  progress: Scalars['Float'];
  subject?: Maybe<ChildCoursesCourseSubject>;
  units: Array<AssignCourseDetailsUnit>;
};

export type AssignCourseDetailsUnit = {
  __typename?: 'AssignCourseDetailsUnit';
  _id: Scalars['String'];
  badge: Scalars['String'];
  completedLessons: Scalars['Float'];
  courseTakenId: Scalars['String'];
  hiddenLessons: Scalars['Float'];
  isHidden: Scalars['Boolean'];
  isRecurringExist: Scalars['Boolean'];
  lessons: Scalars['Float'];
  name: Scalars['String'];
  progress: Scalars['Float'];
};

export type AssignCourseInput = {
  childId: Scalars['String'];
  courseIds: Array<Scalars['String']>;
};

export type AssignForChildrenOutput = {
  __typename?: 'AssignForChildrenOutput';
  paymentUrl?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Assigned lesson to a particular student */
export type AssignLesson = {
  __typename?: 'AssignLesson';
  _id: Scalars['String'];
  assign_date_time?: Maybe<Scalars['Date']>;
  canRead: Scalars['Boolean'];
  child_finish_date_time?: Maybe<Scalars['Date']>;
  child_id?: Maybe<User>;
  course_id?: Maybe<AssignCourse>;
  createdAt: Scalars['Date'];
  current_lesson_page: Scalars['Float'];
  duration: Scalars['Float'];
  finish_date_time?: Maybe<Scalars['Date']>;
  hide: Scalars['Boolean'];
  icon?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  iscurrent: Scalars['Boolean'];
  isfinished: Scalars['Boolean'];
  isstarted: Scalars['Boolean'];
  lesson_audio: Scalars['String'];
  lesson_content: Scalars['String'];
  lesson_description: Scalars['String'];
  lesson_heading: Scalars['String'];
  lesson_name: Scalars['String'];
  lesson_number: Scalars['Float'];
  lesson_pdf: Scalars['String'];
  lesson_v: Array<LessonVideo>;
  lesson_v_l: Array<LessonVideoLink>;
  lesson_video_link: Array<Scalars['String']>;
  originLesson?: Maybe<Lesson>;
  parent_id?: Maybe<User>;
  pdf?: Maybe<Scalars['String']>;
  planet?: Maybe<Scalars['String']>;
  pregress: Scalars['Float'];
  quizTitle?: Maybe<Scalars['String']>;
  quizUrl?: Maybe<Scalars['String']>;
  schedule_date: Scalars['Date'];
  schedule_day?: Maybe<ScheduleDay>;
  status: Status;
  teacher_id?: Maybe<User>;
  tutorial_id?: Maybe<AssignUnit>;
  updatedAt: Scalars['Date'];
  youtubeLink: Scalars['String'];
};

export type AssignLessonContentOutput = {
  __typename?: 'AssignLessonContentOutput';
  _id: Scalars['String'];
  assignDateTime?: Maybe<Scalars['Date']>;
  childFinishDateTime?: Maybe<Scalars['Date']>;
  childId?: Maybe<Scalars['String']>;
  courseId?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  finishDateTime?: Maybe<Scalars['Date']>;
  isAnnotated: Scalars['Boolean'];
  isCurrent: Scalars['Boolean'];
  isFinished: Scalars['Boolean'];
  isStarted: Scalars['Boolean'];
  lessonId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  progress: Scalars['Float'];
  scheduleDay?: Maybe<ScheduleDay>;
  src: Scalars['String'];
  tag: Tag;
  teacherId?: Maybe<Scalars['String']>;
  tutorialId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
};

export type AssignLessonOrderInput = {
  childId: Scalars['String'];
  lessons: Array<Scalars['String']>;
  unitId: Scalars['String'];
};

export type AssignLessonOutput = {
  __typename?: 'AssignLessonOutput';
  _id: Scalars['String'];
  assignDateTime?: Maybe<Scalars['Date']>;
  canRead: Scalars['Boolean'];
  childFinishDateTime?: Maybe<Scalars['Date']>;
  childId?: Maybe<Scalars['String']>;
  courseId?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  currentLessonPage: Scalars['Float'];
  finishDateTime?: Maybe<Scalars['Date']>;
  isCurrent: Scalars['Boolean'];
  isFinished: Scalars['Boolean'];
  isHidden: Scalars['Boolean'];
  isStarted: Scalars['Boolean'];
  lessonAudio: Scalars['String'];
  lessonContent: Scalars['String'];
  lessonDescription: Scalars['String'];
  lessonHeading: Scalars['String'];
  lessonName?: Maybe<Scalars['String']>;
  lessonNumber: Scalars['Float'];
  lessonPdf: Scalars['String'];
  lessonV: Array<LessonVideoOutput>;
  lessonVideoLink: Array<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  pdf?: Maybe<Scalars['String']>;
  progress: Scalars['Float'];
  quizTitle?: Maybe<Scalars['String']>;
  quizUrl?: Maybe<Scalars['String']>;
  resources: Array<LessonResourceOutput>;
  scheduleDate: Scalars['Date'];
  scheduleDay?: Maybe<ScheduleDay>;
  status: Status;
  teacherId?: Maybe<Scalars['String']>;
  tutorialId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
  youtubeLink?: Maybe<Scalars['String']>;
};

/** Assigned unit to a particular student */
export type AssignUnit = {
  __typename?: 'AssignUnit';
  _id: Scalars['String'];
  assign_date_time?: Maybe<Scalars['Date']>;
  badge: Scalars['String'];
  child_finish_date_time?: Maybe<Scalars['Date']>;
  child_id?: Maybe<User>;
  course_id?: Maybe<AssignCourse>;
  createdAt: Scalars['Date'];
  current_grade: Scalars['String'];
  current_lesson_id?: Maybe<IdOnly>;
  finish_date_time?: Maybe<Scalars['Date']>;
  hide: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
  isBadgeCelebrated: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  iscurrent: Scalars['Boolean'];
  isfinished: Scalars['Boolean'];
  isstarted: Scalars['Boolean'];
  parent_id?: Maybe<User>;
  pdf?: Maybe<Scalars['String']>;
  pregress: Scalars['Float'];
  schedule_date: Scalars['Date'];
  schedule_day?: Maybe<ScheduleDay>;
  status: Status;
  teacher_id?: Maybe<User>;
  tutorial_description: Scalars['String'];
  tutorial_number: Scalars['Float'];
  tutorial_title: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type AssignUnitSelectOutput = {
  __typename?: 'AssignUnitSelectOutput';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type AutoPilotChildCourseSubject = {
  __typename?: 'AutoPilotChildCourseSubject';
  _id: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
};

export type AutoPilotChildCourses = {
  __typename?: 'AutoPilotChildCourses';
  _id: Scalars['String'];
  completedLessons: Scalars['Float'];
  icon?: Maybe<Scalars['String']>;
  image: Scalars['String'];
  isCustom: Scalars['Boolean'];
  isHidden: Scalars['Boolean'];
  lessons: Scalars['Float'];
  name: Scalars['String'];
  planet?: Maybe<Scalars['String']>;
  progress: Scalars['Float'];
  subject?: Maybe<AutoPilotChildCourseSubject>;
};

export type AutoPilotChildCoursesOutput = {
  __typename?: 'AutoPilotChildCoursesOutput';
  courses: Array<AutoPilotChildCourses>;
};

export type AutoPilotDetailsUnit = {
  __typename?: 'AutoPilotDetailsUnit';
  _id: Scalars['String'];
  completedLessons: Scalars['Float'];
  isHidden: Scalars['Boolean'];
  lessons: Scalars['Float'];
  name: Scalars['String'];
  progress: Scalars['Float'];
};

export type AutoPilotLessonByUnitOutput = {
  __typename?: 'AutoPilotLessonByUnitOutput';
  _id: Scalars['String'];
  isCompleted: Scalars['Boolean'];
  name: Scalars['String'];
};

export type BadgeCelebrationOutput = {
  __typename?: 'BadgeCelebrationOutput';
  _id: Scalars['String'];
  badge: Scalars['String'];
  courseId: Scalars['String'];
  name: Scalars['String'];
  progress: Scalars['Float'];
};

export type CheckCoursesPurchaseOutput = {
  __typename?: 'CheckCoursesPurchaseOutput';
  currentSessionUrl?: Maybe<Scalars['String']>;
  sessionStatus?: Maybe<SessionStatus>;
  success: Scalars['Boolean'];
};

export type CheckTokenOutput = {
  __typename?: 'CheckTokenOutput';
  _id: Scalars['String'];
  email: Scalars['String'];
  isOnFreemium?: Maybe<Scalars['Boolean']>;
  role: UserRole;
  username: Scalars['String'];
};

export type ChildCoursesCourse = {
  __typename?: 'ChildCoursesCourse';
  _id: Scalars['String'];
  completedLessons?: Maybe<Scalars['Float']>;
  currentLessonName: Scalars['String'];
  grade?: Maybe<ChildCoursesCourseGrade>;
  image: Scalars['String'];
  isCustom: Scalars['Boolean'];
  isDeletable: Scalars['Boolean'];
  isHidden: Scalars['Boolean'];
  isRecurringExist: Scalars['Boolean'];
  lessons?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  progress: Scalars['Float'];
  subject?: Maybe<ChildCoursesCourseSubject>;
};

export type ChildCoursesCourseGrade = {
  __typename?: 'ChildCoursesCourseGrade';
  _id: Scalars['String'];
  slug: Scalars['String'];
};

export type ChildCoursesCourseSubject = {
  __typename?: 'ChildCoursesCourseSubject';
  _id: Scalars['String'];
  slug: Scalars['String'];
};

export type ChildCoursesOutput = {
  __typename?: 'ChildCoursesOutput';
  courses: Array<ChildCoursesCourse>;
  pagination?: Maybe<Pagination>;
};

export type ChildDeletedCourse = {
  __typename?: 'ChildDeletedCourse';
  _id: Scalars['String'];
  completedLessons?: Maybe<Scalars['Float']>;
  currentLessonName: Scalars['String'];
  deletedOn: Scalars['Date'];
  grade?: Maybe<ChildCoursesCourseGrade>;
  image: Scalars['String'];
  isCustom: Scalars['Boolean'];
  isDeletable: Scalars['Boolean'];
  isHidden: Scalars['Boolean'];
  isRecoverable: Scalars['Boolean'];
  isRecurringExist: Scalars['Boolean'];
  lessons?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  progress: Scalars['Float'];
  subject?: Maybe<ChildCoursesCourseSubject>;
};

export type ChildDeletedCoursesOutput = {
  __typename?: 'ChildDeletedCoursesOutput';
  courses: Array<ChildDeletedCourse>;
  pagination?: Maybe<Pagination>;
};

export type ChildDetailedAnalyticsCourse = {
  __typename?: 'ChildDetailedAnalyticsCourse';
  _id: Scalars['String'];
  isCompleted: Scalars['Boolean'];
  lessons: Array<ChildDetailedAnalyticsLesson>;
  name: Scalars['String'];
};

export type ChildDetailedAnalyticsLesson = {
  __typename?: 'ChildDetailedAnalyticsLesson';
  _id: Scalars['String'];
  name: Scalars['String'];
  quizes: Array<ChildDetailedAnalyticsQuiz>;
};

export type ChildDetailedAnalyticsOutput = {
  __typename?: 'ChildDetailedAnalyticsOutput';
  _id: Scalars['String'];
  courses: Array<ChildDetailedAnalyticsCourse>;
  fullName: Scalars['String'];
};

export type ChildDetailedAnalyticsQuiz = {
  __typename?: 'ChildDetailedAnalyticsQuiz';
  _id: Scalars['String'];
  attempt: Scalars['Float'];
  questions: Array<ChildDetailedAnalyticsQuizQuestion>;
  score: Scalars['Float'];
  updatedAt: Scalars['Date'];
};

export type ChildDetailedAnalyticsQuizQuestion = {
  __typename?: 'ChildDetailedAnalyticsQuizQuestion';
  _id: Scalars['String'];
  correctResponse: Scalars['String'];
  isCorrect: Scalars['Boolean'];
  isMarkable: Scalars['Boolean'];
  learnerResponse: Scalars['String'];
  questionType: Scalars['String'];
  title: Scalars['String'];
};

export type ChildMetricOutput = {
  __typename?: 'ChildMetricOutput';
  activeSubscriptions: Scalars['Float'];
  cancelledSubscriptions: Scalars['Float'];
  pastDueSubscriptions: Scalars['Float'];
  trialSubscriptions: Scalars['Float'];
};

export type ChildPlanMetricOutput = {
  __typename?: 'ChildPlanMetricOutput';
  familyPlan: Scalars['Float'];
  individualMonth: Scalars['Float'];
  individualYear: Scalars['Float'];
};

export type ChildTranscriptCourse = {
  __typename?: 'ChildTranscriptCourse';
  _id: Scalars['String'];
  name: Scalars['String'];
  units: Array<ChildTranscriptUnit>;
};

export type ChildTranscriptOutput = {
  __typename?: 'ChildTranscriptOutput';
  _id: Scalars['String'];
  courses: Array<ChildTranscriptCourse>;
  fullName: Scalars['String'];
  schoolName?: Maybe<Scalars['String']>;
};

export type ChildTranscriptUnit = {
  __typename?: 'ChildTranscriptUnit';
  _id: Scalars['String'];
  courseId: Scalars['String'];
  isCompleted: Scalars['Boolean'];
  lessons: Array<ChildDetailedAnalyticsLesson>;
  name: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type ChildrenAnalyticsOutput = {
  __typename?: 'ChildrenAnalyticsOutput';
  _id: Scalars['String'];
  fullName: Scalars['String'];
  grade: GetChildrenGrade;
  mood?: Maybe<Scalars['String']>;
  profilePic: Scalars['String'];
  subjects: Array<ChildrenAnalyticsSubject>;
};

export type ChildrenAnalyticsSubject = {
  __typename?: 'ChildrenAnalyticsSubject';
  _id: Scalars['String'];
  progress: Scalars['Float'];
  score: Scalars['Float'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

/** Course */
export type Course = {
  __typename?: 'Course';
  MSCategory: Array<MsCategory>;
  _id: Scalars['String'];
  autoLoad: Scalars['Boolean'];
  country: CourseCountry;
  courseInfoVideoLink?: Maybe<Scalars['String']>;
  course_name: Scalars['String'];
  createdAt: Scalars['Date'];
  description: Scalars['String'];
  duration: Scalars['Float'];
  grade: Array<Grade>;
  icon?: Maybe<Scalars['String']>;
  image: Scalars['String'];
  isActive: Scalars['Boolean'];
  isBundleCourse: Scalars['Boolean'];
  isCreated: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  planet?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  status: Status;
  subject?: Maybe<Subject>;
  units?: Maybe<Array<Scalars['String']>>;
  updatedAt: Scalars['Date'];
};

export enum CourseCountry {
  Canada = 'CANADA',
  Usa = 'USA'
}

export type CourseDetails = {
  __typename?: 'CourseDetails';
  courseId?: Maybe<Course>;
  courseName?: Maybe<Scalars['String']>;
};

export type CourseDetailsLesson = {
  __typename?: 'CourseDetailsLesson';
  _id: Scalars['String'];
  name: Scalars['String'];
};

export type CourseDetailsOutput = {
  __typename?: 'CourseDetailsOutput';
  _id: Scalars['String'];
  courseInfoVideoLink?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  image: Scalars['String'];
  name: Scalars['String'];
  units: Array<CourseDetailsUnit>;
};

export type CourseDetailsUnit = {
  __typename?: 'CourseDetailsUnit';
  _id: Scalars['String'];
  lessons: Array<CourseDetailsLesson>;
  name: Scalars['String'];
};

export type CoursesSelectOutput = {
  __typename?: 'CoursesSelectOutput';
  grade?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  subject?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

export type CreateLessonInput = {
  childId: Scalars['String'];
  courseId: Scalars['String'];
  description: Scalars['String'];
  duration: Scalars['Float'];
  icon?: InputMaybe<Scalars['String']>;
  pdfs: Array<CreateLessonInputPdf>;
  planet?: InputMaybe<Scalars['String']>;
  resources: Array<CreateLessonInputResource>;
  title: Scalars['String'];
  unitId: Scalars['String'];
};

export type CreateLessonInputPdf = {
  file: Scalars['Upload'];
  tag: Tag;
};

export type CreateLessonInputResource = {
  thumbnail?: InputMaybe<Scalars['Upload']>;
  title: Scalars['String'];
  url: Scalars['String'];
};

export type CreateUnitOutput = {
  __typename?: 'CreateUnitOutput';
  _id: Scalars['String'];
  name: Scalars['String'];
};

export type DigitalAdsOutput = {
  __typename?: 'DigitalAdsOutput';
  _id: Scalars['String'];
  advertisementImage: Scalars['String'];
  bannerImage: Scalars['String'];
  content: Scalars['String'];
  description: Scalars['String'];
  destinationUrl: Scalars['String'];
  title: Scalars['String'];
};

export type Discount = {
  __typename?: 'Discount';
  amountOff?: Maybe<Scalars['Float']>;
  percentOff?: Maybe<Scalars['Float']>;
  promoCode?: Maybe<Scalars['String']>;
  promoCodeId?: Maybe<Scalars['String']>;
};

export type DownloadPdfOutput = {
  __typename?: 'DownloadPdfOutput';
  _id: Scalars['String'];
  childId: Scalars['String'];
  currentDownloads: Scalars['Float'];
  date: Scalars['Date'];
  isDeleted: Scalars['Boolean'];
  maxDownloads: Scalars['Float'];
};

export type EditChildGrade = {
  __typename?: 'EditChildGrade';
  _id: Scalars['String'];
  title: Scalars['String'];
};

export type EditChildInput = {
  _id: Scalars['String'];
  dateOfBirth: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  grade: Scalars['String'];
  image?: InputMaybe<Scalars['Upload']>;
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  studentId?: InputMaybe<Scalars['String']>;
  userName: Scalars['String'];
};

export type EditChildOutput = {
  __typename?: 'EditChildOutput';
  _id: Scalars['String'];
  grade: EditChildGrade;
};

export type EditLessonInput = {
  _id: Scalars['String'];
  description: Scalars['String'];
  duration: Scalars['Float'];
  icon?: InputMaybe<Scalars['String']>;
  pdfs: Array<EditLessonInputPdf>;
  planet?: InputMaybe<Scalars['String']>;
  resources: Array<EditLessonInputResource>;
  title: Scalars['String'];
};

export type EditLessonInputPdf = {
  _id?: InputMaybe<Scalars['String']>;
  file: Scalars['Upload'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  tag: Tag;
};

export type EditLessonInputResource = {
  _id?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  thumbnail?: InputMaybe<Scalars['Upload']>;
  title: Scalars['String'];
  url: Scalars['String'];
};

export type FinishLessonInput = {
  assignLessonId: Scalars['String'];
  plannerLessonId: Scalars['String'];
};

export type FinishLessonOutput = {
  __typename?: 'FinishLessonOutput';
  lessonData: AssignLessonOutput;
};

export type FinishLessonOutputAutoPilot = {
  __typename?: 'FinishLessonOutputAutoPilot';
  lessonData: AssignLessonOutput;
};

export type GetChildOutput = {
  __typename?: 'GetChildOutput';
  _id: Scalars['String'];
  dateOfBirth: Scalars['Date'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  grade: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  learningMode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  studentId?: Maybe<Scalars['String']>;
  userName: Scalars['String'];
};

export type GetChildProfileGrade = {
  __typename?: 'GetChildProfileGrade';
  _id: Scalars['String'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type GetChildProfileLastMoodCheck = {
  __typename?: 'GetChildProfileLastMoodCheck';
  _id: Scalars['String'];
  end?: Maybe<Scalars['Date']>;
  start?: Maybe<Scalars['Date']>;
};

export type GetChildProfileOutput = {
  __typename?: 'GetChildProfileOutput';
  _id: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  grade?: Maybe<GetChildProfileGrade>;
  hasMetaverse: Scalars['Boolean'];
  lastMoodCheck?: Maybe<GetChildProfileLastMoodCheck>;
  lastName: Scalars['String'];
  learningMode?: Maybe<Scalars['String']>;
  parentEmail?: Maybe<Scalars['String']>;
  profilePic: Scalars['String'];
  role: GetChildProfileRole;
  username: Scalars['String'];
};

export type GetChildProfileRole = {
  __typename?: 'GetChildProfileRole';
  _id: Scalars['String'];
  role: UserRole;
};

export type GetChildrenGrade = {
  __typename?: 'GetChildrenGrade';
  _id: Scalars['String'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type GetChildrenOutput = {
  __typename?: 'GetChildrenOutput';
  MSCategory?: Maybe<Array<MsCategory>>;
  _id: Scalars['String'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  grade: GetChildrenGrade;
  isDefaultChild: Scalars['Boolean'];
  isOnFreemium: Scalars['Boolean'];
  isSubscribed: Scalars['Boolean'];
  lastName: Scalars['String'];
  learningMode?: Maybe<Scalars['String']>;
  profilePic: Scalars['String'];
  subscriptionStatus: Scalars['String'];
  trialEndAt?: Maybe<Scalars['Date']>;
};

export type GetForMarketplaceInput = {
  childId: Scalars['String'];
  countries: CourseCountry;
  gradeId: Scalars['String'];
  limit: Scalars['Float'];
  page: Scalars['Float'];
  price: Array<Scalars['String']>;
  subjectIds: Array<Scalars['String']>;
  unit: Scalars['String'];
};

export type GetForOpenMarketplaceInput = {
  countries: CourseCountry;
  gradeId: Scalars['String'];
  limit: Scalars['Float'];
  page: Scalars['Float'];
  price: Array<Scalars['String']>;
  subjectIds: Array<Scalars['String']>;
  unit: Scalars['String'];
};

export type GetParentProfileOutput = {
  __typename?: 'GetParentProfileOutput';
  _id: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  countryCode: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  isFamilyAccount?: Maybe<Scalars['Boolean']>;
  isMSCategoryBasedUser?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  languages: Array<Scalars['String']>;
  lastName: Scalars['String'];
  maxFamilyChildren?: Maybe<Scalars['Float']>;
  membershipCode?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  profilePic: Scalars['String'];
  role: GetChildProfileRole;
  state: Scalars['String'];
  tourCompleted: Scalars['Boolean'];
  userDetailsConfirmed?: Maybe<Scalars['Boolean']>;
  username: Scalars['String'];
};

export type GetPromotionOutput = {
  __typename?: 'GetPromotionOutput';
  _id: Scalars['String'];
  description: Scalars['String'];
  interval: PromotionForInterval;
  isPersonalPromotion: Scalars['Boolean'];
  labelForFirstSubscription: Scalars['String'];
  labelForUpcomingSubscription: Scalars['String'];
  stripeCouponId: Scalars['String'];
};

export type GetSchoolProfileOutput = {
  __typename?: 'GetSchoolProfileOutput';
  _id: Scalars['String'];
  founderName?: Maybe<Scalars['String']>;
  isEnrollmentOpen?: Maybe<Scalars['Boolean']>;
  schoolDescription?: Maybe<Scalars['String']>;
  schoolImage?: Maybe<Scalars['String']>;
  schoolName?: Maybe<Scalars['String']>;
  schoolType?: Maybe<Array<SchoolType>>;
  showOnTranscript?: Maybe<Scalars['Boolean']>;
};

export type Grade = {
  __typename?: 'Grade';
  _id: Scalars['String'];
  content: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  order: Scalars['Float'];
  slug: Scalars['String'];
  status: Status;
  title: Scalars['String'];
};

export type HistoryCoursesOutput = {
  __typename?: 'HistoryCoursesOutput';
  _id: Scalars['String'];
  courseTakenId: Scalars['String'];
  name: Scalars['String'];
  progress: Scalars['Float'];
  units: Array<HistoryUnitsOutput>;
};

export type HistoryLessonOutput = {
  __typename?: 'HistoryLessonOutput';
  lessons: Array<ScheduledPlannerLesson>;
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type HistoryUnitsOutput = {
  __typename?: 'HistoryUnitsOutput';
  _id: Scalars['String'];
  badge: Scalars['String'];
  name: Scalars['String'];
  progress: Scalars['Float'];
};

export type IdOnly = {
  __typename?: 'IdOnly';
  _id: Scalars['String'];
};

export type InvoiceInfo = {
  __typename?: 'InvoiceInfo';
  amount_paid: Scalars['Float'];
  invoice_id_stripe: Scalars['String'];
  period_end: Scalars['Date'];
  period_start: Scalars['Date'];
};

export type Language = {
  __typename?: 'Language';
  _id: Scalars['String'];
  content: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  slug: Scalars['String'];
  status: Status;
  title: Scalars['String'];
};

export type LastMoodCheck = {
  __typename?: 'LastMoodCheck';
  _id: Scalars['String'];
  childId?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  end?: Maybe<LastMoodCheckValue>;
  start?: Maybe<LastMoodCheckValue>;
  updatedAt: Scalars['Date'];
};

export type LastMoodCheckValue = {
  __typename?: 'LastMoodCheckValue';
  date: Scalars['Date'];
  mood: Scalars['String'];
};

export enum LearningMode {
  AutoPilot = 'AutoPilot',
  Scheduled = 'Scheduled'
}

/** Lesson */
export type Lesson = {
  __typename?: 'Lesson';
  _id: Scalars['String'];
  audio: Scalars['String'];
  content: Scalars['String'];
  course_id?: Maybe<Course>;
  createdAt: Scalars['Date'];
  description: Scalars['String'];
  duration: Scalars['Float'];
  heading: Scalars['String'];
  isActive: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  lesson_name: Scalars['String'];
  lesson_number: Scalars['Float'];
  lesson_order: Scalars['String'];
  pdf: Scalars['String'];
  quizTitle: Scalars['String'];
  quizUrl: Scalars['String'];
  status: Status;
  tutorial_id?: Maybe<Unit>;
  updatedAt: Scalars['Date'];
  video: Array<Scalars['String']>;
  video_link: Array<Scalars['String']>;
  video_thumb: Array<Scalars['String']>;
  youtubeLink: Scalars['String'];
};

export type LessonAssignSelectOutput = {
  __typename?: 'LessonAssignSelectOutput';
  isAlreadyScheduled?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  value: Scalars['String'];
};

export type LessonByUnitOutput = {
  __typename?: 'LessonByUnitOutput';
  _id: Scalars['String'];
  isCompleted: Scalars['Boolean'];
  isHidden: Scalars['Boolean'];
  name: Scalars['String'];
  plannerLessons: Array<LessonByUnitPlannerLesson>;
};

export type LessonByUnitPlannerLesson = {
  __typename?: 'LessonByUnitPlannerLesson';
  _id: Scalars['String'];
  scheduleDate: Scalars['Date'];
};

export type LessonDetailsOutput = {
  __typename?: 'LessonDetailsOutput';
  _id: Scalars['String'];
  description: Scalars['String'];
  duration: Scalars['Float'];
  icon?: Maybe<Scalars['String']>;
  pdfs: Array<LessonDetailsOutputPdf>;
  planet?: Maybe<Scalars['String']>;
  resources: Array<LessonDetailsOutputResource>;
  title: Scalars['String'];
};

export type LessonDetailsOutputPdf = {
  __typename?: 'LessonDetailsOutputPdf';
  _id: Scalars['String'];
  file: Scalars['String'];
  tag: Tag;
};

export type LessonDetailsOutputResource = {
  __typename?: 'LessonDetailsOutputResource';
  _id: Scalars['String'];
  thumbnail?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  url: Scalars['String'];
};

export type LessonResourceOutput = {
  __typename?: 'LessonResourceOutput';
  link: Scalars['String'];
  thumb: Scalars['String'];
  title: Scalars['String'];
};

export type LessonType = {
  __typename?: 'LessonType';
  lesson_id: Scalars['String'];
  plannerLesson_id: Scalars['String'];
};

export type LessonVideo = {
  __typename?: 'LessonVideo';
  lesson_video: Scalars['String'];
  lesson_video_thumb: Scalars['String'];
};

export type LessonVideoLink = {
  __typename?: 'LessonVideoLink';
  _id: Scalars['String'];
  lesson_video_link: Scalars['String'];
  lesson_video_link_thumb: Scalars['String'];
  title: Scalars['String'];
};

export type LessonVideoOutput = {
  __typename?: 'LessonVideoOutput';
  lessonVideo: Scalars['String'];
  lessonVideoThumb: Scalars['String'];
};

export type MscSubscriptionPlan = {
  __typename?: 'MSCSubscriptionPlan';
  amount: Scalars['Float'];
  currency: Scalars['String'];
  interval: Scalars['String'];
  price_ids_stripe: Array<Scalars['String']>;
  product_id_stripe: Scalars['String'];
};

export enum MsCategory {
  Combo = 'Combo',
  Core = 'Core',
  Electives = 'Electives',
  FutureReadiness = 'FutureReadiness'
}

export type MarketplaceCourseGrade = {
  __typename?: 'MarketplaceCourseGrade';
  _id: Scalars['String'];
  slug: Scalars['String'];
};

export type MarketplaceCourseOutput = {
  __typename?: 'MarketplaceCourseOutput';
  courses: Array<MarketplaceCoursesCourse>;
  pagination: Pagination;
};

export type MarketplaceCourseSubject = {
  __typename?: 'MarketplaceCourseSubject';
  _id: Scalars['String'];
  slug: Scalars['String'];
};

export type MarketplaceCoursesCourse = {
  __typename?: 'MarketplaceCoursesCourse';
  MSCategory?: Maybe<Array<MsCategory>>;
  _id: Scalars['String'];
  grade?: Maybe<MarketplaceCourseGrade>;
  image: Scalars['String'];
  isBundleCourse: Scalars['Boolean'];
  name: Scalars['String'];
  price: Scalars['Float'];
  subject?: Maybe<MarketplaceCourseSubject>;
};

export type Microschool = {
  __typename?: 'Microschool';
  isEnrollmentOpen?: Maybe<Scalars['Boolean']>;
  schoolDescription?: Maybe<Scalars['String']>;
  schoolImage?: Maybe<Scalars['String']>;
  schoolName?: Maybe<Scalars['String']>;
  schoolType?: Maybe<Array<SchoolType>>;
  showOnTranscript?: Maybe<Scalars['Boolean']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addChild: Scalars['String'];
  addNote: NoteOutput;
  assignCourses: AssignForChildrenOutput;
  cancelSubscription: Scalars['Boolean'];
  changeChildPassword: Scalars['Boolean'];
  changeLessonCompletionStatus: Scalars['Boolean'];
  changeLessonQuizScore: Scalars['Boolean'];
  changeNameUnit: Scalars['String'];
  checkCoursesPurchase: CheckCoursesPurchaseOutput;
  checkForBadgeCelebration?: Maybe<BadgeCelebrationOutput>;
  createCustomCourse: ChildCoursesCourse;
  createLesson: Scalars['Boolean'];
  createPaymentSession: Scalars['String'];
  createUnit: CreateUnitOutput;
  deleteChild: Scalars['String'];
  deleteCourse: Scalars['String'];
  deleteNote: NoteOutput;
  deleteNotification: Notification;
  deleteNotifications: Array<Scalars['String']>;
  deleteRecurringEvent: Scalars['Boolean'];
  deleteScheduledPlannerLesson: Scalars['Boolean'];
  deleteUnit: Scalars['String'];
  disableMetaverse: Scalars['Boolean'];
  editChild: EditChildOutput;
  editLesson: Scalars['Boolean'];
  editNote: NoteOutput;
  editScheduleEntireCourse: Scalars['Boolean'];
  finishLesson: FinishLessonOutput;
  finishLessonAutoPilot: FinishLessonOutputAutoPilot;
  getDownloadDetailsToday: DownloadPdfOutput;
  markParentTourCompleted: Scalars['Boolean'];
  reactivateSubscription: Scalars['Boolean'];
  readNote: NoteOutput;
  readNotifications: Array<Scalars['String']>;
  recoverCourse: Scalars['String'];
  recoverSelectedFromMarketplace: Scalars['String'];
  refresh: SignInOutput;
  replaceWithNewCourse: Scalars['String'];
  requestResetPassword: RequestResetPasswordOutput;
  resendVerificationEmail: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  saveEducatorNames: Scalars['Boolean'];
  saveEvent: Scalars['Boolean'];
  saveFeedback: Scalars['Boolean'];
  savePdf: AssignLessonContentOutput;
  saveUnauthFeedback: Scalars['Boolean'];
  scheduleCourse: Scalars['Boolean'];
  scheduleLesson: ScheduledPlannerLesson;
  setAccountType: AccountTypeOutput;
  setLastMoodCheck: SetLastMoodCheckOutput;
  setSchoolProfile: GetSchoolProfileOutput;
  setUserProfile: GetParentProfileOutput;
  setVerifiedUserProfile: GetParentProfileOutput;
  signIn: SignInOutput;
  signUp: SignUpOutput;
  startLesson: StartLessonOutput;
  startLessonAutoPilot: StartAutoPilotLessonOutput;
  startParentLesson: StartParentLessonOutput;
  switchMSCPlan: Scalars['Boolean'];
  toggleChildAuto: GetChildOutput;
  toggleCourseHideUnhide: Scalars['Boolean'];
  toggleLessonHideUnhide: Scalars['Boolean'];
  toggleSchoolEnrollment: GetSchoolProfileOutput;
  toggleShowOnTranscript: GetSchoolProfileOutput;
  toggleUnitHideUnhide: Scalars['Boolean'];
  updateDownloadDetails: DownloadPdfOutput;
  updateLessonOrder: Scalars['Boolean'];
  updateScheduleLesson: SheduledLessonForCalendarOutput;
  upgradeToAnnual: Scalars['Boolean'];
  upgradeToAnnualMSC: Scalars['Boolean'];
  verifyEmail: SignInOutput;
  verifyUserDetails?: Maybe<VerifyUserDetails>;
};


export type MutationAddChildArgs = {
  addChildInput: AddChildInput;
};


export type MutationAddNoteArgs = {
  lessonId: Scalars['String'];
  name: Scalars['String'];
  note: Scalars['String'];
};


export type MutationAssignCoursesArgs = {
  childCourseIds: Array<AssignCourseInput>;
};


export type MutationCancelSubscriptionArgs = {
  childId: Scalars['String'];
  reason: Scalars['String'];
};


export type MutationChangeChildPasswordArgs = {
  childId: Scalars['String'];
  password: Scalars['String'];
};


export type MutationChangeLessonCompletionStatusArgs = {
  assignLessonId: Scalars['String'];
  childId: Scalars['String'];
  score?: InputMaybe<Scalars['Float']>;
};


export type MutationChangeLessonQuizScoreArgs = {
  childId: Scalars['String'];
  quizId: Scalars['String'];
  score: Scalars['Float'];
};


export type MutationChangeNameUnitArgs = {
  name: Scalars['String'];
  unitId: Scalars['String'];
};


export type MutationCheckCoursesPurchaseArgs = {
  sessionId: Scalars['String'];
};


export type MutationCheckForBadgeCelebrationArgs = {
  childId: Scalars['String'];
};


export type MutationCreateCustomCourseArgs = {
  childId: Scalars['String'];
  courseName: Scalars['String'];
};


export type MutationCreateLessonArgs = {
  createLessonInput: CreateLessonInput;
};


export type MutationCreatePaymentSessionArgs = {
  childId: Scalars['String'];
  couponId?: InputMaybe<Scalars['String']>;
  priceId: Scalars['String'];
};


export type MutationCreateUnitArgs = {
  childId: Scalars['String'];
  courseId: Scalars['String'];
  name: Scalars['String'];
};


export type MutationDeleteChildArgs = {
  childId: Scalars['String'];
};


export type MutationDeleteCourseArgs = {
  childId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationDeleteNoteArgs = {
  noteId: Scalars['String'];
};


export type MutationDeleteNotificationArgs = {
  id: Scalars['String'];
};


export type MutationDeleteNotificationsArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationDeleteRecurringEventArgs = {
  recurringEventId: Scalars['String'];
};


export type MutationDeleteScheduledPlannerLessonArgs = {
  scheduledLessonId: Scalars['String'];
};


export type MutationDeleteUnitArgs = {
  childId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationDisableMetaverseArgs = {
  childId: Scalars['String'];
};


export type MutationEditChildArgs = {
  editChildInput: EditChildInput;
};


export type MutationEditLessonArgs = {
  editLessonInput: EditLessonInput;
};


export type MutationEditNoteArgs = {
  content: Scalars['String'];
  noteId: Scalars['String'];
};


export type MutationEditScheduleEntireCourseArgs = {
  recurringEventId: Scalars['String'];
  scheduleCourseInput: ScheduleCourseInput;
};


export type MutationFinishLessonArgs = {
  finishLessonInput: FinishLessonInput;
};


export type MutationFinishLessonAutoPilotArgs = {
  lessonId: Scalars['String'];
};


export type MutationGetDownloadDetailsTodayArgs = {
  childId: Scalars['String'];
};


export type MutationReactivateSubscriptionArgs = {
  childId: Scalars['String'];
};


export type MutationReadNoteArgs = {
  noteId: Scalars['String'];
};


export type MutationReadNotificationsArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationRecoverCourseArgs = {
  childId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationRecoverSelectedFromMarketplaceArgs = {
  childId: Scalars['String'];
  takenCourseId: Scalars['String'];
};


export type MutationReplaceWithNewCourseArgs = {
  childId: Scalars['String'];
  takenCourseId: Scalars['String'];
};


export type MutationRequestResetPasswordArgs = {
  recaptchaToken: Scalars['String'];
  usernameOrEmail: Scalars['String'];
};


export type MutationResendVerificationEmailArgs = {
  usernameOrEmail: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  resetPasswordInput: ResetPasswordInput;
};


export type MutationSaveEducatorNamesArgs = {
  AddEducatorRequiredInfoInput: AddEducatorRequiredInfoInput;
};


export type MutationSaveEventArgs = {
  input: SaveEventInput;
};


export type MutationSaveFeedbackArgs = {
  input: SaveFeedbackInput;
};


export type MutationSavePdfArgs = {
  savePdfInput: SavePdfInput;
};


export type MutationSaveUnauthFeedbackArgs = {
  input: SaveUnauthFeedbackInput;
};


export type MutationScheduleCourseArgs = {
  scheduleCourseInput: ScheduleCourseInput;
};


export type MutationScheduleLessonArgs = {
  scheduleLessonInput: ScheduleLessonInput;
};


export type MutationSetAccountTypeArgs = {
  accountType: Scalars['String'];
};


export type MutationSetLastMoodCheckArgs = {
  input: SetLastMoodCheckInput;
};


export type MutationSetSchoolProfileArgs = {
  setSchoolProfileInput: SetSchoolProfileInput;
};


export type MutationSetUserProfileArgs = {
  setProfileInput: SetProfileInput;
};


export type MutationSetVerifiedUserProfileArgs = {
  verifyParentDetailsInput: VerifyParentDetailsInput;
};


export type MutationSignInArgs = {
  authCredentials: SignInInput;
};


export type MutationSignUpArgs = {
  signUpInput: SignUpInput;
};


export type MutationStartLessonArgs = {
  plannerLessonId: Scalars['String'];
};


export type MutationStartLessonAutoPilotArgs = {
  lessonId: Scalars['String'];
};


export type MutationStartParentLessonArgs = {
  lessonId: Scalars['String'];
};


export type MutationSwitchMscPlanArgs = {
  childId: Scalars['String'];
  priceId: Scalars['String'];
};


export type MutationToggleChildAutoArgs = {
  childId: Scalars['String'];
  learningMode: Scalars['String'];
};


export type MutationToggleCourseHideUnhideArgs = {
  courseId: Scalars['String'];
  isHidden: Scalars['Boolean'];
};


export type MutationToggleLessonHideUnhideArgs = {
  isHidden: Scalars['Boolean'];
  lessonId: Scalars['String'];
};


export type MutationToggleUnitHideUnhideArgs = {
  childId: Scalars['String'];
  isHidden: Scalars['Boolean'];
  unitId: Scalars['String'];
};


export type MutationUpdateDownloadDetailsArgs = {
  childId: Scalars['String'];
};


export type MutationUpdateLessonOrderArgs = {
  assignLessonOrderInput: AssignLessonOrderInput;
};


export type MutationUpdateScheduleLessonArgs = {
  updateScheduleLessonInput: UpdateScheduledLessonInput;
};


export type MutationUpgradeToAnnualArgs = {
  childId: Scalars['String'];
};


export type MutationUpgradeToAnnualMscArgs = {
  childId: Scalars['String'];
};


export type MutationVerifyEmailArgs = {
  code: Scalars['String'];
  recaptchaToken: Scalars['String'];
};

export type NoteOutput = {
  __typename?: 'NoteOutput';
  _id: Scalars['String'];
  authorId: Scalars['String'];
  childId?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  courseId?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  lessonId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  tutorialId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
  wasRead: Scalars['Boolean'];
};

/** Notification */
export type Notification = {
  __typename?: 'Notification';
  _id: Scalars['String'];
  advertisementImage: Scalars['String'];
  bannerImage: Scalars['String'];
  content: Scalars['String'];
  createdAt: Scalars['Date'];
  description: Scalars['String'];
  destinationUrl: Scalars['String'];
  grade?: Maybe<Grade>;
  heading: Scalars['String'];
  isBanner: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  isOneTimeAd: Scalars['Boolean'];
  isPublic: Scalars['Boolean'];
  isRead: Scalars['Boolean'];
  send_date: Scalars['Date'];
  sender_id?: Maybe<User>;
  status: Status;
  title: Scalars['String'];
  updatedAt: Scalars['Date'];
  user_id?: Maybe<User>;
};

export type NotificationOutput = {
  __typename?: 'NotificationOutput';
  _id: Scalars['String'];
  content: Scalars['String'];
  createdAt: Scalars['Date'];
  description: Scalars['String'];
  fullName: Scalars['String'];
  isPublic: Scalars['Boolean'];
  parentId?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Scalars['String']>;
  senderId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type NotificationsOutput = {
  __typename?: 'NotificationsOutput';
  notifications: Array<NotificationOutput>;
  pagination?: Maybe<Pagination>;
};

export type Pagination = {
  __typename?: 'Pagination';
  limit: Scalars['Float'];
  page: Scalars['Float'];
  pages: Scalars['Float'];
  total: Scalars['Float'];
};

export type ParentMetricOutput = {
  __typename?: 'ParentMetricOutput';
  activeAccounts: Scalars['Float'];
  deadAccounts: Scalars['Float'];
  newActive: Scalars['Float'];
  newCancel: Scalars['Float'];
  newPastDue: Scalars['Float'];
  newTrial: Scalars['Float'];
  newlyRegistered: Scalars['Float'];
  verifiedAccounts: Scalars['Float'];
};

export type PlanOutput = {
  __typename?: 'PlanOutput';
  MSCategory?: Maybe<MsCategory>;
  _id: Scalars['String'];
  interval: SubscriptionPlanInterval;
  isFamilyPlan: Scalars['Boolean'];
  isMSCategoryBased?: Maybe<Scalars['Boolean']>;
  postPriceText: Scalars['String'];
  prePriceText: Scalars['String'];
  price: Scalars['String'];
  stripePriceId: Scalars['String'];
};

export enum PlannerLessonStatus {
  Completed = 'Completed',
  InProgress = 'InProgress',
  NotStarted = 'NotStarted'
}

export enum PromotionForInterval {
  Month = 'Month',
  Year = 'Year'
}

export type PurchasedCourseInfo = {
  __typename?: 'PurchasedCourseInfo';
  childId?: Maybe<User>;
  courses?: Maybe<Array<CourseDetails>>;
};

export type Query = {
  __typename?: 'Query';
  annualFamilyPromotion?: Maybe<GetPromotionOutput>;
  annualPromotion?: Maybe<GetPromotionOutput>;
  checkToken: CheckTokenOutput;
  child: GetChildOutput;
  childAtLeastOneCourse: Scalars['Boolean'];
  childCourses: ChildCoursesOutput;
  childDetailedAnalytics: ChildDetailedAnalyticsOutput;
  childMetricStats: ChildMetricOutput;
  childSubscriptionTypesCount: ChildPlanMetricOutput;
  childTranscript: ChildTranscriptOutput;
  children: Array<GetChildrenOutput>;
  childrenAnalytics: Array<ChildrenAnalyticsOutput>;
  countryReportData: Array<ReportsLocationOutput>;
  courseDetails: AssignCourseDetailsOutput;
  courseDetailsForMarketplace: CourseDetailsOutput;
  courseIcons: Array<Scalars['String']>;
  coursePlanets: Array<Scalars['String']>;
  coursesByChildAutoPilot: AutoPilotChildCoursesOutput;
  coursesForCalendarSelect: Array<CoursesSelectOutput>;
  customerPortalLink: Scalars['String'];
  deletedCourses: ChildDeletedCoursesOutput;
  getAccountType?: Maybe<AccountTypeOutput>;
  getBannerAdvertisements: Array<DigitalAdsOutput>;
  getChildAccountType?: Maybe<AccountTypeOutput>;
  getCsvForSubscriptionStatus: Array<CsvIndividualMetricOutput>;
  getCsvForVerifiedEducators: Array<CsvIndividualEducatorMetricOutput>;
  getOneTimeAdvertisementAndMarkRead?: Maybe<DigitalAdsOutput>;
  getPaymentsByDate: Array<ReportsDateOutput>;
  getStudentsByGradeCountry: Array<StudentsForReports>;
  grades: Array<Grade>;
  historyLessons: Array<HistoryLessonOutput>;
  isCourseRecoverableFromDeletedList: Scalars['Boolean'];
  isEmailAvailable: Scalars['Boolean'];
  isPhoneNumberAvailable: Scalars['Boolean'];
  isUsernameAvailable: Scalars['Boolean'];
  languages: Array<Language>;
  lessonDetails: LessonDetailsOutput;
  lessonDetailsAutoPilot?: Maybe<LessonDetailsOutput>;
  lessonsByUnit: Array<LessonByUnitOutput>;
  lessonsByUnitAutoPilot: Array<AutoPilotLessonByUnitOutput>;
  lessonsForCalendarSelect: Array<LessonAssignSelectOutput>;
  locationReportData: Array<ReportsLocationOutput>;
  locationReportsByDate: Array<ReportsDateOutput>;
  marketplaceCourses: MarketplaceCourseOutput;
  metricReportData: ReportsMetricOutput;
  nameExistOnProfile: Scalars['Boolean'];
  notesByLessonId: Array<NoteOutput>;
  notifications: Array<Notification>;
  notificationsForPage: NotificationsOutput;
  openMarketplaceCourses: MarketplaceCourseOutput;
  parentsMetricStats: ParentMetricOutput;
  plannerLessons: ScheduledPlannerLessonsOutput;
  privacyPolicy: AgreementOutput;
  recurringEvent?: Maybe<RecurringEventOutput>;
  referralSourceOptions: Array<ReferralSourceOptionOutput>;
  registrationsByDate: Array<SubscriptionsByDate>;
  scheduledLessonsForCalendar: StudentLessons;
  scheduledPlannerLessonForCalendar: SheduledLessonForCalendarOutput;
  searchChildCourses: Array<ChildCoursesCourse>;
  searchHistoryLessons: Array<HistoryLessonOutput>;
  searchLessonsByCourseAutoPilot: Array<AutoPilotLessonByUnitOutput>;
  statesReportsByDate: Array<StateReportsByDate>;
  subjects: Array<Subject>;
  subscriptionDetails?: Maybe<SubscriptionDetailsOutput>;
  subscriptionFamilyPlans: Array<PlanOutput>;
  subscriptionPlans: Array<PlanOutput>;
  subscriptionPlansMSCBased: Array<PlanOutput>;
  subscriptionSwitchAndUpgradeDetailsForMSC?: Maybe<SubscriptionSwitchAndUpgradeDetailsForMscOutPut>;
  subscriptionsByDate: Array<SubscriptionsByDate>;
  termsAndConditions: AgreementOutput;
  unitsByCourseAutoPilot: Array<AutoPilotDetailsUnit>;
  unitsForBadges: Array<HistoryCoursesOutput>;
  unitsForCalendarSelect: Array<AssignUnitSelectOutput>;
  userChildProfile: GetChildProfileOutput;
  userCountriesReport: Array<UserLocations>;
  userParentProfile: GetParentProfileOutput;
  userSchoolProfile: GetSchoolProfileOutput;
};


export type QueryChildArgs = {
  id: Scalars['String'];
};


export type QueryChildAtLeastOneCourseArgs = {
  childId: Scalars['String'];
};


export type QueryChildCoursesArgs = {
  childId: Scalars['String'];
  limit?: InputMaybe<Scalars['Float']>;
  page?: InputMaybe<Scalars['Float']>;
};


export type QueryChildDetailedAnalyticsArgs = {
  childId: Scalars['String'];
};


export type QueryChildMetricStatsArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};


export type QueryChildSubscriptionTypesCountArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};


export type QueryChildTranscriptArgs = {
  childId: Scalars['String'];
};


export type QueryCountryReportDataArgs = {
  country: Scalars['String'];
  location: Scalars['String'];
};


export type QueryCourseDetailsArgs = {
  childId: Scalars['String'];
  courseId: Scalars['String'];
};


export type QueryCourseDetailsForMarketplaceArgs = {
  id: Scalars['String'];
};


export type QueryCoursesForCalendarSelectArgs = {
  childId: Scalars['String'];
};


export type QueryDeletedCoursesArgs = {
  childId: Scalars['String'];
  limit?: InputMaybe<Scalars['Float']>;
  page?: InputMaybe<Scalars['Float']>;
};


export type QueryGetBannerAdvertisementsArgs = {
  studentId?: InputMaybe<Scalars['String']>;
};


export type QueryGetCsvForSubscriptionStatusArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  status: Scalars['String'];
};


export type QueryGetCsvForVerifiedEducatorsArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  status: Scalars['String'];
};


export type QueryGetPaymentsByDateArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  paying: Scalars['Boolean'];
  startDate?: InputMaybe<Scalars['String']>;
};


export type QueryGetStudentsByGradeCountryArgs = {
  country: Scalars['String'];
  grade: Scalars['String'];
};


export type QueryIsCourseRecoverableFromDeletedListArgs = {
  childId: Scalars['String'];
  courseId: Scalars['String'];
};


export type QueryIsEmailAvailableArgs = {
  email: Scalars['String'];
};


export type QueryIsPhoneNumberAvailableArgs = {
  phoneNumber: Scalars['String'];
  userId: Scalars['String'];
};


export type QueryIsUsernameAvailableArgs = {
  userName: Scalars['String'];
};


export type QueryLessonDetailsArgs = {
  id: Scalars['String'];
};


export type QueryLessonDetailsAutoPilotArgs = {
  courseId: Scalars['String'];
};


export type QueryLessonsByUnitArgs = {
  childId: Scalars['String'];
  unitId: Scalars['String'];
};


export type QueryLessonsByUnitAutoPilotArgs = {
  unitId: Scalars['String'];
};


export type QueryLessonsForCalendarSelectArgs = {
  unitId: Scalars['String'];
};


export type QueryLocationReportDataArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};


export type QueryLocationReportsByDateArgs = {
  country?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};


export type QueryMarketplaceCoursesArgs = {
  input: GetForMarketplaceInput;
};


export type QueryMetricReportDataArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};


export type QueryNotesByLessonIdArgs = {
  lessonId: Scalars['String'];
};


export type QueryNotificationsForPageArgs = {
  limit?: InputMaybe<Scalars['Float']>;
  page?: InputMaybe<Scalars['Float']>;
  studentId?: InputMaybe<Scalars['String']>;
};


export type QueryOpenMarketplaceCoursesArgs = {
  input: GetForOpenMarketplaceInput;
};


export type QueryParentsMetricStatsArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};


export type QueryRecurringEventArgs = {
  childId: Scalars['String'];
  courseId: Scalars['String'];
  unitId?: InputMaybe<Scalars['String']>;
};


export type QueryRegistrationsByDateArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};


export type QueryScheduledLessonsForCalendarArgs = {
  childId: Scalars['String'];
  from: Scalars['String'];
  to: Scalars['String'];
};


export type QueryScheduledPlannerLessonForCalendarArgs = {
  childId: Scalars['String'];
  scheduledLessonId: Scalars['String'];
};


export type QuerySearchChildCoursesArgs = {
  childId: Scalars['String'];
  query: Scalars['String'];
};


export type QuerySearchHistoryLessonsArgs = {
  query: Scalars['String'];
};


export type QuerySearchLessonsByCourseAutoPilotArgs = {
  courseId: Scalars['String'];
  query: Scalars['String'];
};


export type QueryStatesReportsByDateArgs = {
  country?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};


export type QuerySubscriptionDetailsArgs = {
  childId: Scalars['String'];
};


export type QuerySubscriptionSwitchAndUpgradeDetailsForMscArgs = {
  childId: Scalars['String'];
};


export type QuerySubscriptionsByDateArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};


export type QueryUnitsByCourseAutoPilotArgs = {
  courseId: Scalars['String'];
};


export type QueryUnitsForBadgesArgs = {
  childId: Scalars['String'];
};


export type QueryUnitsForCalendarSelectArgs = {
  courseId: Scalars['String'];
};

export type RecurringEventOutput = {
  __typename?: 'RecurringEventOutput';
  childId?: Maybe<Scalars['String']>;
  courseId?: Maybe<Scalars['String']>;
  interval?: Maybe<Scalars['Float']>;
  recurringEventId: Scalars['String'];
  scheduleDays: Array<Scalars['String']>;
  scheduleTime?: Maybe<Scalars['Date']>;
  unitId: Scalars['String'];
};

export type ReferralSourceOptionOutput = {
  __typename?: 'ReferralSourceOptionOutput';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type ReportsDateOutput = {
  __typename?: 'ReportsDateOutput';
  date: Scalars['String'];
  value: Scalars['Float'];
};

export type ReportsLocationOutput = {
  __typename?: 'ReportsLocationOutput';
  location: Scalars['String'];
  value: Scalars['Float'];
};

export type ReportsMetricOutput = {
  __typename?: 'ReportsMetricOutput';
  activeAccounts: Scalars['Float'];
  deadAccounts: Scalars['Float'];
  newActive: Scalars['Float'];
  newCancel: Scalars['Float'];
  newPastDue: Scalars['Float'];
  newTrial: Scalars['Float'];
  newlyRegistered: Scalars['Float'];
  renewalActive: Scalars['Float'];
  renewalCancel: Scalars['Float'];
  renewalPastDue: Scalars['Float'];
  renewalTrial: Scalars['Float'];
  verifiedAccounts: Scalars['Float'];
};

export type RequestResetPasswordOutput = {
  __typename?: 'RequestResetPasswordOutput';
  email: Scalars['String'];
};

export type ResetPasswordInput = {
  password: Scalars['String'];
  recaptchaToken: Scalars['String'];
  token: Scalars['String'];
};

/** User's role */
export type Role = {
  __typename?: 'Role';
  _id: Scalars['String'];
  desc: Scalars['String'];
  role: UserRole;
  roleDisplayName: Scalars['String'];
};

export type SaveEventInput = {
  initiatorId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  propsJson?: InputMaybe<Scalars['String']>;
};

export type SaveFeedbackInput = {
  message: Scalars['String'];
  screenshots: Array<Scalars['Upload']>;
  submissionCategoryLabel: Scalars['String'];
  submissionCategoryValue: Scalars['String'];
  title: Scalars['String'];
  url: Scalars['String'];
  userAgent: UserAgentInput;
};

export type SavePdfInput = {
  assignLessonContentId: Scalars['String'];
  pdf: Scalars['Upload'];
};

export type SaveUnauthFeedbackInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  message: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  screenshots: Array<Scalars['Upload']>;
  submissionCategoryLabel: Scalars['String'];
  submissionCategoryValue: Scalars['String'];
  title: Scalars['String'];
  url: Scalars['String'];
  userAgent: UserAgentInput;
};

export type ScheduleCourseInput = {
  childId: Scalars['String'];
  courseId: Scalars['String'];
  interval: Scalars['Float'];
  scheduledDate: Scalars['String'];
  scheduledDays: Weekdays;
  unitId?: InputMaybe<Scalars['String']>;
};

export enum ScheduleDay {
  Friday = 'Friday',
  Monday = 'Monday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  Thursday = 'Thursday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday'
}

export type ScheduleLessonInput = {
  childId: Scalars['String'];
  courseId: Scalars['String'];
  date: Scalars['String'];
  lessonId: Scalars['String'];
  unitId: Scalars['String'];
};

export type ScheduledLessonsCalendarOutput = {
  __typename?: 'ScheduledLessonsCalendarOutput';
  assignDate: Scalars['Date'];
  childId?: Maybe<Scalars['String']>;
  courseId?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  duration?: Maybe<Scalars['Float']>;
  isCompleted: Scalars['Boolean'];
  lessonId?: Maybe<Scalars['String']>;
  lessonStatus?: Maybe<Scalars['String']>;
  plannerId: Scalars['String'];
  scheduleDate?: Maybe<Scalars['String']>;
  subjectName: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  unitId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
};

export type ScheduledPlannerAssignCourse = {
  __typename?: 'ScheduledPlannerAssignCourse';
  name: Scalars['String'];
  subject?: Maybe<ScheduledPlannerSubject>;
};

export type ScheduledPlannerAssignLesson = {
  __typename?: 'ScheduledPlannerAssignLesson';
  _id: Scalars['String'];
  canRead: Scalars['Boolean'];
  duration: Scalars['Float'];
  icon: Scalars['String'];
  name: Scalars['String'];
  planet: Scalars['String'];
};

export type ScheduledPlannerAssignUnit = {
  __typename?: 'ScheduledPlannerAssignUnit';
  name: Scalars['String'];
};

export type ScheduledPlannerLesson = {
  __typename?: 'ScheduledPlannerLesson';
  _id: Scalars['String'];
  course: ScheduledPlannerAssignCourse;
  createdAt: Scalars['Date'];
  finishedAt?: Maybe<Scalars['Date']>;
  lesson: ScheduledPlannerAssignLesson;
  scheduleDate: Scalars['String'];
  status: PlannerLessonStatus;
  unit: ScheduledPlannerAssignUnit;
};

export type ScheduledPlannerLessonsOutput = {
  __typename?: 'ScheduledPlannerLessonsOutput';
  completed: Array<ScheduledPlannerLesson>;
  todayAndUncompleted: Array<ScheduledPlannerLesson>;
};

export type ScheduledPlannerSubject = {
  __typename?: 'ScheduledPlannerSubject';
  _id: Scalars['String'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

export enum SchoolType {
  FullTime = 'FullTime',
  OnDemand = 'OnDemand',
  PartTime = 'PartTime'
}

export enum SessionStatus {
  Complete = 'Complete',
  Expired = 'Expired',
  Open = 'Open'
}

export type SetLastMoodCheckInput = {
  _id?: InputMaybe<Scalars['String']>;
  checkTime: Scalars['String'];
  mood: Scalars['String'];
};

export type SetLastMoodCheckOutput = {
  __typename?: 'SetLastMoodCheckOutput';
  _id: Scalars['String'];
  childId?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  end?: Maybe<LastMoodCheckValue>;
  start?: Maybe<LastMoodCheckValue>;
  updatedAt: Scalars['Date'];
};

export type SetProfileInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  countryCode: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  image?: InputMaybe<Scalars['Upload']>;
  languages: Array<Scalars['String']>;
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  state: Scalars['String'];
};

export type SetSchoolProfileInput = {
  isEnrollmentOpen?: InputMaybe<Scalars['Boolean']>;
  schoolDescription?: InputMaybe<Scalars['String']>;
  schoolImage?: InputMaybe<Scalars['Upload']>;
  schoolName?: InputMaybe<Scalars['String']>;
  schoolType?: InputMaybe<Array<SchoolType>>;
};

export type SheduledLessonForCalendarOutput = {
  __typename?: 'SheduledLessonForCalendarOutput';
  childId: Scalars['String'];
  courses: Array<CoursesSelectOutput>;
  lessons: Array<LessonAssignSelectOutput>;
  plannerId: Scalars['String'];
  scheduleDate: Scalars['String'];
  selectedCourse: CoursesSelectOutput;
  selectedLesson: LessonAssignSelectOutput;
  selectedUnit: AssignUnitSelectOutput;
  units: Array<AssignUnitSelectOutput>;
};

export type SignInInput = {
  password: Scalars['String'];
  recaptchaToken: Scalars['String'];
  username: Scalars['String'];
};

export type SignInOutput = {
  __typename?: 'SignInOutput';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
  user: SignInUser;
};

export type SignInUser = {
  __typename?: 'SignInUser';
  _id: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  isFamilyAccount?: Maybe<Scalars['Boolean']>;
  isOnFreemium?: Maybe<Scalars['Boolean']>;
  lastMoodCheck?: Maybe<SignInUserLastMoodCheck>;
  lastName: Scalars['String'];
  role: UserRole;
  username: Scalars['String'];
};

export type SignInUserLastMoodCheck = {
  __typename?: 'SignInUserLastMoodCheck';
  _id: Scalars['String'];
  end?: Maybe<Scalars['Date']>;
  start?: Maybe<Scalars['Date']>;
};

export type SignUpInput = {
  adUrl?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  recaptchaToken: Scalars['String'];
  source: Scalars['String'];
};

export type SignUpOutput = {
  __typename?: 'SignUpOutput';
  _id: Scalars['String'];
  accessToken: Scalars['String'];
  email: Scalars['String'];
  refreshToken: Scalars['String'];
  user: SignInUser;
};

export type StartAutoPilotLessonOutput = {
  __typename?: 'StartAutoPilotLessonOutput';
  _id: Scalars['String'];
  courseData: StartLessonCourseDataAutoPilot;
  lessonData: AssignLessonOutput;
  notes?: Maybe<Array<NoteOutput>>;
  pdfs?: Maybe<Array<AssignLessonContentOutput>>;
  quizData?: Maybe<Array<ChildDetailedAnalyticsQuiz>>;
};

export type StartLessonCourseData = {
  __typename?: 'StartLessonCourseData';
  _id: Scalars['String'];
  grade?: Maybe<StartLessonGrade>;
  subject?: Maybe<StartLessonSubject>;
  takenCourseId?: Maybe<Scalars['String']>;
};

export type StartLessonCourseDataAutoPilot = {
  __typename?: 'StartLessonCourseDataAutoPilot';
  _id: Scalars['String'];
  grade?: Maybe<StartLessonGradeAutoPilot>;
  subject?: Maybe<StartLessonSubjectAutoPilot>;
  takenCourseId?: Maybe<Scalars['String']>;
};

export type StartLessonGrade = {
  __typename?: 'StartLessonGrade';
  _id: Scalars['String'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type StartLessonGradeAutoPilot = {
  __typename?: 'StartLessonGradeAutoPilot';
  _id: Scalars['String'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type StartLessonOutput = {
  __typename?: 'StartLessonOutput';
  _id: Scalars['String'];
  courseData: StartLessonCourseData;
  lessonData: AssignLessonOutput;
  notes?: Maybe<Array<NoteOutput>>;
  pdfs?: Maybe<Array<AssignLessonContentOutput>>;
  quizData?: Maybe<Array<ChildDetailedAnalyticsQuiz>>;
  scheduleDate: Scalars['Date'];
  status?: Maybe<PlannerLessonStatus>;
};

export type StartLessonSubject = {
  __typename?: 'StartLessonSubject';
  _id: Scalars['String'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type StartLessonSubjectAutoPilot = {
  __typename?: 'StartLessonSubjectAutoPilot';
  _id: Scalars['String'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type StartParentLessonOutput = {
  __typename?: 'StartParentLessonOutput';
  _id: Scalars['String'];
  courseData: StartLessonCourseData;
  lessonData: AssignLessonOutput;
  notes?: Maybe<Array<NoteOutput>>;
  pdfs?: Maybe<Array<AssignLessonContentOutput>>;
  quizData?: Maybe<Array<ChildDetailedAnalyticsQuiz>>;
};

export type StateReportsByDate = {
  __typename?: 'StateReportsByDate';
  date: Scalars['String'];
  state: Scalars['String'];
  value: Scalars['Float'];
};

export enum Status {
  Active = 'Active',
  Inactive = 'Inactive'
}

/** Storyline attempts */
export type Storyline = {
  __typename?: 'Storyline';
  _id: Scalars['String'];
  attempt: Scalars['Float'];
  completionStatus: Scalars['String'];
  createdAt: Scalars['Date'];
  hasEvaluationSlide: Scalars['Boolean'];
  interactions: Scalars['Float'];
  isParsed: Scalars['Boolean'];
  lesson: AssignLesson;
  questionsInQuiz: Scalars['Float'];
  quizTitle: Scalars['String'];
  score: Scalars['Float'];
  scormData: Scalars['String'];
  student: User;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type StudentLessons = {
  __typename?: 'StudentLessons';
  childName: Scalars['String'];
  scheduledLessons: Array<ScheduledLessonsCalendarOutput>;
};

export type StudentsForReports = {
  __typename?: 'StudentsForReports';
  _id: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  parentFirstName?: Maybe<Scalars['String']>;
  parentLastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  studentFirstName?: Maybe<Scalars['String']>;
  studentLastName?: Maybe<Scalars['String']>;
  subscriptionStartDate?: Maybe<Scalars['Date']>;
};

/** Subject of course */
export type Subject = {
  __typename?: 'Subject';
  _id: Scalars['String'];
  content: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  slug: Scalars['String'];
  status: Status;
  title: Scalars['String'];
};

export type SubscriptionDetailsOutput = {
  __typename?: 'SubscriptionDetailsOutput';
  _id: Scalars['String'];
  interval: SubscriptionPlanInterval;
  nextInvoice: Scalars['Date'];
  price: Scalars['String'];
  reactivable: Scalars['Boolean'];
};

export type SubscriptionPlan = {
  __typename?: 'SubscriptionPlan';
  amount: Scalars['Float'];
  currency: Scalars['String'];
  interval: Scalars['String'];
  price_id_stripe: Scalars['String'];
  product_id_stripe: Scalars['String'];
};

export enum SubscriptionPlanInterval {
  Month = 'Month',
  Year = 'Year'
}

export type SubscriptionSwitchAndUpgradeDetailsForMscOutPut = {
  __typename?: 'SubscriptionSwitchAndUpgradeDetailsForMSCOutPut';
  annualPlanOption?: Maybe<PlanOutput>;
  switchPlanOptions: Array<PlanOutput>;
};

export type SubscriptionsByDate = {
  __typename?: 'SubscriptionsByDate';
  date: Scalars['String'];
  status: Scalars['String'];
  value: Scalars['Float'];
};

export enum Tag {
  Lesson = 'Lesson',
  Worksheet = 'Worksheet'
}

/** Unit/Tutorial */
export type Unit = {
  __typename?: 'Unit';
  _id: Scalars['String'];
  badge: Scalars['String'];
  course: Course;
  createdAt: Scalars['Date'];
  description: Scalars['String'];
  isActive: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  status: Status;
  title: Scalars['String'];
  tutorial_number: Scalars['Float'];
  updatedAt: Scalars['Date'];
};

export type UpdateScheduledLessonInput = {
  childId: Scalars['String'];
  courseId: Scalars['String'];
  date: Scalars['String'];
  lessonId: Scalars['String'];
  scheduledLessonId: Scalars['String'];
  unitId: Scalars['String'];
};

/** Student (child) user */
export type User = {
  __typename?: 'User';
  _id: Scalars['String'];
  address: Scalars['String'];
  by_web_to_referral_source: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  country_code: Scalars['String'];
  country_of_education: Scalars['String'];
  createdAt: Scalars['Date'];
  customerIdStripe: Scalars['String'];
  customer_id?: Maybe<Scalars['String']>;
  deviceToken: Scalars['String'];
  deviceType: Scalars['String'];
  dob: Scalars['Date'];
  email: Scalars['String'];
  facebbok_connected: Scalars['Boolean'];
  facebook_id: Scalars['String'];
  first_name: Scalars['String'];
  full_name: Scalars['String'];
  hasMetaverse: Scalars['Boolean'];
  impersonate: Scalars['Boolean'];
  impersonate_actual_password: Scalars['String'];
  impersonate_password: Scalars['String'];
  inTrialPeriod: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
  isDefaultChild: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  isFamilyAccount?: Maybe<Scalars['Boolean']>;
  isMSCategoryBasedUser: Scalars['Boolean'];
  isSubscribed: Scalars['Boolean'];
  isVerified: Scalars['Boolean'];
  isVerifiedcode?: Maybe<Scalars['String']>;
  language_spoken: Array<Language>;
  lastMoodCheck?: Maybe<LastMoodCheck>;
  last_name: Scalars['String'];
  lates_degree: Scalars['String'];
  learningMode?: Maybe<LearningMode>;
  maxFamilyChildren?: Maybe<Scalars['Float']>;
  membershipCode?: Maybe<Scalars['String']>;
  microschool: Microschool;
  omnisendId?: Maybe<Scalars['String']>;
  other_to_referral_source: Scalars['String'];
  parent_id?: Maybe<User>;
  phone: Scalars['String'];
  plgas: Scalars['Boolean'];
  profile_pic: Scalars['String'];
  province_of_education: Scalars['String'];
  referral_source: Scalars['String'];
  register_type: Scalars['String'];
  role: Role;
  school_grade?: Maybe<Grade>;
  school_sub_grade: Scalars['String'];
  skippedTrial?: Maybe<Scalars['Boolean']>;
  social_id: Scalars['String'];
  state: Scalars['String'];
  status: Status;
  studentId?: Maybe<Scalars['String']>;
  subscription_platform: Scalars['String'];
  tcpp: Scalars['Boolean'];
  tourCompleted: Scalars['Boolean'];
  trialPeriodEndDate?: Maybe<Scalars['Date']>;
  updatedAt: Scalars['Date'];
  userDetailsConfirmed?: Maybe<Scalars['Boolean']>;
  user_name: Scalars['String'];
  user_title: Scalars['String'];
  verificationOtp: Scalars['String'];
  verifyToken?: Maybe<Scalars['Float']>;
};

export type UserAgent = {
  __typename?: 'UserAgent';
  browser: UserAgentBrowser;
  cpu?: Maybe<Scalars['String']>;
  device: UserAgentDevice;
  os: UserAgentOs;
  screenResolution: UserAgentScreenResolution;
};

export type UserAgentBrowser = {
  __typename?: 'UserAgentBrowser';
  name?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type UserAgentBrowserInput = {
  name?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['String']>;
};

export type UserAgentDevice = {
  __typename?: 'UserAgentDevice';
  model?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  vendor?: Maybe<Scalars['String']>;
};

export type UserAgentDeviceInput = {
  model?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  vendor?: InputMaybe<Scalars['String']>;
};

export type UserAgentInput = {
  browser: UserAgentBrowserInput;
  cpu?: InputMaybe<Scalars['String']>;
  device: UserAgentDeviceInput;
  os: UserAgentOsInput;
  screenResolution: UserAgentScreenResolutionInput;
};

export type UserAgentOs = {
  __typename?: 'UserAgentOS';
  name?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type UserAgentOsInput = {
  name?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['String']>;
};

export type UserAgentScreenResolution = {
  __typename?: 'UserAgentScreenResolution';
  height: Scalars['Float'];
  width: Scalars['Float'];
};

export type UserAgentScreenResolutionInput = {
  height: Scalars['Float'];
  width: Scalars['Float'];
};

export type UserLocations = {
  __typename?: 'UserLocations';
  country: Scalars['String'];
};

export enum UserRole {
  Admin = 'Admin',
  Child = 'Child',
  Parent = 'Parent'
}

export type VerifyDefaultStudentDetails = {
  __typename?: 'VerifyDefaultStudentDetails';
  _id: Scalars['String'];
  dateOfBirth: Scalars['String'];
  firstName: Scalars['String'];
  grade: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  userName: Scalars['String'];
};

export type VerifyParentDetails = {
  __typename?: 'VerifyParentDetails';
  _id: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  phoneNumber: Scalars['String'];
  state: Scalars['String'];
};

export type VerifyParentDetailsInput = {
  childFirstName: Scalars['String'];
  childId: Scalars['String'];
  childLastName: Scalars['String'];
  childPassword: Scalars['String'];
  childUserName: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  countryCode: Scalars['String'];
  dateOfBirth: Scalars['String'];
  grade: Scalars['String'];
  phoneNumber: Scalars['String'];
  state: Scalars['String'];
  userTitle: Scalars['String'];
};

export type VerifyUserDetails = {
  __typename?: 'VerifyUserDetails';
  child?: Maybe<VerifyDefaultStudentDetails>;
  parent?: Maybe<VerifyParentDetails>;
};

export type Weekdays = {
  friday: Scalars['Boolean'];
  monday: Scalars['Boolean'];
  saturday: Scalars['Boolean'];
  sunday: Scalars['Boolean'];
  thursday: Scalars['Boolean'];
  tuesday: Scalars['Boolean'];
  wednesday: Scalars['Boolean'];
};

export type CsvIndividualEducatorMetricOutput = {
  __typename?: 'csvIndividualEducatorMetricOutput';
  _id: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
};

export type CsvIndividualMetricOutput = {
  __typename?: 'csvIndividualMetricOutput';
  _id: Scalars['String'];
  cancellation_reason?: Maybe<Scalars['String']>;
  current_period_end_date?: Maybe<Scalars['Date']>;
  current_period_start_date?: Maybe<Scalars['Date']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  interval: Scalars['String'];
  isFamilyPlan: Scalars['Boolean'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  status: Scalars['String'];
  subscription_start_date?: Maybe<Scalars['Date']>;
};

export type SaveEventMutationVariables = Exact<{
  input: SaveEventInput;
}>;


export type SaveEventMutation = { __typename?: 'Mutation', saveEvent: boolean };

export type ChildrenAnalyticsQueryVariables = Exact<{ [key: string]: never; }>;


export type ChildrenAnalyticsQuery = { __typename?: 'Query', childrenAnalytics: Array<{ __typename?: 'ChildrenAnalyticsOutput', _id: string, fullName: string, profilePic: string, mood?: string | null, grade: { __typename?: 'GetChildrenGrade', _id: string, title: string }, subjects: Array<{ __typename?: 'ChildrenAnalyticsSubject', _id: string, title: string, slug: string, progress: number, score: number }> }> };

export type ChildDetailedAnalyticsQueryVariables = Exact<{
  childId: Scalars['String'];
}>;


export type ChildDetailedAnalyticsQuery = { __typename?: 'Query', childDetailedAnalytics: { __typename?: 'ChildDetailedAnalyticsOutput', fullName: string, courses: Array<{ __typename?: 'ChildDetailedAnalyticsCourse', _id: string, name: string, isCompleted: boolean, lessons: Array<{ __typename?: 'ChildDetailedAnalyticsLesson', _id: string, name: string, quizes: Array<{ __typename?: 'ChildDetailedAnalyticsQuiz', _id: string, score: number, attempt: number, updatedAt: string, questions: Array<{ __typename?: 'ChildDetailedAnalyticsQuizQuestion', _id: string, title: string, isMarkable: boolean, questionType: string, learnerResponse: string, correctResponse: string, isCorrect: boolean }> }> }> }> } };

export type ChildTranscriptQueryVariables = Exact<{
  childId: Scalars['String'];
}>;


export type ChildTranscriptQuery = { __typename?: 'Query', childTranscript: { __typename?: 'ChildTranscriptOutput', fullName: string, schoolName?: string | null, courses: Array<{ __typename?: 'ChildTranscriptCourse', _id: string, name: string, units: Array<{ __typename?: 'ChildTranscriptUnit', _id: string, name: string, isCompleted: boolean, updatedAt: string, lessons: Array<{ __typename?: 'ChildDetailedAnalyticsLesson', _id: string, name: string, quizes: Array<{ __typename?: 'ChildDetailedAnalyticsQuiz', _id: string, score: number, attempt: number, updatedAt: string, questions: Array<{ __typename?: 'ChildDetailedAnalyticsQuizQuestion', _id: string, title: string, isMarkable: boolean, questionType: string, learnerResponse: string, correctResponse: string, isCorrect: boolean }> }> }> }> }> } };

export type ChangeLessonQuizScoreMutationVariables = Exact<{
  childId: Scalars['String'];
  quizId: Scalars['String'];
  score: Scalars['Float'];
}>;


export type ChangeLessonQuizScoreMutation = { __typename?: 'Mutation', changeLessonQuizScore: boolean };

export type SavePdfMutationVariables = Exact<{
  savePdfInput: SavePdfInput;
}>;


export type SavePdfMutation = { __typename?: 'Mutation', savePdf: { __typename?: 'AssignLessonContentOutput', _id: string, src: string, isAnnotated: boolean, name: string, tag: Tag, isStarted: boolean, isFinished: boolean } };

export type SearchLessonsByCourseQueryVariables = Exact<{
  courseId: Scalars['String'];
  query: Scalars['String'];
}>;


export type SearchLessonsByCourseQuery = { __typename?: 'Query', searchLessonsByCourseAutoPilot: Array<{ __typename?: 'AutoPilotLessonByUnitOutput', _id: string, isCompleted: boolean, name: string }> };

export type CourseDataAutoPilotFragment = { __typename?: 'StartLessonCourseDataAutoPilot', _id: string, takenCourseId?: string | null, subject?: { __typename?: 'StartLessonSubjectAutoPilot', _id: string, title: string, slug: string } | null, grade?: { __typename?: 'StartLessonGradeAutoPilot', _id: string, title: string, slug: string } | null };

export type NotesFragment = { __typename?: 'NoteOutput', _id: string, name: string, createdAt: string, content: string, authorId: string, wasRead: boolean };

export type LessonDataFragment = { __typename?: 'AssignLessonOutput', _id: string, lessonName?: string | null, quizTitle?: string | null, quizUrl?: string | null, lessonContent: string, isFinished: boolean, childFinishDateTime?: string | null, youtubeLink?: string | null, resources: Array<{ __typename?: 'LessonResourceOutput', link: string, thumb: string, title: string }>, lessonV: Array<{ __typename?: 'LessonVideoOutput', lessonVideo: string, lessonVideoThumb: string }> };

export type PdfFragment = { __typename?: 'AssignLessonContentOutput', _id: string, src: string, isAnnotated: boolean, name: string, tag: Tag, isStarted: boolean, isFinished: boolean };

export type StartLessonAutoPilotMutationVariables = Exact<{
  lessonId: Scalars['String'];
}>;


export type StartLessonAutoPilotMutation = { __typename?: 'Mutation', startLessonAutoPilot: { __typename?: 'StartAutoPilotLessonOutput', _id: string, lessonData: { __typename?: 'AssignLessonOutput', _id: string, lessonName?: string | null, quizTitle?: string | null, quizUrl?: string | null, lessonContent: string, isFinished: boolean, childFinishDateTime?: string | null, youtubeLink?: string | null, resources: Array<{ __typename?: 'LessonResourceOutput', link: string, thumb: string, title: string }>, lessonV: Array<{ __typename?: 'LessonVideoOutput', lessonVideo: string, lessonVideoThumb: string }> }, courseData: { __typename?: 'StartLessonCourseDataAutoPilot', _id: string, takenCourseId?: string | null, subject?: { __typename?: 'StartLessonSubjectAutoPilot', _id: string, title: string, slug: string } | null, grade?: { __typename?: 'StartLessonGradeAutoPilot', _id: string, title: string, slug: string } | null }, notes?: Array<{ __typename?: 'NoteOutput', _id: string, name: string, createdAt: string, content: string, authorId: string, wasRead: boolean }> | null, pdfs?: Array<{ __typename?: 'AssignLessonContentOutput', _id: string, src: string, isAnnotated: boolean, name: string, tag: Tag, isStarted: boolean, isFinished: boolean }> | null } };

export type FinishLessonAutoPilotMutationVariables = Exact<{
  lessonId: Scalars['String'];
}>;


export type FinishLessonAutoPilotMutation = { __typename?: 'Mutation', finishLessonAutoPilot: { __typename?: 'FinishLessonOutputAutoPilot', lessonData: { __typename?: 'AssignLessonOutput', _id: string } } };

export type LessonDetailsAutoPilotQueryVariables = Exact<{
  courseId: Scalars['String'];
}>;


export type LessonDetailsAutoPilotQuery = { __typename?: 'Query', lessonDetailsAutoPilot?: { __typename?: 'LessonDetailsOutput', _id: string } | null };

export type CoursesByChildAutoPilotQueryVariables = Exact<{ [key: string]: never; }>;


export type CoursesByChildAutoPilotQuery = { __typename?: 'Query', coursesByChildAutoPilot: { __typename?: 'AutoPilotChildCoursesOutput', courses: Array<{ __typename?: 'AutoPilotChildCourses', _id: string, image: string, isCustom: boolean, name: string, planet?: string | null, isHidden: boolean, icon?: string | null, lessons: number, completedLessons: number, progress: number, subject?: { __typename?: 'AutoPilotChildCourseSubject', _id: string, slug?: string | null } | null }> } };

export type ChildCoursesQueryVariables = Exact<{
  childId: Scalars['String'];
  limit?: InputMaybe<Scalars['Float']>;
  page?: InputMaybe<Scalars['Float']>;
}>;


export type ChildCoursesQuery = { __typename?: 'Query', childCourses: { __typename?: 'ChildCoursesOutput', courses: Array<{ __typename?: 'ChildCoursesCourse', _id: string, isHidden: boolean, image: string, name: string, progress: number, currentLessonName: string, isCustom: boolean, lessons?: number | null, completedLessons?: number | null, isRecurringExist: boolean, isDeletable: boolean, grade?: { __typename?: 'ChildCoursesCourseGrade', _id: string, slug: string } | null, subject?: { __typename?: 'ChildCoursesCourseSubject', _id: string, slug: string } | null }>, pagination?: { __typename?: 'Pagination', page: number, pages: number, total: number, limit: number } | null } };

export type DeletedCoursesQueryVariables = Exact<{
  childId: Scalars['String'];
  limit?: InputMaybe<Scalars['Float']>;
  page?: InputMaybe<Scalars['Float']>;
}>;


export type DeletedCoursesQuery = { __typename?: 'Query', deletedCourses: { __typename?: 'ChildDeletedCoursesOutput', courses: Array<{ __typename?: 'ChildDeletedCourse', _id: string, image: string, name: string, progress: number, currentLessonName: string, isCustom: boolean, isHidden: boolean, isRecoverable: boolean, deletedOn: string, grade?: { __typename?: 'ChildCoursesCourseGrade', _id: string, slug: string } | null, subject?: { __typename?: 'ChildCoursesCourseSubject', _id: string, slug: string } | null }>, pagination?: { __typename?: 'Pagination', page: number, pages: number, total: number, limit: number } | null } };

export type DeleteCourseMutationVariables = Exact<{
  courseId: Scalars['String'];
  childId: Scalars['String'];
}>;


export type DeleteCourseMutation = { __typename?: 'Mutation', deleteCourse: string };

export type RecoverCourseMutationVariables = Exact<{
  courseId: Scalars['String'];
  childId: Scalars['String'];
}>;


export type RecoverCourseMutation = { __typename?: 'Mutation', recoverCourse: string };

export type RecoverSelectedFromMarketplaceMutationVariables = Exact<{
  childId: Scalars['String'];
  takenCourseId: Scalars['String'];
}>;


export type RecoverSelectedFromMarketplaceMutation = { __typename?: 'Mutation', recoverSelectedFromMarketplace: string };

export type ReplaceWithNewCourseMutationVariables = Exact<{
  childId: Scalars['String'];
  takenCourseId: Scalars['String'];
}>;


export type ReplaceWithNewCourseMutation = { __typename?: 'Mutation', replaceWithNewCourse: string };

export type SearchChildCoursesQueryVariables = Exact<{
  childId: Scalars['String'];
  query: Scalars['String'];
}>;


export type SearchChildCoursesQuery = { __typename?: 'Query', searchChildCourses: Array<{ __typename?: 'ChildCoursesCourse', _id: string, image: string, name: string, isHidden: boolean, progress: number, currentLessonName: string, isCustom: boolean, isRecurringExist: boolean, isDeletable: boolean }> };

export type CourseDetailsQueryVariables = Exact<{
  childId: Scalars['String'];
  courseId: Scalars['String'];
}>;


export type CourseDetailsQuery = { __typename?: 'Query', courseDetails: { __typename?: 'AssignCourseDetailsOutput', _id: string, image: string, name: string, progress: number, isHidden: boolean, lessons?: number | null, completedLessons?: number | null, currentLessonName: string, isCustom: boolean, isRecurringExist: boolean, isDeletable: boolean, grade?: { __typename?: 'ChildCoursesCourseGrade', _id: string, slug: string } | null, subject?: { __typename?: 'ChildCoursesCourseSubject', _id: string, slug: string } | null, units: Array<{ __typename?: 'AssignCourseDetailsUnit', _id: string, name: string, progress: number, lessons: number, completedLessons: number, hiddenLessons: number, badge: string, courseTakenId: string, isRecurringExist: boolean, isHidden: boolean }> } };

export type CreateCustomCourseMutationVariables = Exact<{
  childId: Scalars['String'];
  courseName: Scalars['String'];
}>;


export type CreateCustomCourseMutation = { __typename?: 'Mutation', createCustomCourse: { __typename?: 'ChildCoursesCourse', _id: string, image: string, name: string } };

export type ChildAtLeastOneCourseQueryVariables = Exact<{
  childId: Scalars['String'];
}>;


export type ChildAtLeastOneCourseQuery = { __typename?: 'Query', childAtLeastOneCourse: boolean };

export type IsCourseRecoverableFromDeletedListQueryVariables = Exact<{
  childId: Scalars['String'];
  courseId: Scalars['String'];
}>;


export type IsCourseRecoverableFromDeletedListQuery = { __typename?: 'Query', isCourseRecoverableFromDeletedList: boolean };

export type ToggleCourseHideUnhideMutationVariables = Exact<{
  courseId: Scalars['String'];
  isHidden: Scalars['Boolean'];
}>;


export type ToggleCourseHideUnhideMutation = { __typename?: 'Mutation', toggleCourseHideUnhide: boolean };

export type LessonsByUnitAutoPilotQueryVariables = Exact<{
  unitId: Scalars['String'];
}>;


export type LessonsByUnitAutoPilotQuery = { __typename?: 'Query', lessonsByUnitAutoPilot: Array<{ __typename?: 'AutoPilotLessonByUnitOutput', _id: string, isCompleted: boolean, name: string }> };

export type LessonsByUnitQueryVariables = Exact<{
  childId: Scalars['String'];
  unitId: Scalars['String'];
}>;


export type LessonsByUnitQuery = { __typename?: 'Query', lessonsByUnit: Array<{ __typename?: 'LessonByUnitOutput', _id: string, name: string, isCompleted: boolean, isHidden: boolean, plannerLessons: Array<{ __typename?: 'LessonByUnitPlannerLesson', _id: string, scheduleDate: string }> }> };

export type CreateLessonMutationVariables = Exact<{
  input: CreateLessonInput;
}>;


export type CreateLessonMutation = { __typename?: 'Mutation', createLesson: boolean };

export type UpdateLessonOrderMutationVariables = Exact<{
  input: AssignLessonOrderInput;
}>;


export type UpdateLessonOrderMutation = { __typename?: 'Mutation', updateLessonOrder: boolean };

export type LessonDetailsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type LessonDetailsQuery = { __typename?: 'Query', lessonDetails: { __typename?: 'LessonDetailsOutput', _id: string, title: string, duration: number, description: string, icon?: string | null, planet?: string | null, pdfs: Array<{ __typename?: 'LessonDetailsOutputPdf', _id: string, file: string, tag: Tag }>, resources: Array<{ __typename?: 'LessonDetailsOutputResource', _id: string, title: string, url: string, thumbnail?: string | null }> } };

export type EditLessonMutationVariables = Exact<{
  input: EditLessonInput;
}>;


export type EditLessonMutation = { __typename?: 'Mutation', editLesson: boolean };

export type ToggleLessonHideUnhideMutationVariables = Exact<{
  lessonId: Scalars['String'];
  isHidden: Scalars['Boolean'];
}>;


export type ToggleLessonHideUnhideMutation = { __typename?: 'Mutation', toggleLessonHideUnhide: boolean };

export type AddNoteMutationVariables = Exact<{
  note: Scalars['String'];
  lessonId: Scalars['String'];
  name: Scalars['String'];
}>;


export type AddNoteMutation = { __typename?: 'Mutation', addNote: { __typename?: 'NoteOutput', _id: string, name: string, createdAt: string, content: string, authorId: string, wasRead: boolean } };

export type ReadNoteMutationVariables = Exact<{
  noteId: Scalars['String'];
}>;


export type ReadNoteMutation = { __typename?: 'Mutation', readNote: { __typename?: 'NoteOutput', _id: string, wasRead: boolean } };

export type EditNoteMutationVariables = Exact<{
  noteId: Scalars['String'];
  content: Scalars['String'];
}>;


export type EditNoteMutation = { __typename?: 'Mutation', editNote: { __typename?: 'NoteOutput', _id: string, content: string } };

export type DeleteNoteMutationVariables = Exact<{
  noteId: Scalars['String'];
}>;


export type DeleteNoteMutation = { __typename?: 'Mutation', deleteNote: { __typename?: 'NoteOutput', _id: string } };

export type NotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type NotificationsQuery = { __typename?: 'Query', notifications: Array<{ __typename?: 'Notification', _id: string, isRead: boolean, title: string, description: string, createdAt: string }> };

export type ReadNotificationsMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type ReadNotificationsMutation = { __typename?: 'Mutation', readNotifications: Array<string> };

export type DeleteNotificationMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteNotificationMutation = { __typename?: 'Mutation', deleteNotification: { __typename?: 'Notification', _id: string } };

export type DeleteNotificationsMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteNotificationsMutation = { __typename?: 'Mutation', deleteNotifications: Array<string> };

export type NotificationsForPageQueryVariables = Exact<{
  studentId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  page?: InputMaybe<Scalars['Float']>;
}>;


export type NotificationsForPageQuery = { __typename?: 'Query', notificationsForPage: { __typename?: 'NotificationsOutput', notifications: Array<{ __typename?: 'NotificationOutput', _id: string, profileImage?: string | null, fullName: string, senderId?: string | null, parentId?: string | null, createdAt: string, updatedAt: string, title: string, content: string, description: string, isPublic: boolean }>, pagination?: { __typename?: 'Pagination', pages: number, page: number, total: number, limit: number } | null } };

export type GetBannerAdvertisementsQueryVariables = Exact<{
  studentId?: InputMaybe<Scalars['String']>;
}>;


export type GetBannerAdvertisementsQuery = { __typename?: 'Query', getBannerAdvertisements: Array<{ __typename?: 'DigitalAdsOutput', _id: string, title: string, content: string, description: string, bannerImage: string, advertisementImage: string, destinationUrl: string }> };

export type GetOneTimeAdvertisementAndMarkReadQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOneTimeAdvertisementAndMarkReadQuery = { __typename?: 'Query', getOneTimeAdvertisementAndMarkRead?: { __typename?: 'DigitalAdsOutput', _id: string, title: string, content: string, description: string, bannerImage: string, advertisementImage: string, destinationUrl: string } | null };

export type LessonFragment = { __typename?: 'ScheduledPlannerLesson', _id: string, scheduleDate: string, status: PlannerLessonStatus, finishedAt?: string | null, createdAt: string, course: { __typename?: 'ScheduledPlannerAssignCourse', name: string, subject?: { __typename?: 'ScheduledPlannerSubject', _id: string, title: string, slug: string } | null }, unit: { __typename?: 'ScheduledPlannerAssignUnit', name: string }, lesson: { __typename?: 'ScheduledPlannerAssignLesson', _id: string, name: string, canRead: boolean, duration: number, icon: string, planet: string } };

export type CourseDataFragment = { __typename?: 'StartLessonCourseData', _id: string, takenCourseId?: string | null, subject?: { __typename?: 'StartLessonSubject', _id: string, title: string, slug: string } | null, grade?: { __typename?: 'StartLessonGrade', _id: string, title: string, slug: string } | null };

export type QuizDataFragment = { __typename?: 'ChildDetailedAnalyticsQuiz', _id: string, score: number, attempt: number, updatedAt: string, questions: Array<{ __typename?: 'ChildDetailedAnalyticsQuizQuestion', _id: string, title: string, isMarkable: boolean, questionType: string, learnerResponse: string, correctResponse: string, isCorrect: boolean }> };

export type PlannerLessonsQueryVariables = Exact<{ [key: string]: never; }>;


export type PlannerLessonsQuery = { __typename?: 'Query', plannerLessons: { __typename?: 'ScheduledPlannerLessonsOutput', todayAndUncompleted: Array<{ __typename?: 'ScheduledPlannerLesson', _id: string, scheduleDate: string, status: PlannerLessonStatus, finishedAt?: string | null, createdAt: string, course: { __typename?: 'ScheduledPlannerAssignCourse', name: string, subject?: { __typename?: 'ScheduledPlannerSubject', _id: string, title: string, slug: string } | null }, unit: { __typename?: 'ScheduledPlannerAssignUnit', name: string }, lesson: { __typename?: 'ScheduledPlannerAssignLesson', _id: string, name: string, canRead: boolean, duration: number, icon: string, planet: string } }>, completed: Array<{ __typename?: 'ScheduledPlannerLesson', _id: string, scheduleDate: string, status: PlannerLessonStatus, finishedAt?: string | null, createdAt: string, course: { __typename?: 'ScheduledPlannerAssignCourse', name: string, subject?: { __typename?: 'ScheduledPlannerSubject', _id: string, title: string, slug: string } | null }, unit: { __typename?: 'ScheduledPlannerAssignUnit', name: string }, lesson: { __typename?: 'ScheduledPlannerAssignLesson', _id: string, name: string, canRead: boolean, duration: number, icon: string, planet: string } }> } };

export type CheckForBadgeCelebrationMutationVariables = Exact<{
  childId: Scalars['String'];
}>;


export type CheckForBadgeCelebrationMutation = { __typename?: 'Mutation', checkForBadgeCelebration?: { __typename?: 'BadgeCelebrationOutput', _id: string, name: string, progress: number, badge: string, courseId: string } | null };

export type StartLessonMutationVariables = Exact<{
  plannerLessonId: Scalars['String'];
}>;


export type StartLessonMutation = { __typename?: 'Mutation', startLesson: { __typename?: 'StartLessonOutput', _id: string, status?: PlannerLessonStatus | null, scheduleDate: string, lessonData: { __typename?: 'AssignLessonOutput', _id: string, lessonName?: string | null, quizTitle?: string | null, quizUrl?: string | null, lessonContent: string, isFinished: boolean, childFinishDateTime?: string | null, youtubeLink?: string | null, resources: Array<{ __typename?: 'LessonResourceOutput', link: string, thumb: string, title: string }>, lessonV: Array<{ __typename?: 'LessonVideoOutput', lessonVideo: string, lessonVideoThumb: string }> }, courseData: { __typename?: 'StartLessonCourseData', _id: string, takenCourseId?: string | null, subject?: { __typename?: 'StartLessonSubject', _id: string, title: string, slug: string } | null, grade?: { __typename?: 'StartLessonGrade', _id: string, title: string, slug: string } | null }, notes?: Array<{ __typename?: 'NoteOutput', _id: string, name: string, createdAt: string, content: string, authorId: string, wasRead: boolean }> | null, pdfs?: Array<{ __typename?: 'AssignLessonContentOutput', _id: string, src: string, isAnnotated: boolean, name: string, tag: Tag, isStarted: boolean, isFinished: boolean }> | null } };

export type StartParentLessonMutationVariables = Exact<{
  lessonId: Scalars['String'];
}>;


export type StartParentLessonMutation = { __typename?: 'Mutation', startParentLesson: { __typename?: 'StartParentLessonOutput', _id: string, lessonData: { __typename?: 'AssignLessonOutput', _id: string, lessonName?: string | null, quizTitle?: string | null, quizUrl?: string | null, lessonContent: string, isFinished: boolean, childFinishDateTime?: string | null, youtubeLink?: string | null, resources: Array<{ __typename?: 'LessonResourceOutput', link: string, thumb: string, title: string }>, lessonV: Array<{ __typename?: 'LessonVideoOutput', lessonVideo: string, lessonVideoThumb: string }> }, courseData: { __typename?: 'StartLessonCourseData', _id: string, takenCourseId?: string | null, subject?: { __typename?: 'StartLessonSubject', _id: string, title: string, slug: string } | null, grade?: { __typename?: 'StartLessonGrade', _id: string, title: string, slug: string } | null }, notes?: Array<{ __typename?: 'NoteOutput', _id: string, name: string, createdAt: string, content: string, authorId: string, wasRead: boolean }> | null, pdfs?: Array<{ __typename?: 'AssignLessonContentOutput', _id: string, src: string, isAnnotated: boolean, name: string, tag: Tag, isStarted: boolean, isFinished: boolean }> | null, quizData?: Array<{ __typename?: 'ChildDetailedAnalyticsQuiz', _id: string, score: number, attempt: number, updatedAt: string, questions: Array<{ __typename?: 'ChildDetailedAnalyticsQuizQuestion', _id: string, title: string, isMarkable: boolean, questionType: string, learnerResponse: string, correctResponse: string, isCorrect: boolean }> }> | null } };

export type FinishLessonMutationVariables = Exact<{
  finishLessonInput: FinishLessonInput;
}>;


export type FinishLessonMutation = { __typename?: 'Mutation', finishLesson: { __typename?: 'FinishLessonOutput', lessonData: { __typename?: 'AssignLessonOutput', _id: string } } };

export type HistoryLessonsQueryVariables = Exact<{ [key: string]: never; }>;


export type HistoryLessonsQuery = { __typename?: 'Query', historyLessons: Array<{ __typename?: 'HistoryLessonOutput', title: string, slug: string, lessons: Array<{ __typename?: 'ScheduledPlannerLesson', _id: string, finishedAt?: string | null, course: { __typename?: 'ScheduledPlannerAssignCourse', name: string }, unit: { __typename?: 'ScheduledPlannerAssignUnit', name: string }, lesson: { __typename?: 'ScheduledPlannerAssignLesson', name: string } }> }> };

export type UnitsForBadgesQueryVariables = Exact<{
  childId: Scalars['String'];
}>;


export type UnitsForBadgesQuery = { __typename?: 'Query', unitsForBadges: Array<{ __typename?: 'HistoryCoursesOutput', _id: string, name: string, courseTakenId: string, progress: number, units: Array<{ __typename?: 'HistoryUnitsOutput', _id: string, name: string, progress: number, badge: string }> }> };

export type SearchHistoryLessonsQueryVariables = Exact<{
  query: Scalars['String'];
}>;


export type SearchHistoryLessonsQuery = { __typename?: 'Query', searchHistoryLessons: Array<{ __typename?: 'HistoryLessonOutput', title: string, slug: string, lessons: Array<{ __typename?: 'ScheduledPlannerLesson', _id: string, finishedAt?: string | null, course: { __typename?: 'ScheduledPlannerAssignCourse', name: string }, unit: { __typename?: 'ScheduledPlannerAssignUnit', name: string }, lesson: { __typename?: 'ScheduledPlannerAssignLesson', name: string } }> }> };

export type ChangeLessonCompletionStatusMutationVariables = Exact<{
  childId: Scalars['String'];
  assignLessonId: Scalars['String'];
  score?: InputMaybe<Scalars['Float']>;
}>;


export type ChangeLessonCompletionStatusMutation = { __typename?: 'Mutation', changeLessonCompletionStatus: boolean };

export type UnitsByCourseAutoPilotQueryVariables = Exact<{
  courseId: Scalars['String'];
}>;


export type UnitsByCourseAutoPilotQuery = { __typename?: 'Query', unitsByCourseAutoPilot: Array<{ __typename?: 'AutoPilotDetailsUnit', isHidden: boolean, lessons: number, completedLessons: number, name: string, progress: number, _id: string }> };

export type DeleteUnitMutationVariables = Exact<{
  id: Scalars['String'];
  childId: Scalars['String'];
}>;


export type DeleteUnitMutation = { __typename?: 'Mutation', deleteUnit: string };

export type ChangeNameUnitMutationVariables = Exact<{
  unitId: Scalars['String'];
  name: Scalars['String'];
}>;


export type ChangeNameUnitMutation = { __typename?: 'Mutation', changeNameUnit: string };

export type ToggleUnitHideUnhideMutationVariables = Exact<{
  unitId: Scalars['String'];
  isHidden: Scalars['Boolean'];
  childId: Scalars['String'];
}>;


export type ToggleUnitHideUnhideMutation = { __typename?: 'Mutation', toggleUnitHideUnhide: boolean };

export type CreateUnitMutationVariables = Exact<{
  childId: Scalars['String'];
  courseId: Scalars['String'];
  name: Scalars['String'];
}>;


export type CreateUnitMutation = { __typename?: 'Mutation', createUnit: { __typename?: 'CreateUnitOutput', _id: string, name: string } };

export type SignInMutationVariables = Exact<{
  authCredentials: SignInInput;
}>;


export type SignInMutation = { __typename?: 'Mutation', signIn: { __typename?: 'SignInOutput', accessToken: string, refreshToken: string, user: { __typename?: 'SignInUser', _id: string, username: string, firstName: string, lastName: string, email: string, role: UserRole, isOnFreemium?: boolean | null, lastMoodCheck?: { __typename?: 'SignInUserLastMoodCheck', _id: string, start?: string | null, end?: string | null } | null } } };

export type SignUpMutationVariables = Exact<{
  input: SignUpInput;
}>;


export type SignUpMutation = { __typename?: 'Mutation', signUp: { __typename?: 'SignUpOutput', _id: string, email: string, accessToken: string, refreshToken: string, user: { __typename?: 'SignInUser', _id: string, username: string, firstName: string, lastName: string, email: string, role: UserRole, isOnFreemium?: boolean | null, lastMoodCheck?: { __typename?: 'SignInUserLastMoodCheck', _id: string, start?: string | null, end?: string | null } | null } } };

export type VerifyEmailMutationVariables = Exact<{
  code: Scalars['String'];
  recaptchaToken: Scalars['String'];
}>;


export type VerifyEmailMutation = { __typename?: 'Mutation', verifyEmail: { __typename?: 'SignInOutput', accessToken: string, refreshToken: string, user: { __typename?: 'SignInUser', _id: string, username: string, firstName: string, lastName: string, email: string, role: UserRole, isOnFreemium?: boolean | null, lastMoodCheck?: { __typename?: 'SignInUserLastMoodCheck', _id: string, start?: string | null, end?: string | null } | null } } };

export type ResendVerificationEmailMutationVariables = Exact<{
  usernameOrEmail: Scalars['String'];
}>;


export type ResendVerificationEmailMutation = { __typename?: 'Mutation', resendVerificationEmail: boolean };

export type RequestResetPasswordMutationVariables = Exact<{
  usernameOrEmail: Scalars['String'];
  recaptchaToken: Scalars['String'];
}>;


export type RequestResetPasswordMutation = { __typename?: 'Mutation', requestResetPassword: { __typename?: 'RequestResetPasswordOutput', email: string } };

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: boolean };

export type RefreshMutationVariables = Exact<{ [key: string]: never; }>;


export type RefreshMutation = { __typename?: 'Mutation', refresh: { __typename?: 'SignInOutput', accessToken: string, refreshToken: string, user: { __typename?: 'SignInUser', _id: string, username: string, firstName: string, lastName: string, email: string, role: UserRole } } };

export type CheckTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type CheckTokenQuery = { __typename?: 'Query', checkToken: { __typename?: 'CheckTokenOutput', _id: string, username: string, email: string, role: UserRole, isOnFreemium?: boolean | null } };

export type ChangeChildPasswordMutationVariables = Exact<{
  childId: Scalars['String'];
  password: Scalars['String'];
}>;


export type ChangeChildPasswordMutation = { __typename?: 'Mutation', changeChildPassword: boolean };

export type PaginationFragment = { __typename?: 'Pagination', page: number, pages: number, total: number, limit: number };

export type AgreementDetailsFragment = { __typename?: 'AgreementOutput', title: string, content: string };

export type TermsAndConditionsQueryVariables = Exact<{ [key: string]: never; }>;


export type TermsAndConditionsQuery = { __typename?: 'Query', termsAndConditions: { __typename?: 'AgreementOutput', title: string, content: string } };

export type PrivacyPolicyQueryVariables = Exact<{ [key: string]: never; }>;


export type PrivacyPolicyQuery = { __typename?: 'Query', privacyPolicy: { __typename?: 'AgreementOutput', title: string, content: string } };

export type DownloadDetailsFragment = { __typename?: 'DownloadPdfOutput', _id: string, childId: string, date: string, maxDownloads: number, currentDownloads: number, isDeleted: boolean };

export type GetDownloadDetailsTodayMutationVariables = Exact<{
  childId: Scalars['String'];
}>;


export type GetDownloadDetailsTodayMutation = { __typename?: 'Mutation', getDownloadDetailsToday: { __typename?: 'DownloadPdfOutput', _id: string, childId: string, date: string, maxDownloads: number, currentDownloads: number, isDeleted: boolean } };

export type UpdateDownloadDetailsMutationVariables = Exact<{
  childId: Scalars['String'];
}>;


export type UpdateDownloadDetailsMutation = { __typename?: 'Mutation', updateDownloadDetails: { __typename?: 'DownloadPdfOutput', _id: string, childId: string, date: string, maxDownloads: number, currentDownloads: number, isDeleted: boolean } };

export type SaveFeedbackMutationVariables = Exact<{
  input: SaveFeedbackInput;
}>;


export type SaveFeedbackMutation = { __typename?: 'Mutation', saveFeedback: boolean };

export type SaveUnauthFeedbackMutationVariables = Exact<{
  input: SaveUnauthFeedbackInput;
}>;


export type SaveUnauthFeedbackMutation = { __typename?: 'Mutation', saveUnauthFeedback: boolean };

export type GradesQueryVariables = Exact<{ [key: string]: never; }>;


export type GradesQuery = { __typename?: 'Query', grades: Array<{ __typename?: 'Grade', _id: string, slug: string, title: string }> };

export type LanguagesQueryVariables = Exact<{ [key: string]: never; }>;


export type LanguagesQuery = { __typename?: 'Query', languages: Array<{ __typename?: 'Language', _id: string, slug: string }> };

export type ScheduleCourseMutationVariables = Exact<{
  scheduleCourseInput: ScheduleCourseInput;
}>;


export type ScheduleCourseMutation = { __typename?: 'Mutation', scheduleCourse: boolean };

export type RecurringEventQueryVariables = Exact<{
  childId: Scalars['String'];
  courseId: Scalars['String'];
  unitId?: InputMaybe<Scalars['String']>;
}>;


export type RecurringEventQuery = { __typename?: 'Query', recurringEvent?: { __typename?: 'RecurringEventOutput', recurringEventId: string, scheduleTime?: string | null, interval?: number | null, childId?: string | null, courseId?: string | null, scheduleDays: Array<string> } | null };

export type EditScheduleEntireCourseMutationVariables = Exact<{
  recurringEventId: Scalars['String'];
  scheduleCourseInput: ScheduleCourseInput;
}>;


export type EditScheduleEntireCourseMutation = { __typename?: 'Mutation', editScheduleEntireCourse: boolean };

export type DeleteRecurringEventMutationVariables = Exact<{
  recurringEventId: Scalars['String'];
}>;


export type DeleteRecurringEventMutation = { __typename?: 'Mutation', deleteRecurringEvent: boolean };

export type ScheduledLessonsForCalendarQueryVariables = Exact<{
  childId: Scalars['String'];
  from: Scalars['String'];
  to: Scalars['String'];
}>;


export type ScheduledLessonsForCalendarQuery = { __typename?: 'Query', scheduledLessonsForCalendar: { __typename?: 'StudentLessons', childName: string, scheduledLessons: Array<{ __typename?: 'ScheduledLessonsCalendarOutput', plannerId: string, courseId?: string | null, childId?: string | null, scheduleDate?: string | null, isCompleted: boolean, lessonStatus?: string | null, duration?: number | null, title?: string | null, subjectName: string }> } };

export type CoursesForCalendarSelectQueryVariables = Exact<{
  childId: Scalars['String'];
}>;


export type CoursesForCalendarSelectQuery = { __typename?: 'Query', coursesForCalendarSelect: Array<{ __typename?: 'CoursesSelectOutput', value: string, label: string, subject?: string | null, grade?: string | null }> };

export type UnitsForCalendarSelectQueryVariables = Exact<{
  courseId: Scalars['String'];
}>;


export type UnitsForCalendarSelectQuery = { __typename?: 'Query', unitsForCalendarSelect: Array<{ __typename?: 'AssignUnitSelectOutput', value: string, label: string }> };

export type LessonsForCalendarSelectQueryVariables = Exact<{
  unitId: Scalars['String'];
}>;


export type LessonsForCalendarSelectQuery = { __typename?: 'Query', lessonsForCalendarSelect: Array<{ __typename?: 'LessonAssignSelectOutput', value: string, label: string, isAlreadyScheduled?: boolean | null }> };

export type ScheduleLessonMutationVariables = Exact<{
  scheduleLessonInput: ScheduleLessonInput;
}>;


export type ScheduleLessonMutation = { __typename?: 'Mutation', scheduleLesson: { __typename?: 'ScheduledPlannerLesson', _id: string } };

export type UpdateScheduleLessonMutationVariables = Exact<{
  updateScheduleLessonInput: UpdateScheduledLessonInput;
}>;


export type UpdateScheduleLessonMutation = { __typename?: 'Mutation', updateScheduleLesson: { __typename?: 'SheduledLessonForCalendarOutput', childId: string, plannerId: string, scheduleDate: string, selectedCourse: { __typename?: 'CoursesSelectOutput', label: string, value: string }, selectedUnit: { __typename?: 'AssignUnitSelectOutput', label: string, value: string }, selectedLesson: { __typename?: 'LessonAssignSelectOutput', label: string, value: string } } };

export type DeleteScheduledPlannerLessonMutationVariables = Exact<{
  scheduledLessonId: Scalars['String'];
}>;


export type DeleteScheduledPlannerLessonMutation = { __typename?: 'Mutation', deleteScheduledPlannerLesson: boolean };

export type ScheduledPlannerLessonForCalendarQueryVariables = Exact<{
  childId: Scalars['String'];
  scheduledLessonId: Scalars['String'];
}>;


export type ScheduledPlannerLessonForCalendarQuery = { __typename?: 'Query', scheduledPlannerLessonForCalendar: { __typename?: 'SheduledLessonForCalendarOutput', childId: string, plannerId: string, scheduleDate: string, selectedCourse: { __typename?: 'CoursesSelectOutput', label: string, value: string }, selectedUnit: { __typename?: 'AssignUnitSelectOutput', label: string, value: string }, selectedLesson: { __typename?: 'LessonAssignSelectOutput', label: string, value: string }, courses: Array<{ __typename?: 'CoursesSelectOutput', label: string, value: string }>, units: Array<{ __typename?: 'AssignUnitSelectOutput', label: string, value: string }>, lessons: Array<{ __typename?: 'LessonAssignSelectOutput', label: string, value: string }> } };

export type ReferralSourceOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type ReferralSourceOptionsQuery = { __typename?: 'Query', referralSourceOptions: Array<{ __typename?: 'ReferralSourceOptionOutput', value: string, label: string }> };

export type LocationReportDataQueryVariables = Exact<{
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
}>;


export type LocationReportDataQuery = { __typename?: 'Query', locationReportData: Array<{ __typename?: 'ReportsLocationOutput', location: string, value: number }> };

export type MetricReportDataQueryVariables = Exact<{
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
}>;


export type MetricReportDataQuery = { __typename?: 'Query', metricReportData: { __typename?: 'ReportsMetricOutput', newlyRegistered: number, verifiedAccounts: number, activeAccounts: number, deadAccounts: number, newActive: number, newCancel: number, newTrial: number, newPastDue: number, renewalTrial: number, renewalActive: number, renewalCancel: number, renewalPastDue: number } };

export type UserCountriesReportQueryVariables = Exact<{ [key: string]: never; }>;


export type UserCountriesReportQuery = { __typename?: 'Query', userCountriesReport: Array<{ __typename?: 'UserLocations', country: string }> };

export type CountryReportDataQueryVariables = Exact<{
  country: Scalars['String'];
  location: Scalars['String'];
}>;


export type CountryReportDataQuery = { __typename?: 'Query', countryReportData: Array<{ __typename?: 'ReportsLocationOutput', location: string, value: number }> };

export type LocationReportsByDateQueryVariables = Exact<{
  country?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
}>;


export type LocationReportsByDateQuery = { __typename?: 'Query', locationReportsByDate: Array<{ __typename?: 'ReportsDateOutput', date: string, value: number }> };

export type SubscriptionsByDateQueryVariables = Exact<{
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
}>;


export type SubscriptionsByDateQuery = { __typename?: 'Query', subscriptionsByDate: Array<{ __typename?: 'SubscriptionsByDate', date: string, status: string, value: number }> };

export type ParentsMetricStatsQueryVariables = Exact<{
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
}>;


export type ParentsMetricStatsQuery = { __typename?: 'Query', parentsMetricStats: { __typename?: 'ParentMetricOutput', newlyRegistered: number, verifiedAccounts: number, activeAccounts: number, deadAccounts: number, newActive: number, newCancel: number, newTrial: number, newPastDue: number } };

export type ChildMetricStatsQueryVariables = Exact<{
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
}>;


export type ChildMetricStatsQuery = { __typename?: 'Query', childMetricStats: { __typename?: 'ChildMetricOutput', activeSubscriptions: number, cancelledSubscriptions: number, trialSubscriptions: number, pastDueSubscriptions: number } };

export type GetPaymentsByDateQueryVariables = Exact<{
  paying: Scalars['Boolean'];
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
}>;


export type GetPaymentsByDateQuery = { __typename?: 'Query', getPaymentsByDate: Array<{ __typename?: 'ReportsDateOutput', date: string, value: number }> };

export type ChildSubscriptionTypesCountQueryVariables = Exact<{
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
}>;


export type ChildSubscriptionTypesCountQuery = { __typename?: 'Query', childSubscriptionTypesCount: { __typename?: 'ChildPlanMetricOutput', familyPlan: number, individualMonth: number, individualYear: number } };

export type RegistrationsByDateQueryVariables = Exact<{
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
}>;


export type RegistrationsByDateQuery = { __typename?: 'Query', registrationsByDate: Array<{ __typename?: 'SubscriptionsByDate', date: string, status: string, value: number }> };

export type GetCsvForSubscriptionStatusQueryVariables = Exact<{
  status: Scalars['String'];
  startDate?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
}>;


export type GetCsvForSubscriptionStatusQuery = { __typename?: 'Query', getCsvForSubscriptionStatus: Array<{ __typename?: 'csvIndividualMetricOutput', firstName: string, lastName: string, email: string, phone: string, interval: string, isFamilyPlan: boolean, status: string, subscription_start_date?: string | null, current_period_start_date?: string | null, current_period_end_date?: string | null, cancellation_reason?: string | null, _id: string }> };

export type GetCsvForVerifiedEducatorsQueryVariables = Exact<{
  status: Scalars['String'];
  startDate?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
}>;


export type GetCsvForVerifiedEducatorsQuery = { __typename?: 'Query', getCsvForVerifiedEducators: Array<{ __typename?: 'csvIndividualEducatorMetricOutput', firstName: string, lastName: string, email: string, phone: string, _id: string }> };

export type GetStudentsByGradeCountryQueryVariables = Exact<{
  grade: Scalars['String'];
  country: Scalars['String'];
}>;


export type GetStudentsByGradeCountryQuery = { __typename?: 'Query', getStudentsByGradeCountry: Array<{ __typename?: 'StudentsForReports', parentFirstName?: string | null, parentLastName?: string | null, email?: string | null, phone?: string | null, status?: string | null, subscriptionStartDate?: string | null, country?: string | null, grade?: string | null, studentFirstName?: string | null, studentLastName?: string | null, _id: string }> };

export type CourseIconsQueryVariables = Exact<{ [key: string]: never; }>;


export type CourseIconsQuery = { __typename?: 'Query', courseIcons: Array<string> };

export type CoursePlanetsQueryVariables = Exact<{ [key: string]: never; }>;


export type CoursePlanetsQuery = { __typename?: 'Query', coursePlanets: Array<string> };

export type SubjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type SubjectsQuery = { __typename?: 'Query', subjects: Array<{ __typename?: 'Subject', _id: string, slug: string, title: string }> };

export type MarketplaceCoursesQueryVariables = Exact<{
  input: GetForMarketplaceInput;
}>;


export type MarketplaceCoursesQuery = { __typename?: 'Query', marketplaceCourses: { __typename?: 'MarketplaceCourseOutput', courses: Array<{ __typename?: 'MarketplaceCoursesCourse', _id: string, name: string, image: string, price: number, isBundleCourse: boolean, MSCategory?: Array<MsCategory> | null, grade?: { __typename?: 'MarketplaceCourseGrade', _id: string, slug: string } | null, subject?: { __typename?: 'MarketplaceCourseSubject', _id: string, slug: string } | null }>, pagination: { __typename?: 'Pagination', pages: number, page: number, total: number, limit: number } } };

export type OpenMarketplaceCoursesQueryVariables = Exact<{
  input: GetForOpenMarketplaceInput;
}>;


export type OpenMarketplaceCoursesQuery = { __typename?: 'Query', openMarketplaceCourses: { __typename?: 'MarketplaceCourseOutput', courses: Array<{ __typename?: 'MarketplaceCoursesCourse', _id: string, name: string, image: string, price: number, isBundleCourse: boolean, grade?: { __typename?: 'MarketplaceCourseGrade', _id: string, slug: string } | null, subject?: { __typename?: 'MarketplaceCourseSubject', _id: string, slug: string } | null }>, pagination: { __typename?: 'Pagination', pages: number, page: number, total: number, limit: number } } };

export type AssignCoursesMutationVariables = Exact<{
  childCourseIds: Array<AssignCourseInput> | AssignCourseInput;
}>;


export type AssignCoursesMutation = { __typename?: 'Mutation', assignCourses: { __typename?: 'AssignForChildrenOutput', success: boolean, paymentUrl?: string | null, sessionId?: string | null } };

export type CheckCoursesPurchaseMutationVariables = Exact<{
  sessionId: Scalars['String'];
}>;


export type CheckCoursesPurchaseMutation = { __typename?: 'Mutation', checkCoursesPurchase: { __typename?: 'CheckCoursesPurchaseOutput', success: boolean, sessionStatus?: SessionStatus | null, currentSessionUrl?: string | null } };

export type CourseDetailsForMarketplaceQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type CourseDetailsForMarketplaceQuery = { __typename?: 'Query', courseDetailsForMarketplace: { __typename?: 'CourseDetailsOutput', _id: string, name: string, image: string, courseInfoVideoLink?: string | null, description: string, units: Array<{ __typename?: 'CourseDetailsUnit', _id: string, name: string, lessons: Array<{ __typename?: 'CourseDetailsLesson', _id: string, name: string }> }> } };

export type SubscriptionPlansQueryVariables = Exact<{ [key: string]: never; }>;


export type SubscriptionPlansQuery = { __typename?: 'Query', subscriptionPlans: Array<{ __typename?: 'PlanOutput', _id: string, stripePriceId: string, interval: SubscriptionPlanInterval, price: string, prePriceText: string, postPriceText: string, isFamilyPlan: boolean }> };

export type SubscriptionPlansMscBasedQueryVariables = Exact<{ [key: string]: never; }>;


export type SubscriptionPlansMscBasedQuery = { __typename?: 'Query', subscriptionPlansMSCBased: Array<{ __typename?: 'PlanOutput', _id: string, stripePriceId: string, interval: SubscriptionPlanInterval, price: string, prePriceText: string, postPriceText: string, isMSCategoryBased?: boolean | null, MSCategory?: MsCategory | null }> };

export type SubscriptionFamilyPlansQueryVariables = Exact<{ [key: string]: never; }>;


export type SubscriptionFamilyPlansQuery = { __typename?: 'Query', subscriptionFamilyPlans: Array<{ __typename?: 'PlanOutput', _id: string, stripePriceId: string, interval: SubscriptionPlanInterval, price: string, prePriceText: string, postPriceText: string, isFamilyPlan: boolean }> };

export type AnnualPromotionQueryVariables = Exact<{ [key: string]: never; }>;


export type AnnualPromotionQuery = { __typename?: 'Query', annualPromotion?: { __typename?: 'GetPromotionOutput', _id: string, stripeCouponId: string, interval: PromotionForInterval, labelForFirstSubscription: string, labelForUpcomingSubscription: string, description: string, isPersonalPromotion: boolean } | null };

export type AnnualFamilyPromotionQueryVariables = Exact<{ [key: string]: never; }>;


export type AnnualFamilyPromotionQuery = { __typename?: 'Query', annualFamilyPromotion?: { __typename?: 'GetPromotionOutput', _id: string, stripeCouponId: string, interval: PromotionForInterval, labelForFirstSubscription: string, labelForUpcomingSubscription: string, description: string, isPersonalPromotion: boolean } | null };

export type CreatePaymentSessionMutationVariables = Exact<{
  childId: Scalars['String'];
  priceId: Scalars['String'];
  couponId?: InputMaybe<Scalars['String']>;
}>;


export type CreatePaymentSessionMutation = { __typename?: 'Mutation', createPaymentSession: string };

export type CancelSubscriptionMutationVariables = Exact<{
  childId: Scalars['String'];
  reason: Scalars['String'];
}>;


export type CancelSubscriptionMutation = { __typename?: 'Mutation', cancelSubscription: boolean };

export type ReactivateSubscriptionMutationVariables = Exact<{
  childId: Scalars['String'];
}>;


export type ReactivateSubscriptionMutation = { __typename?: 'Mutation', reactivateSubscription: boolean };

export type UpgradeToAnnualMutationVariables = Exact<{
  childId: Scalars['String'];
}>;


export type UpgradeToAnnualMutation = { __typename?: 'Mutation', upgradeToAnnual: boolean };

export type UpgradeToAnnualMscMutationVariables = Exact<{
  childId: Scalars['String'];
}>;


export type UpgradeToAnnualMscMutation = { __typename?: 'Mutation', upgradeToAnnualMSC: boolean };

export type SwitchMscPlanMutationVariables = Exact<{
  childId: Scalars['String'];
  priceId: Scalars['String'];
}>;


export type SwitchMscPlanMutation = { __typename?: 'Mutation', switchMSCPlan: boolean };

export type SubscriptionDetailsQueryVariables = Exact<{
  childId: Scalars['String'];
}>;


export type SubscriptionDetailsQuery = { __typename?: 'Query', subscriptionDetails?: { __typename?: 'SubscriptionDetailsOutput', _id: string, interval: SubscriptionPlanInterval, price: string, nextInvoice: string, reactivable: boolean } | null };

export type MscPlanDetailsFragment = { __typename?: 'PlanOutput', _id: string, stripePriceId: string, interval: SubscriptionPlanInterval, price: string, prePriceText: string, postPriceText: string, isFamilyPlan: boolean, isMSCategoryBased?: boolean | null, MSCategory?: MsCategory | null };

export type SubscriptionSwitchAndUpgradeDetailsForMscQueryVariables = Exact<{
  childId: Scalars['String'];
}>;


export type SubscriptionSwitchAndUpgradeDetailsForMscQuery = { __typename?: 'Query', subscriptionSwitchAndUpgradeDetailsForMSC?: { __typename?: 'SubscriptionSwitchAndUpgradeDetailsForMSCOutPut', switchPlanOptions: Array<{ __typename?: 'PlanOutput', _id: string, stripePriceId: string, interval: SubscriptionPlanInterval, price: string, prePriceText: string, postPriceText: string, isFamilyPlan: boolean, isMSCategoryBased?: boolean | null, MSCategory?: MsCategory | null }>, annualPlanOption?: { __typename?: 'PlanOutput', _id: string, stripePriceId: string, interval: SubscriptionPlanInterval, price: string, prePriceText: string, postPriceText: string, isFamilyPlan: boolean, isMSCategoryBased?: boolean | null, MSCategory?: MsCategory | null } | null } | null };

export type UserChildProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type UserChildProfileQuery = { __typename?: 'Query', userChildProfile: { __typename?: 'GetChildProfileOutput', _id: string, username: string, firstName: string, lastName: string, profilePic: string, email: string, parentEmail?: string | null, hasMetaverse: boolean, learningMode?: string | null, role: { __typename?: 'GetChildProfileRole', _id: string, role: UserRole }, lastMoodCheck?: { __typename?: 'GetChildProfileLastMoodCheck', _id: string, start?: string | null, end?: string | null } | null, grade?: { __typename?: 'GetChildProfileGrade', _id: string, title: string, slug: string } | null } };

export type ParentProfileFragment = { __typename?: 'GetParentProfileOutput', _id: string, city: string, country: string, countryCode: string, email: string, firstName: string, languages: Array<string>, lastName: string, phoneNumber: string, profilePic: string, state: string, username: string, tourCompleted: boolean, userDetailsConfirmed?: boolean | null, isFamilyAccount?: boolean | null, maxFamilyChildren?: number | null, membershipCode?: string | null, isVerified?: boolean | null, isMSCategoryBasedUser?: boolean | null, role: { __typename?: 'GetChildProfileRole', _id: string, role: UserRole } };

export type SchoolProfileFragment = { __typename?: 'GetSchoolProfileOutput', _id: string, founderName?: string | null, isEnrollmentOpen?: boolean | null, schoolName?: string | null, schoolType?: Array<SchoolType> | null, schoolDescription?: string | null, schoolImage?: string | null, showOnTranscript?: boolean | null };

export type VerifyUserDetailsMutationVariables = Exact<{ [key: string]: never; }>;


export type VerifyUserDetailsMutation = { __typename?: 'Mutation', verifyUserDetails?: { __typename?: 'VerifyUserDetails', parent?: { __typename?: 'VerifyParentDetails', _id: string, country: string, state: string, city: string, phoneNumber: string } | null, child?: { __typename?: 'VerifyDefaultStudentDetails', _id: string, userName: string, password: string, firstName: string, lastName: string, dateOfBirth: string, grade: string } | null } | null };

export type UserParentProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type UserParentProfileQuery = { __typename?: 'Query', userParentProfile: { __typename?: 'GetParentProfileOutput', _id: string, city: string, country: string, countryCode: string, email: string, firstName: string, languages: Array<string>, lastName: string, phoneNumber: string, profilePic: string, state: string, username: string, tourCompleted: boolean, userDetailsConfirmed?: boolean | null, isFamilyAccount?: boolean | null, maxFamilyChildren?: number | null, membershipCode?: string | null, isVerified?: boolean | null, isMSCategoryBasedUser?: boolean | null, role: { __typename?: 'GetChildProfileRole', _id: string, role: UserRole } } };

export type UserSchoolProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type UserSchoolProfileQuery = { __typename?: 'Query', userSchoolProfile: { __typename?: 'GetSchoolProfileOutput', _id: string, founderName?: string | null, isEnrollmentOpen?: boolean | null, schoolName?: string | null, schoolType?: Array<SchoolType> | null, schoolDescription?: string | null, schoolImage?: string | null, showOnTranscript?: boolean | null } };

export type SetLastMoodCheckMutationVariables = Exact<{
  input: SetLastMoodCheckInput;
}>;


export type SetLastMoodCheckMutation = { __typename?: 'Mutation', setLastMoodCheck: { __typename?: 'SetLastMoodCheckOutput', _id: string, start?: { __typename?: 'LastMoodCheckValue', date: string } | null, end?: { __typename?: 'LastMoodCheckValue', date: string } | null } };

export type SetUserProfileMutationVariables = Exact<{
  input: SetProfileInput;
}>;


export type SetUserProfileMutation = { __typename?: 'Mutation', setUserProfile: { __typename?: 'GetParentProfileOutput', _id: string, city: string, country: string, countryCode: string, email: string, firstName: string, languages: Array<string>, lastName: string, phoneNumber: string, profilePic: string, state: string, username: string, tourCompleted: boolean, userDetailsConfirmed?: boolean | null, isFamilyAccount?: boolean | null, maxFamilyChildren?: number | null, membershipCode?: string | null, isVerified?: boolean | null, isMSCategoryBasedUser?: boolean | null, role: { __typename?: 'GetChildProfileRole', _id: string, role: UserRole } } };

export type SetSchoolProfileMutationVariables = Exact<{
  input: SetSchoolProfileInput;
}>;


export type SetSchoolProfileMutation = { __typename?: 'Mutation', setSchoolProfile: { __typename?: 'GetSchoolProfileOutput', _id: string, founderName?: string | null, isEnrollmentOpen?: boolean | null, schoolName?: string | null, schoolType?: Array<SchoolType> | null, schoolDescription?: string | null, schoolImage?: string | null, showOnTranscript?: boolean | null } };

export type SetVerifiedUserProfileMutationVariables = Exact<{
  input: VerifyParentDetailsInput;
}>;


export type SetVerifiedUserProfileMutation = { __typename?: 'Mutation', setVerifiedUserProfile: { __typename?: 'GetParentProfileOutput', _id: string, city: string, country: string, countryCode: string, email: string, firstName: string, languages: Array<string>, lastName: string, phoneNumber: string, profilePic: string, state: string, username: string, tourCompleted: boolean, userDetailsConfirmed?: boolean | null, isFamilyAccount?: boolean | null, maxFamilyChildren?: number | null, membershipCode?: string | null, isVerified?: boolean | null, isMSCategoryBasedUser?: boolean | null, role: { __typename?: 'GetChildProfileRole', _id: string, role: UserRole } } };

export type IsEmailAvailableQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type IsEmailAvailableQuery = { __typename?: 'Query', isEmailAvailable: boolean };

export type IsUsernameAvailableQueryVariables = Exact<{
  userName: Scalars['String'];
}>;


export type IsUsernameAvailableQuery = { __typename?: 'Query', isUsernameAvailable: boolean };

export type IsPhoneNumberAvailableQueryVariables = Exact<{
  userId: Scalars['String'];
  phoneNumber: Scalars['String'];
}>;


export type IsPhoneNumberAvailableQuery = { __typename?: 'Query', isPhoneNumberAvailable: boolean };

export type ChildrenQueryVariables = Exact<{ [key: string]: never; }>;


export type ChildrenQuery = { __typename?: 'Query', children: Array<{ __typename?: 'GetChildrenOutput', _id: string, fullName: string, firstName: string, lastName: string, profilePic: string, isSubscribed: boolean, subscriptionStatus: string, trialEndAt?: string | null, isOnFreemium: boolean, isDefaultChild: boolean, learningMode?: string | null, MSCategory?: Array<MsCategory> | null, grade: { __typename?: 'GetChildrenGrade', _id: string, title: string, slug: string } }> };

export type DisableMetaverseMutationVariables = Exact<{
  childId: Scalars['String'];
}>;


export type DisableMetaverseMutation = { __typename?: 'Mutation', disableMetaverse: boolean };

export type AddChildMutationVariables = Exact<{
  input: AddChildInput;
}>;


export type AddChildMutation = { __typename?: 'Mutation', addChild: string };

export type ToggleChildAutoMutationVariables = Exact<{
  childId: Scalars['String'];
  learningMode: Scalars['String'];
}>;


export type ToggleChildAutoMutation = { __typename?: 'Mutation', toggleChildAuto: { __typename?: 'GetChildOutput', _id: string, userName: string, firstName: string, learningMode?: string | null, lastName: string, dateOfBirth: string, grade: string, phoneNumber?: string | null, email?: string | null, image?: string | null, studentId?: string | null } };

export type ToggleSchoolEnrollmentMutationVariables = Exact<{ [key: string]: never; }>;


export type ToggleSchoolEnrollmentMutation = { __typename?: 'Mutation', toggleSchoolEnrollment: { __typename?: 'GetSchoolProfileOutput', _id: string, founderName?: string | null, isEnrollmentOpen?: boolean | null, schoolName?: string | null, schoolType?: Array<SchoolType> | null, schoolDescription?: string | null, schoolImage?: string | null, showOnTranscript?: boolean | null } };

export type ToggleShowOnTranscriptMutationVariables = Exact<{ [key: string]: never; }>;


export type ToggleShowOnTranscriptMutation = { __typename?: 'Mutation', toggleShowOnTranscript: { __typename?: 'GetSchoolProfileOutput', _id: string, founderName?: string | null, isEnrollmentOpen?: boolean | null, schoolName?: string | null, schoolType?: Array<SchoolType> | null, schoolDescription?: string | null, schoolImage?: string | null, showOnTranscript?: boolean | null } };

export type DeleteChildMutationVariables = Exact<{
  childId: Scalars['String'];
}>;


export type DeleteChildMutation = { __typename?: 'Mutation', deleteChild: string };

export type EditChildMutationVariables = Exact<{
  input: EditChildInput;
}>;


export type EditChildMutation = { __typename?: 'Mutation', editChild: { __typename?: 'EditChildOutput', _id: string, grade: { __typename?: 'EditChildGrade', _id: string, title: string } } };

export type ChildQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ChildQuery = { __typename?: 'Query', child: { __typename?: 'GetChildOutput', _id: string, userName: string, firstName: string, learningMode?: string | null, lastName: string, dateOfBirth: string, grade: string, phoneNumber?: string | null, email?: string | null, image?: string | null, studentId?: string | null } };

export type CustomerPortalLinkQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomerPortalLinkQuery = { __typename?: 'Query', customerPortalLink: string };

export type NameExistOnProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type NameExistOnProfileQuery = { __typename?: 'Query', nameExistOnProfile: boolean };

export type SaveEducatorNamesMutationVariables = Exact<{
  input: AddEducatorRequiredInfoInput;
}>;


export type SaveEducatorNamesMutation = { __typename?: 'Mutation', saveEducatorNames: boolean };

export type MarkParentTourCompletedMutationVariables = Exact<{ [key: string]: never; }>;


export type MarkParentTourCompletedMutation = { __typename?: 'Mutation', markParentTourCompleted: boolean };

export type GetAccountTypeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAccountTypeQuery = { __typename?: 'Query', getAccountType?: { __typename?: 'AccountTypeOutput', accountType?: string | null } | null };

export type SetAccountTypeMutationVariables = Exact<{
  accountType: Scalars['String'];
}>;


export type SetAccountTypeMutation = { __typename?: 'Mutation', setAccountType: { __typename?: 'AccountTypeOutput', accountType?: string | null } };

export const CourseDataAutoPilotFragmentDoc = gql`
    fragment CourseDataAutoPilot on StartLessonCourseDataAutoPilot {
  _id
  takenCourseId
  subject {
    _id
    title
    slug
  }
  grade {
    _id
    title
    slug
  }
}
    `;
export const NotesFragmentDoc = gql`
    fragment Notes on NoteOutput {
  _id
  name
  createdAt
  content
  authorId
  wasRead
}
    `;
export const LessonDataFragmentDoc = gql`
    fragment LessonData on AssignLessonOutput {
  _id
  lessonName
  quizTitle
  quizUrl
  resources {
    link
    thumb
    title
  }
  lessonV {
    lessonVideo
    lessonVideoThumb
  }
  lessonContent
  isFinished
  childFinishDateTime
  youtubeLink
}
    `;
export const PdfFragmentDoc = gql`
    fragment Pdf on AssignLessonContentOutput {
  _id
  src
  isAnnotated
  name
  tag
  isStarted
  isFinished
}
    `;
export const LessonFragmentDoc = gql`
    fragment Lesson on ScheduledPlannerLesson {
  _id
  course {
    name
    subject {
      _id
      title
      slug
    }
  }
  unit {
    name
  }
  lesson {
    _id
    name
    canRead
    duration
    icon
    planet
  }
  scheduleDate
  status
  finishedAt
  createdAt
}
    `;
export const CourseDataFragmentDoc = gql`
    fragment CourseData on StartLessonCourseData {
  _id
  takenCourseId
  subject {
    _id
    title
    slug
  }
  grade {
    _id
    title
    slug
  }
}
    `;
export const QuizDataFragmentDoc = gql`
    fragment QuizData on ChildDetailedAnalyticsQuiz {
  _id
  score
  attempt
  updatedAt
  questions {
    _id
    title
    isMarkable
    questionType
    learnerResponse
    correctResponse
    isCorrect
  }
}
    `;
export const PaginationFragmentDoc = gql`
    fragment Pagination on Pagination {
  page
  pages
  total
  limit
}
    `;
export const AgreementDetailsFragmentDoc = gql`
    fragment agreementDetails on AgreementOutput {
  title
  content
}
    `;
export const DownloadDetailsFragmentDoc = gql`
    fragment DownloadDetails on DownloadPdfOutput {
  _id
  childId
  date
  maxDownloads
  currentDownloads
  isDeleted
}
    `;
export const MscPlanDetailsFragmentDoc = gql`
    fragment MscPlanDetails on PlanOutput {
  _id
  stripePriceId
  interval
  price
  prePriceText
  postPriceText
  isFamilyPlan
  isMSCategoryBased
  MSCategory
}
    `;
export const ParentProfileFragmentDoc = gql`
    fragment ParentProfile on GetParentProfileOutput {
  _id
  city
  country
  countryCode
  email
  firstName
  languages
  lastName
  phoneNumber
  profilePic
  role {
    _id
    role
  }
  state
  username
  tourCompleted
  userDetailsConfirmed
  isFamilyAccount
  maxFamilyChildren
  membershipCode
  isVerified
  isMSCategoryBasedUser
}
    `;
export const SchoolProfileFragmentDoc = gql`
    fragment SchoolProfile on GetSchoolProfileOutput {
  _id
  founderName
  isEnrollmentOpen
  schoolName
  schoolType
  schoolDescription
  schoolImage
  showOnTranscript
}
    `;
export const SaveEventDocument = gql`
    mutation saveEvent($input: SaveEventInput!) {
  saveEvent(input: $input)
}
    `;
export type SaveEventMutationFn = Apollo.MutationFunction<SaveEventMutation, SaveEventMutationVariables>;

/**
 * __useSaveEventMutation__
 *
 * To run a mutation, you first call `useSaveEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveEventMutation, { data, loading, error }] = useSaveEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveEventMutation(baseOptions?: Apollo.MutationHookOptions<SaveEventMutation, SaveEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveEventMutation, SaveEventMutationVariables>(SaveEventDocument, options);
      }
export type SaveEventMutationHookResult = ReturnType<typeof useSaveEventMutation>;
export type SaveEventMutationResult = Apollo.MutationResult<SaveEventMutation>;
export type SaveEventMutationOptions = Apollo.BaseMutationOptions<SaveEventMutation, SaveEventMutationVariables>;
export const ChildrenAnalyticsDocument = gql`
    query childrenAnalytics {
  childrenAnalytics {
    _id
    fullName
    profilePic
    mood
    grade {
      _id
      title
    }
    subjects {
      _id
      title
      slug
      progress
      score
    }
  }
}
    `;

/**
 * __useChildrenAnalyticsQuery__
 *
 * To run a query within a React component, call `useChildrenAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChildrenAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChildrenAnalyticsQuery({
 *   variables: {
 *   },
 * });
 */
export function useChildrenAnalyticsQuery(baseOptions?: Apollo.QueryHookOptions<ChildrenAnalyticsQuery, ChildrenAnalyticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChildrenAnalyticsQuery, ChildrenAnalyticsQueryVariables>(ChildrenAnalyticsDocument, options);
      }
export function useChildrenAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChildrenAnalyticsQuery, ChildrenAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChildrenAnalyticsQuery, ChildrenAnalyticsQueryVariables>(ChildrenAnalyticsDocument, options);
        }
export type ChildrenAnalyticsQueryHookResult = ReturnType<typeof useChildrenAnalyticsQuery>;
export type ChildrenAnalyticsLazyQueryHookResult = ReturnType<typeof useChildrenAnalyticsLazyQuery>;
export type ChildrenAnalyticsQueryResult = Apollo.QueryResult<ChildrenAnalyticsQuery, ChildrenAnalyticsQueryVariables>;
export const ChildDetailedAnalyticsDocument = gql`
    query childDetailedAnalytics($childId: String!) {
  childDetailedAnalytics(childId: $childId) {
    fullName
    courses {
      _id
      name
      isCompleted
      lessons {
        _id
        name
        quizes {
          _id
          score
          attempt
          updatedAt
          questions {
            _id
            title
            isMarkable
            questionType
            learnerResponse
            correctResponse
            isCorrect
          }
        }
      }
    }
  }
}
    `;

/**
 * __useChildDetailedAnalyticsQuery__
 *
 * To run a query within a React component, call `useChildDetailedAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChildDetailedAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChildDetailedAnalyticsQuery({
 *   variables: {
 *      childId: // value for 'childId'
 *   },
 * });
 */
export function useChildDetailedAnalyticsQuery(baseOptions: Apollo.QueryHookOptions<ChildDetailedAnalyticsQuery, ChildDetailedAnalyticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChildDetailedAnalyticsQuery, ChildDetailedAnalyticsQueryVariables>(ChildDetailedAnalyticsDocument, options);
      }
export function useChildDetailedAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChildDetailedAnalyticsQuery, ChildDetailedAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChildDetailedAnalyticsQuery, ChildDetailedAnalyticsQueryVariables>(ChildDetailedAnalyticsDocument, options);
        }
export type ChildDetailedAnalyticsQueryHookResult = ReturnType<typeof useChildDetailedAnalyticsQuery>;
export type ChildDetailedAnalyticsLazyQueryHookResult = ReturnType<typeof useChildDetailedAnalyticsLazyQuery>;
export type ChildDetailedAnalyticsQueryResult = Apollo.QueryResult<ChildDetailedAnalyticsQuery, ChildDetailedAnalyticsQueryVariables>;
export const ChildTranscriptDocument = gql`
    query childTranscript($childId: String!) {
  childTranscript(childId: $childId) {
    fullName
    schoolName
    courses {
      _id
      name
      units {
        _id
        name
        isCompleted
        updatedAt
        lessons {
          _id
          name
          quizes {
            _id
            score
            attempt
            updatedAt
            questions {
              _id
              title
              isMarkable
              questionType
              learnerResponse
              correctResponse
              isCorrect
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useChildTranscriptQuery__
 *
 * To run a query within a React component, call `useChildTranscriptQuery` and pass it any options that fit your needs.
 * When your component renders, `useChildTranscriptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChildTranscriptQuery({
 *   variables: {
 *      childId: // value for 'childId'
 *   },
 * });
 */
export function useChildTranscriptQuery(baseOptions: Apollo.QueryHookOptions<ChildTranscriptQuery, ChildTranscriptQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChildTranscriptQuery, ChildTranscriptQueryVariables>(ChildTranscriptDocument, options);
      }
export function useChildTranscriptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChildTranscriptQuery, ChildTranscriptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChildTranscriptQuery, ChildTranscriptQueryVariables>(ChildTranscriptDocument, options);
        }
export type ChildTranscriptQueryHookResult = ReturnType<typeof useChildTranscriptQuery>;
export type ChildTranscriptLazyQueryHookResult = ReturnType<typeof useChildTranscriptLazyQuery>;
export type ChildTranscriptQueryResult = Apollo.QueryResult<ChildTranscriptQuery, ChildTranscriptQueryVariables>;
export const ChangeLessonQuizScoreDocument = gql`
    mutation changeLessonQuizScore($childId: String!, $quizId: String!, $score: Float!) {
  changeLessonQuizScore(childId: $childId, quizId: $quizId, score: $score)
}
    `;
export type ChangeLessonQuizScoreMutationFn = Apollo.MutationFunction<ChangeLessonQuizScoreMutation, ChangeLessonQuizScoreMutationVariables>;

/**
 * __useChangeLessonQuizScoreMutation__
 *
 * To run a mutation, you first call `useChangeLessonQuizScoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeLessonQuizScoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeLessonQuizScoreMutation, { data, loading, error }] = useChangeLessonQuizScoreMutation({
 *   variables: {
 *      childId: // value for 'childId'
 *      quizId: // value for 'quizId'
 *      score: // value for 'score'
 *   },
 * });
 */
export function useChangeLessonQuizScoreMutation(baseOptions?: Apollo.MutationHookOptions<ChangeLessonQuizScoreMutation, ChangeLessonQuizScoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeLessonQuizScoreMutation, ChangeLessonQuizScoreMutationVariables>(ChangeLessonQuizScoreDocument, options);
      }
export type ChangeLessonQuizScoreMutationHookResult = ReturnType<typeof useChangeLessonQuizScoreMutation>;
export type ChangeLessonQuizScoreMutationResult = Apollo.MutationResult<ChangeLessonQuizScoreMutation>;
export type ChangeLessonQuizScoreMutationOptions = Apollo.BaseMutationOptions<ChangeLessonQuizScoreMutation, ChangeLessonQuizScoreMutationVariables>;
export const SavePdfDocument = gql`
    mutation savePdf($savePdfInput: SavePdfInput!) {
  savePdf(savePdfInput: $savePdfInput) {
    ...Pdf
  }
}
    ${PdfFragmentDoc}`;
export type SavePdfMutationFn = Apollo.MutationFunction<SavePdfMutation, SavePdfMutationVariables>;

/**
 * __useSavePdfMutation__
 *
 * To run a mutation, you first call `useSavePdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePdfMutation, { data, loading, error }] = useSavePdfMutation({
 *   variables: {
 *      savePdfInput: // value for 'savePdfInput'
 *   },
 * });
 */
export function useSavePdfMutation(baseOptions?: Apollo.MutationHookOptions<SavePdfMutation, SavePdfMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SavePdfMutation, SavePdfMutationVariables>(SavePdfDocument, options);
      }
export type SavePdfMutationHookResult = ReturnType<typeof useSavePdfMutation>;
export type SavePdfMutationResult = Apollo.MutationResult<SavePdfMutation>;
export type SavePdfMutationOptions = Apollo.BaseMutationOptions<SavePdfMutation, SavePdfMutationVariables>;
export const SearchLessonsByCourseDocument = gql`
    query SearchLessonsByCourse($courseId: String!, $query: String!) {
  searchLessonsByCourseAutoPilot(courseId: $courseId, query: $query) {
    _id
    isCompleted
    name
  }
}
    `;

/**
 * __useSearchLessonsByCourseQuery__
 *
 * To run a query within a React component, call `useSearchLessonsByCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchLessonsByCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchLessonsByCourseQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchLessonsByCourseQuery(baseOptions: Apollo.QueryHookOptions<SearchLessonsByCourseQuery, SearchLessonsByCourseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchLessonsByCourseQuery, SearchLessonsByCourseQueryVariables>(SearchLessonsByCourseDocument, options);
      }
export function useSearchLessonsByCourseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchLessonsByCourseQuery, SearchLessonsByCourseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchLessonsByCourseQuery, SearchLessonsByCourseQueryVariables>(SearchLessonsByCourseDocument, options);
        }
export type SearchLessonsByCourseQueryHookResult = ReturnType<typeof useSearchLessonsByCourseQuery>;
export type SearchLessonsByCourseLazyQueryHookResult = ReturnType<typeof useSearchLessonsByCourseLazyQuery>;
export type SearchLessonsByCourseQueryResult = Apollo.QueryResult<SearchLessonsByCourseQuery, SearchLessonsByCourseQueryVariables>;
export const StartLessonAutoPilotDocument = gql`
    mutation StartLessonAutoPilot($lessonId: String!) {
  startLessonAutoPilot(lessonId: $lessonId) {
    _id
    lessonData {
      ...LessonData
    }
    courseData {
      ...CourseDataAutoPilot
    }
    notes {
      ...Notes
    }
    pdfs {
      ...Pdf
    }
  }
}
    ${LessonDataFragmentDoc}
${CourseDataAutoPilotFragmentDoc}
${NotesFragmentDoc}
${PdfFragmentDoc}`;
export type StartLessonAutoPilotMutationFn = Apollo.MutationFunction<StartLessonAutoPilotMutation, StartLessonAutoPilotMutationVariables>;

/**
 * __useStartLessonAutoPilotMutation__
 *
 * To run a mutation, you first call `useStartLessonAutoPilotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartLessonAutoPilotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startLessonAutoPilotMutation, { data, loading, error }] = useStartLessonAutoPilotMutation({
 *   variables: {
 *      lessonId: // value for 'lessonId'
 *   },
 * });
 */
export function useStartLessonAutoPilotMutation(baseOptions?: Apollo.MutationHookOptions<StartLessonAutoPilotMutation, StartLessonAutoPilotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartLessonAutoPilotMutation, StartLessonAutoPilotMutationVariables>(StartLessonAutoPilotDocument, options);
      }
export type StartLessonAutoPilotMutationHookResult = ReturnType<typeof useStartLessonAutoPilotMutation>;
export type StartLessonAutoPilotMutationResult = Apollo.MutationResult<StartLessonAutoPilotMutation>;
export type StartLessonAutoPilotMutationOptions = Apollo.BaseMutationOptions<StartLessonAutoPilotMutation, StartLessonAutoPilotMutationVariables>;
export const FinishLessonAutoPilotDocument = gql`
    mutation finishLessonAutoPilot($lessonId: String!) {
  finishLessonAutoPilot(lessonId: $lessonId) {
    lessonData {
      _id
    }
  }
}
    `;
export type FinishLessonAutoPilotMutationFn = Apollo.MutationFunction<FinishLessonAutoPilotMutation, FinishLessonAutoPilotMutationVariables>;

/**
 * __useFinishLessonAutoPilotMutation__
 *
 * To run a mutation, you first call `useFinishLessonAutoPilotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishLessonAutoPilotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishLessonAutoPilotMutation, { data, loading, error }] = useFinishLessonAutoPilotMutation({
 *   variables: {
 *      lessonId: // value for 'lessonId'
 *   },
 * });
 */
export function useFinishLessonAutoPilotMutation(baseOptions?: Apollo.MutationHookOptions<FinishLessonAutoPilotMutation, FinishLessonAutoPilotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinishLessonAutoPilotMutation, FinishLessonAutoPilotMutationVariables>(FinishLessonAutoPilotDocument, options);
      }
export type FinishLessonAutoPilotMutationHookResult = ReturnType<typeof useFinishLessonAutoPilotMutation>;
export type FinishLessonAutoPilotMutationResult = Apollo.MutationResult<FinishLessonAutoPilotMutation>;
export type FinishLessonAutoPilotMutationOptions = Apollo.BaseMutationOptions<FinishLessonAutoPilotMutation, FinishLessonAutoPilotMutationVariables>;
export const LessonDetailsAutoPilotDocument = gql`
    query lessonDetailsAutoPilot($courseId: String!) {
  lessonDetailsAutoPilot(courseId: $courseId) {
    _id
  }
}
    `;

/**
 * __useLessonDetailsAutoPilotQuery__
 *
 * To run a query within a React component, call `useLessonDetailsAutoPilotQuery` and pass it any options that fit your needs.
 * When your component renders, `useLessonDetailsAutoPilotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLessonDetailsAutoPilotQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useLessonDetailsAutoPilotQuery(baseOptions: Apollo.QueryHookOptions<LessonDetailsAutoPilotQuery, LessonDetailsAutoPilotQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LessonDetailsAutoPilotQuery, LessonDetailsAutoPilotQueryVariables>(LessonDetailsAutoPilotDocument, options);
      }
export function useLessonDetailsAutoPilotLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LessonDetailsAutoPilotQuery, LessonDetailsAutoPilotQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LessonDetailsAutoPilotQuery, LessonDetailsAutoPilotQueryVariables>(LessonDetailsAutoPilotDocument, options);
        }
export type LessonDetailsAutoPilotQueryHookResult = ReturnType<typeof useLessonDetailsAutoPilotQuery>;
export type LessonDetailsAutoPilotLazyQueryHookResult = ReturnType<typeof useLessonDetailsAutoPilotLazyQuery>;
export type LessonDetailsAutoPilotQueryResult = Apollo.QueryResult<LessonDetailsAutoPilotQuery, LessonDetailsAutoPilotQueryVariables>;
export const CoursesByChildAutoPilotDocument = gql`
    query coursesByChildAutoPilot {
  coursesByChildAutoPilot {
    courses {
      _id
      image
      isCustom
      name
      planet
      isHidden
      icon
      lessons
      completedLessons
      progress
      subject {
        _id
        slug
      }
    }
  }
}
    `;

/**
 * __useCoursesByChildAutoPilotQuery__
 *
 * To run a query within a React component, call `useCoursesByChildAutoPilotQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoursesByChildAutoPilotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoursesByChildAutoPilotQuery({
 *   variables: {
 *   },
 * });
 */
export function useCoursesByChildAutoPilotQuery(baseOptions?: Apollo.QueryHookOptions<CoursesByChildAutoPilotQuery, CoursesByChildAutoPilotQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CoursesByChildAutoPilotQuery, CoursesByChildAutoPilotQueryVariables>(CoursesByChildAutoPilotDocument, options);
      }
export function useCoursesByChildAutoPilotLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CoursesByChildAutoPilotQuery, CoursesByChildAutoPilotQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CoursesByChildAutoPilotQuery, CoursesByChildAutoPilotQueryVariables>(CoursesByChildAutoPilotDocument, options);
        }
export type CoursesByChildAutoPilotQueryHookResult = ReturnType<typeof useCoursesByChildAutoPilotQuery>;
export type CoursesByChildAutoPilotLazyQueryHookResult = ReturnType<typeof useCoursesByChildAutoPilotLazyQuery>;
export type CoursesByChildAutoPilotQueryResult = Apollo.QueryResult<CoursesByChildAutoPilotQuery, CoursesByChildAutoPilotQueryVariables>;
export const ChildCoursesDocument = gql`
    query childCourses($childId: String!, $limit: Float, $page: Float) {
  childCourses(childId: $childId, limit: $limit, page: $page) {
    courses {
      _id
      isHidden
      image
      name
      progress
      currentLessonName
      isCustom
      lessons
      completedLessons
      grade {
        _id
        slug
      }
      subject {
        _id
        slug
      }
      isRecurringExist
      isDeletable
    }
    pagination {
      ...Pagination
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useChildCoursesQuery__
 *
 * To run a query within a React component, call `useChildCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useChildCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChildCoursesQuery({
 *   variables: {
 *      childId: // value for 'childId'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useChildCoursesQuery(baseOptions: Apollo.QueryHookOptions<ChildCoursesQuery, ChildCoursesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChildCoursesQuery, ChildCoursesQueryVariables>(ChildCoursesDocument, options);
      }
export function useChildCoursesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChildCoursesQuery, ChildCoursesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChildCoursesQuery, ChildCoursesQueryVariables>(ChildCoursesDocument, options);
        }
export type ChildCoursesQueryHookResult = ReturnType<typeof useChildCoursesQuery>;
export type ChildCoursesLazyQueryHookResult = ReturnType<typeof useChildCoursesLazyQuery>;
export type ChildCoursesQueryResult = Apollo.QueryResult<ChildCoursesQuery, ChildCoursesQueryVariables>;
export const DeletedCoursesDocument = gql`
    query deletedCourses($childId: String!, $limit: Float, $page: Float) {
  deletedCourses(childId: $childId, limit: $limit, page: $page) {
    courses {
      _id
      image
      name
      progress
      currentLessonName
      isCustom
      isHidden
      grade {
        _id
        slug
      }
      subject {
        _id
        slug
      }
      isRecoverable
      deletedOn
    }
    pagination {
      ...Pagination
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useDeletedCoursesQuery__
 *
 * To run a query within a React component, call `useDeletedCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeletedCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeletedCoursesQuery({
 *   variables: {
 *      childId: // value for 'childId'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useDeletedCoursesQuery(baseOptions: Apollo.QueryHookOptions<DeletedCoursesQuery, DeletedCoursesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeletedCoursesQuery, DeletedCoursesQueryVariables>(DeletedCoursesDocument, options);
      }
export function useDeletedCoursesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeletedCoursesQuery, DeletedCoursesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeletedCoursesQuery, DeletedCoursesQueryVariables>(DeletedCoursesDocument, options);
        }
export type DeletedCoursesQueryHookResult = ReturnType<typeof useDeletedCoursesQuery>;
export type DeletedCoursesLazyQueryHookResult = ReturnType<typeof useDeletedCoursesLazyQuery>;
export type DeletedCoursesQueryResult = Apollo.QueryResult<DeletedCoursesQuery, DeletedCoursesQueryVariables>;
export const DeleteCourseDocument = gql`
    mutation deleteCourse($courseId: String!, $childId: String!) {
  deleteCourse(id: $courseId, childId: $childId)
}
    `;
export type DeleteCourseMutationFn = Apollo.MutationFunction<DeleteCourseMutation, DeleteCourseMutationVariables>;

/**
 * __useDeleteCourseMutation__
 *
 * To run a mutation, you first call `useDeleteCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCourseMutation, { data, loading, error }] = useDeleteCourseMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      childId: // value for 'childId'
 *   },
 * });
 */
export function useDeleteCourseMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCourseMutation, DeleteCourseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCourseMutation, DeleteCourseMutationVariables>(DeleteCourseDocument, options);
      }
export type DeleteCourseMutationHookResult = ReturnType<typeof useDeleteCourseMutation>;
export type DeleteCourseMutationResult = Apollo.MutationResult<DeleteCourseMutation>;
export type DeleteCourseMutationOptions = Apollo.BaseMutationOptions<DeleteCourseMutation, DeleteCourseMutationVariables>;
export const RecoverCourseDocument = gql`
    mutation recoverCourse($courseId: String!, $childId: String!) {
  recoverCourse(id: $courseId, childId: $childId)
}
    `;
export type RecoverCourseMutationFn = Apollo.MutationFunction<RecoverCourseMutation, RecoverCourseMutationVariables>;

/**
 * __useRecoverCourseMutation__
 *
 * To run a mutation, you first call `useRecoverCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecoverCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recoverCourseMutation, { data, loading, error }] = useRecoverCourseMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      childId: // value for 'childId'
 *   },
 * });
 */
export function useRecoverCourseMutation(baseOptions?: Apollo.MutationHookOptions<RecoverCourseMutation, RecoverCourseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecoverCourseMutation, RecoverCourseMutationVariables>(RecoverCourseDocument, options);
      }
export type RecoverCourseMutationHookResult = ReturnType<typeof useRecoverCourseMutation>;
export type RecoverCourseMutationResult = Apollo.MutationResult<RecoverCourseMutation>;
export type RecoverCourseMutationOptions = Apollo.BaseMutationOptions<RecoverCourseMutation, RecoverCourseMutationVariables>;
export const RecoverSelectedFromMarketplaceDocument = gql`
    mutation recoverSelectedFromMarketplace($childId: String!, $takenCourseId: String!) {
  recoverSelectedFromMarketplace(childId: $childId, takenCourseId: $takenCourseId)
}
    `;
export type RecoverSelectedFromMarketplaceMutationFn = Apollo.MutationFunction<RecoverSelectedFromMarketplaceMutation, RecoverSelectedFromMarketplaceMutationVariables>;

/**
 * __useRecoverSelectedFromMarketplaceMutation__
 *
 * To run a mutation, you first call `useRecoverSelectedFromMarketplaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecoverSelectedFromMarketplaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recoverSelectedFromMarketplaceMutation, { data, loading, error }] = useRecoverSelectedFromMarketplaceMutation({
 *   variables: {
 *      childId: // value for 'childId'
 *      takenCourseId: // value for 'takenCourseId'
 *   },
 * });
 */
export function useRecoverSelectedFromMarketplaceMutation(baseOptions?: Apollo.MutationHookOptions<RecoverSelectedFromMarketplaceMutation, RecoverSelectedFromMarketplaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecoverSelectedFromMarketplaceMutation, RecoverSelectedFromMarketplaceMutationVariables>(RecoverSelectedFromMarketplaceDocument, options);
      }
export type RecoverSelectedFromMarketplaceMutationHookResult = ReturnType<typeof useRecoverSelectedFromMarketplaceMutation>;
export type RecoverSelectedFromMarketplaceMutationResult = Apollo.MutationResult<RecoverSelectedFromMarketplaceMutation>;
export type RecoverSelectedFromMarketplaceMutationOptions = Apollo.BaseMutationOptions<RecoverSelectedFromMarketplaceMutation, RecoverSelectedFromMarketplaceMutationVariables>;
export const ReplaceWithNewCourseDocument = gql`
    mutation replaceWithNewCourse($childId: String!, $takenCourseId: String!) {
  replaceWithNewCourse(childId: $childId, takenCourseId: $takenCourseId)
}
    `;
export type ReplaceWithNewCourseMutationFn = Apollo.MutationFunction<ReplaceWithNewCourseMutation, ReplaceWithNewCourseMutationVariables>;

/**
 * __useReplaceWithNewCourseMutation__
 *
 * To run a mutation, you first call `useReplaceWithNewCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplaceWithNewCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replaceWithNewCourseMutation, { data, loading, error }] = useReplaceWithNewCourseMutation({
 *   variables: {
 *      childId: // value for 'childId'
 *      takenCourseId: // value for 'takenCourseId'
 *   },
 * });
 */
export function useReplaceWithNewCourseMutation(baseOptions?: Apollo.MutationHookOptions<ReplaceWithNewCourseMutation, ReplaceWithNewCourseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReplaceWithNewCourseMutation, ReplaceWithNewCourseMutationVariables>(ReplaceWithNewCourseDocument, options);
      }
export type ReplaceWithNewCourseMutationHookResult = ReturnType<typeof useReplaceWithNewCourseMutation>;
export type ReplaceWithNewCourseMutationResult = Apollo.MutationResult<ReplaceWithNewCourseMutation>;
export type ReplaceWithNewCourseMutationOptions = Apollo.BaseMutationOptions<ReplaceWithNewCourseMutation, ReplaceWithNewCourseMutationVariables>;
export const SearchChildCoursesDocument = gql`
    query searchChildCourses($childId: String!, $query: String!) {
  searchChildCourses(childId: $childId, query: $query) {
    _id
    image
    name
    isHidden
    progress
    currentLessonName
    isCustom
    isRecurringExist
    isDeletable
  }
}
    `;

/**
 * __useSearchChildCoursesQuery__
 *
 * To run a query within a React component, call `useSearchChildCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchChildCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchChildCoursesQuery({
 *   variables: {
 *      childId: // value for 'childId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchChildCoursesQuery(baseOptions: Apollo.QueryHookOptions<SearchChildCoursesQuery, SearchChildCoursesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchChildCoursesQuery, SearchChildCoursesQueryVariables>(SearchChildCoursesDocument, options);
      }
export function useSearchChildCoursesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchChildCoursesQuery, SearchChildCoursesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchChildCoursesQuery, SearchChildCoursesQueryVariables>(SearchChildCoursesDocument, options);
        }
export type SearchChildCoursesQueryHookResult = ReturnType<typeof useSearchChildCoursesQuery>;
export type SearchChildCoursesLazyQueryHookResult = ReturnType<typeof useSearchChildCoursesLazyQuery>;
export type SearchChildCoursesQueryResult = Apollo.QueryResult<SearchChildCoursesQuery, SearchChildCoursesQueryVariables>;
export const CourseDetailsDocument = gql`
    query courseDetails($childId: String!, $courseId: String!) {
  courseDetails(childId: $childId, courseId: $courseId) {
    _id
    image
    name
    progress
    isHidden
    lessons
    completedLessons
    currentLessonName
    isCustom
    grade {
      _id
      slug
    }
    subject {
      _id
      slug
    }
    units {
      _id
      name
      progress
      lessons
      completedLessons
      hiddenLessons
      badge
      courseTakenId
      isRecurringExist
      isHidden
      badge
    }
    isRecurringExist
    isDeletable
  }
}
    `;

/**
 * __useCourseDetailsQuery__
 *
 * To run a query within a React component, call `useCourseDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseDetailsQuery({
 *   variables: {
 *      childId: // value for 'childId'
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useCourseDetailsQuery(baseOptions: Apollo.QueryHookOptions<CourseDetailsQuery, CourseDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CourseDetailsQuery, CourseDetailsQueryVariables>(CourseDetailsDocument, options);
      }
export function useCourseDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CourseDetailsQuery, CourseDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CourseDetailsQuery, CourseDetailsQueryVariables>(CourseDetailsDocument, options);
        }
export type CourseDetailsQueryHookResult = ReturnType<typeof useCourseDetailsQuery>;
export type CourseDetailsLazyQueryHookResult = ReturnType<typeof useCourseDetailsLazyQuery>;
export type CourseDetailsQueryResult = Apollo.QueryResult<CourseDetailsQuery, CourseDetailsQueryVariables>;
export const CreateCustomCourseDocument = gql`
    mutation createCustomCourse($childId: String!, $courseName: String!) {
  createCustomCourse(childId: $childId, courseName: $courseName) {
    _id
    image
    name
  }
}
    `;
export type CreateCustomCourseMutationFn = Apollo.MutationFunction<CreateCustomCourseMutation, CreateCustomCourseMutationVariables>;

/**
 * __useCreateCustomCourseMutation__
 *
 * To run a mutation, you first call `useCreateCustomCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomCourseMutation, { data, loading, error }] = useCreateCustomCourseMutation({
 *   variables: {
 *      childId: // value for 'childId'
 *      courseName: // value for 'courseName'
 *   },
 * });
 */
export function useCreateCustomCourseMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomCourseMutation, CreateCustomCourseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomCourseMutation, CreateCustomCourseMutationVariables>(CreateCustomCourseDocument, options);
      }
export type CreateCustomCourseMutationHookResult = ReturnType<typeof useCreateCustomCourseMutation>;
export type CreateCustomCourseMutationResult = Apollo.MutationResult<CreateCustomCourseMutation>;
export type CreateCustomCourseMutationOptions = Apollo.BaseMutationOptions<CreateCustomCourseMutation, CreateCustomCourseMutationVariables>;
export const ChildAtLeastOneCourseDocument = gql`
    query childAtLeastOneCourse($childId: String!) {
  childAtLeastOneCourse(childId: $childId)
}
    `;

/**
 * __useChildAtLeastOneCourseQuery__
 *
 * To run a query within a React component, call `useChildAtLeastOneCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useChildAtLeastOneCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChildAtLeastOneCourseQuery({
 *   variables: {
 *      childId: // value for 'childId'
 *   },
 * });
 */
export function useChildAtLeastOneCourseQuery(baseOptions: Apollo.QueryHookOptions<ChildAtLeastOneCourseQuery, ChildAtLeastOneCourseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChildAtLeastOneCourseQuery, ChildAtLeastOneCourseQueryVariables>(ChildAtLeastOneCourseDocument, options);
      }
export function useChildAtLeastOneCourseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChildAtLeastOneCourseQuery, ChildAtLeastOneCourseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChildAtLeastOneCourseQuery, ChildAtLeastOneCourseQueryVariables>(ChildAtLeastOneCourseDocument, options);
        }
export type ChildAtLeastOneCourseQueryHookResult = ReturnType<typeof useChildAtLeastOneCourseQuery>;
export type ChildAtLeastOneCourseLazyQueryHookResult = ReturnType<typeof useChildAtLeastOneCourseLazyQuery>;
export type ChildAtLeastOneCourseQueryResult = Apollo.QueryResult<ChildAtLeastOneCourseQuery, ChildAtLeastOneCourseQueryVariables>;
export const IsCourseRecoverableFromDeletedListDocument = gql`
    query isCourseRecoverableFromDeletedList($childId: String!, $courseId: String!) {
  isCourseRecoverableFromDeletedList(childId: $childId, courseId: $courseId)
}
    `;

/**
 * __useIsCourseRecoverableFromDeletedListQuery__
 *
 * To run a query within a React component, call `useIsCourseRecoverableFromDeletedListQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsCourseRecoverableFromDeletedListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsCourseRecoverableFromDeletedListQuery({
 *   variables: {
 *      childId: // value for 'childId'
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useIsCourseRecoverableFromDeletedListQuery(baseOptions: Apollo.QueryHookOptions<IsCourseRecoverableFromDeletedListQuery, IsCourseRecoverableFromDeletedListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsCourseRecoverableFromDeletedListQuery, IsCourseRecoverableFromDeletedListQueryVariables>(IsCourseRecoverableFromDeletedListDocument, options);
      }
export function useIsCourseRecoverableFromDeletedListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsCourseRecoverableFromDeletedListQuery, IsCourseRecoverableFromDeletedListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsCourseRecoverableFromDeletedListQuery, IsCourseRecoverableFromDeletedListQueryVariables>(IsCourseRecoverableFromDeletedListDocument, options);
        }
export type IsCourseRecoverableFromDeletedListQueryHookResult = ReturnType<typeof useIsCourseRecoverableFromDeletedListQuery>;
export type IsCourseRecoverableFromDeletedListLazyQueryHookResult = ReturnType<typeof useIsCourseRecoverableFromDeletedListLazyQuery>;
export type IsCourseRecoverableFromDeletedListQueryResult = Apollo.QueryResult<IsCourseRecoverableFromDeletedListQuery, IsCourseRecoverableFromDeletedListQueryVariables>;
export const ToggleCourseHideUnhideDocument = gql`
    mutation toggleCourseHideUnhide($courseId: String!, $isHidden: Boolean!) {
  toggleCourseHideUnhide(courseId: $courseId, isHidden: $isHidden)
}
    `;
export type ToggleCourseHideUnhideMutationFn = Apollo.MutationFunction<ToggleCourseHideUnhideMutation, ToggleCourseHideUnhideMutationVariables>;

/**
 * __useToggleCourseHideUnhideMutation__
 *
 * To run a mutation, you first call `useToggleCourseHideUnhideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleCourseHideUnhideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleCourseHideUnhideMutation, { data, loading, error }] = useToggleCourseHideUnhideMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      isHidden: // value for 'isHidden'
 *   },
 * });
 */
export function useToggleCourseHideUnhideMutation(baseOptions?: Apollo.MutationHookOptions<ToggleCourseHideUnhideMutation, ToggleCourseHideUnhideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleCourseHideUnhideMutation, ToggleCourseHideUnhideMutationVariables>(ToggleCourseHideUnhideDocument, options);
      }
export type ToggleCourseHideUnhideMutationHookResult = ReturnType<typeof useToggleCourseHideUnhideMutation>;
export type ToggleCourseHideUnhideMutationResult = Apollo.MutationResult<ToggleCourseHideUnhideMutation>;
export type ToggleCourseHideUnhideMutationOptions = Apollo.BaseMutationOptions<ToggleCourseHideUnhideMutation, ToggleCourseHideUnhideMutationVariables>;
export const LessonsByUnitAutoPilotDocument = gql`
    query lessonsByUnitAutoPilot($unitId: String!) {
  lessonsByUnitAutoPilot(unitId: $unitId) {
    _id
    isCompleted
    name
  }
}
    `;

/**
 * __useLessonsByUnitAutoPilotQuery__
 *
 * To run a query within a React component, call `useLessonsByUnitAutoPilotQuery` and pass it any options that fit your needs.
 * When your component renders, `useLessonsByUnitAutoPilotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLessonsByUnitAutoPilotQuery({
 *   variables: {
 *      unitId: // value for 'unitId'
 *   },
 * });
 */
export function useLessonsByUnitAutoPilotQuery(baseOptions: Apollo.QueryHookOptions<LessonsByUnitAutoPilotQuery, LessonsByUnitAutoPilotQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LessonsByUnitAutoPilotQuery, LessonsByUnitAutoPilotQueryVariables>(LessonsByUnitAutoPilotDocument, options);
      }
export function useLessonsByUnitAutoPilotLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LessonsByUnitAutoPilotQuery, LessonsByUnitAutoPilotQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LessonsByUnitAutoPilotQuery, LessonsByUnitAutoPilotQueryVariables>(LessonsByUnitAutoPilotDocument, options);
        }
export type LessonsByUnitAutoPilotQueryHookResult = ReturnType<typeof useLessonsByUnitAutoPilotQuery>;
export type LessonsByUnitAutoPilotLazyQueryHookResult = ReturnType<typeof useLessonsByUnitAutoPilotLazyQuery>;
export type LessonsByUnitAutoPilotQueryResult = Apollo.QueryResult<LessonsByUnitAutoPilotQuery, LessonsByUnitAutoPilotQueryVariables>;
export const LessonsByUnitDocument = gql`
    query lessonsByUnit($childId: String!, $unitId: String!) {
  lessonsByUnit(childId: $childId, unitId: $unitId) {
    _id
    name
    isCompleted
    isHidden
    plannerLessons {
      _id
      scheduleDate
    }
  }
}
    `;

/**
 * __useLessonsByUnitQuery__
 *
 * To run a query within a React component, call `useLessonsByUnitQuery` and pass it any options that fit your needs.
 * When your component renders, `useLessonsByUnitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLessonsByUnitQuery({
 *   variables: {
 *      childId: // value for 'childId'
 *      unitId: // value for 'unitId'
 *   },
 * });
 */
export function useLessonsByUnitQuery(baseOptions: Apollo.QueryHookOptions<LessonsByUnitQuery, LessonsByUnitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LessonsByUnitQuery, LessonsByUnitQueryVariables>(LessonsByUnitDocument, options);
      }
export function useLessonsByUnitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LessonsByUnitQuery, LessonsByUnitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LessonsByUnitQuery, LessonsByUnitQueryVariables>(LessonsByUnitDocument, options);
        }
export type LessonsByUnitQueryHookResult = ReturnType<typeof useLessonsByUnitQuery>;
export type LessonsByUnitLazyQueryHookResult = ReturnType<typeof useLessonsByUnitLazyQuery>;
export type LessonsByUnitQueryResult = Apollo.QueryResult<LessonsByUnitQuery, LessonsByUnitQueryVariables>;
export const CreateLessonDocument = gql`
    mutation createLesson($input: CreateLessonInput!) {
  createLesson(createLessonInput: $input)
}
    `;
export type CreateLessonMutationFn = Apollo.MutationFunction<CreateLessonMutation, CreateLessonMutationVariables>;

/**
 * __useCreateLessonMutation__
 *
 * To run a mutation, you first call `useCreateLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLessonMutation, { data, loading, error }] = useCreateLessonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLessonMutation(baseOptions?: Apollo.MutationHookOptions<CreateLessonMutation, CreateLessonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLessonMutation, CreateLessonMutationVariables>(CreateLessonDocument, options);
      }
export type CreateLessonMutationHookResult = ReturnType<typeof useCreateLessonMutation>;
export type CreateLessonMutationResult = Apollo.MutationResult<CreateLessonMutation>;
export type CreateLessonMutationOptions = Apollo.BaseMutationOptions<CreateLessonMutation, CreateLessonMutationVariables>;
export const UpdateLessonOrderDocument = gql`
    mutation updateLessonOrder($input: AssignLessonOrderInput!) {
  updateLessonOrder(assignLessonOrderInput: $input)
}
    `;
export type UpdateLessonOrderMutationFn = Apollo.MutationFunction<UpdateLessonOrderMutation, UpdateLessonOrderMutationVariables>;

/**
 * __useUpdateLessonOrderMutation__
 *
 * To run a mutation, you first call `useUpdateLessonOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLessonOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLessonOrderMutation, { data, loading, error }] = useUpdateLessonOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLessonOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLessonOrderMutation, UpdateLessonOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLessonOrderMutation, UpdateLessonOrderMutationVariables>(UpdateLessonOrderDocument, options);
      }
export type UpdateLessonOrderMutationHookResult = ReturnType<typeof useUpdateLessonOrderMutation>;
export type UpdateLessonOrderMutationResult = Apollo.MutationResult<UpdateLessonOrderMutation>;
export type UpdateLessonOrderMutationOptions = Apollo.BaseMutationOptions<UpdateLessonOrderMutation, UpdateLessonOrderMutationVariables>;
export const LessonDetailsDocument = gql`
    query lessonDetails($id: String!) {
  lessonDetails(id: $id) {
    _id
    title
    duration
    description
    pdfs {
      _id
      file
      tag
    }
    resources {
      _id
      title
      url
      thumbnail
    }
    icon
    planet
  }
}
    `;

/**
 * __useLessonDetailsQuery__
 *
 * To run a query within a React component, call `useLessonDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLessonDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLessonDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLessonDetailsQuery(baseOptions: Apollo.QueryHookOptions<LessonDetailsQuery, LessonDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LessonDetailsQuery, LessonDetailsQueryVariables>(LessonDetailsDocument, options);
      }
export function useLessonDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LessonDetailsQuery, LessonDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LessonDetailsQuery, LessonDetailsQueryVariables>(LessonDetailsDocument, options);
        }
export type LessonDetailsQueryHookResult = ReturnType<typeof useLessonDetailsQuery>;
export type LessonDetailsLazyQueryHookResult = ReturnType<typeof useLessonDetailsLazyQuery>;
export type LessonDetailsQueryResult = Apollo.QueryResult<LessonDetailsQuery, LessonDetailsQueryVariables>;
export const EditLessonDocument = gql`
    mutation editLesson($input: EditLessonInput!) {
  editLesson(editLessonInput: $input)
}
    `;
export type EditLessonMutationFn = Apollo.MutationFunction<EditLessonMutation, EditLessonMutationVariables>;

/**
 * __useEditLessonMutation__
 *
 * To run a mutation, you first call `useEditLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editLessonMutation, { data, loading, error }] = useEditLessonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditLessonMutation(baseOptions?: Apollo.MutationHookOptions<EditLessonMutation, EditLessonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditLessonMutation, EditLessonMutationVariables>(EditLessonDocument, options);
      }
export type EditLessonMutationHookResult = ReturnType<typeof useEditLessonMutation>;
export type EditLessonMutationResult = Apollo.MutationResult<EditLessonMutation>;
export type EditLessonMutationOptions = Apollo.BaseMutationOptions<EditLessonMutation, EditLessonMutationVariables>;
export const ToggleLessonHideUnhideDocument = gql`
    mutation toggleLessonHideUnhide($lessonId: String!, $isHidden: Boolean!) {
  toggleLessonHideUnhide(lessonId: $lessonId, isHidden: $isHidden)
}
    `;
export type ToggleLessonHideUnhideMutationFn = Apollo.MutationFunction<ToggleLessonHideUnhideMutation, ToggleLessonHideUnhideMutationVariables>;

/**
 * __useToggleLessonHideUnhideMutation__
 *
 * To run a mutation, you first call `useToggleLessonHideUnhideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleLessonHideUnhideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleLessonHideUnhideMutation, { data, loading, error }] = useToggleLessonHideUnhideMutation({
 *   variables: {
 *      lessonId: // value for 'lessonId'
 *      isHidden: // value for 'isHidden'
 *   },
 * });
 */
export function useToggleLessonHideUnhideMutation(baseOptions?: Apollo.MutationHookOptions<ToggleLessonHideUnhideMutation, ToggleLessonHideUnhideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleLessonHideUnhideMutation, ToggleLessonHideUnhideMutationVariables>(ToggleLessonHideUnhideDocument, options);
      }
export type ToggleLessonHideUnhideMutationHookResult = ReturnType<typeof useToggleLessonHideUnhideMutation>;
export type ToggleLessonHideUnhideMutationResult = Apollo.MutationResult<ToggleLessonHideUnhideMutation>;
export type ToggleLessonHideUnhideMutationOptions = Apollo.BaseMutationOptions<ToggleLessonHideUnhideMutation, ToggleLessonHideUnhideMutationVariables>;
export const AddNoteDocument = gql`
    mutation addNote($note: String!, $lessonId: String!, $name: String!) {
  addNote(note: $note, lessonId: $lessonId, name: $name) {
    _id
    name
    createdAt
    content
    authorId
    wasRead
  }
}
    `;
export type AddNoteMutationFn = Apollo.MutationFunction<AddNoteMutation, AddNoteMutationVariables>;

/**
 * __useAddNoteMutation__
 *
 * To run a mutation, you first call `useAddNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNoteMutation, { data, loading, error }] = useAddNoteMutation({
 *   variables: {
 *      note: // value for 'note'
 *      lessonId: // value for 'lessonId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddNoteMutation(baseOptions?: Apollo.MutationHookOptions<AddNoteMutation, AddNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddNoteMutation, AddNoteMutationVariables>(AddNoteDocument, options);
      }
export type AddNoteMutationHookResult = ReturnType<typeof useAddNoteMutation>;
export type AddNoteMutationResult = Apollo.MutationResult<AddNoteMutation>;
export type AddNoteMutationOptions = Apollo.BaseMutationOptions<AddNoteMutation, AddNoteMutationVariables>;
export const ReadNoteDocument = gql`
    mutation readNote($noteId: String!) {
  readNote(noteId: $noteId) {
    _id
    wasRead
  }
}
    `;
export type ReadNoteMutationFn = Apollo.MutationFunction<ReadNoteMutation, ReadNoteMutationVariables>;

/**
 * __useReadNoteMutation__
 *
 * To run a mutation, you first call `useReadNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readNoteMutation, { data, loading, error }] = useReadNoteMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useReadNoteMutation(baseOptions?: Apollo.MutationHookOptions<ReadNoteMutation, ReadNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReadNoteMutation, ReadNoteMutationVariables>(ReadNoteDocument, options);
      }
export type ReadNoteMutationHookResult = ReturnType<typeof useReadNoteMutation>;
export type ReadNoteMutationResult = Apollo.MutationResult<ReadNoteMutation>;
export type ReadNoteMutationOptions = Apollo.BaseMutationOptions<ReadNoteMutation, ReadNoteMutationVariables>;
export const EditNoteDocument = gql`
    mutation editNote($noteId: String!, $content: String!) {
  editNote(noteId: $noteId, content: $content) {
    _id
    content
  }
}
    `;
export type EditNoteMutationFn = Apollo.MutationFunction<EditNoteMutation, EditNoteMutationVariables>;

/**
 * __useEditNoteMutation__
 *
 * To run a mutation, you first call `useEditNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editNoteMutation, { data, loading, error }] = useEditNoteMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useEditNoteMutation(baseOptions?: Apollo.MutationHookOptions<EditNoteMutation, EditNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditNoteMutation, EditNoteMutationVariables>(EditNoteDocument, options);
      }
export type EditNoteMutationHookResult = ReturnType<typeof useEditNoteMutation>;
export type EditNoteMutationResult = Apollo.MutationResult<EditNoteMutation>;
export type EditNoteMutationOptions = Apollo.BaseMutationOptions<EditNoteMutation, EditNoteMutationVariables>;
export const DeleteNoteDocument = gql`
    mutation deleteNote($noteId: String!) {
  deleteNote(noteId: $noteId) {
    _id
  }
}
    `;
export type DeleteNoteMutationFn = Apollo.MutationFunction<DeleteNoteMutation, DeleteNoteMutationVariables>;

/**
 * __useDeleteNoteMutation__
 *
 * To run a mutation, you first call `useDeleteNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNoteMutation, { data, loading, error }] = useDeleteNoteMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useDeleteNoteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNoteMutation, DeleteNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNoteMutation, DeleteNoteMutationVariables>(DeleteNoteDocument, options);
      }
export type DeleteNoteMutationHookResult = ReturnType<typeof useDeleteNoteMutation>;
export type DeleteNoteMutationResult = Apollo.MutationResult<DeleteNoteMutation>;
export type DeleteNoteMutationOptions = Apollo.BaseMutationOptions<DeleteNoteMutation, DeleteNoteMutationVariables>;
export const NotificationsDocument = gql`
    query notifications {
  notifications {
    _id
    isRead
    title
    description
    createdAt
  }
}
    `;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
      }
export function useNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<NotificationsQuery, NotificationsQueryVariables>;
export const ReadNotificationsDocument = gql`
    mutation readNotifications($ids: [String!]!) {
  readNotifications(ids: $ids)
}
    `;
export type ReadNotificationsMutationFn = Apollo.MutationFunction<ReadNotificationsMutation, ReadNotificationsMutationVariables>;

/**
 * __useReadNotificationsMutation__
 *
 * To run a mutation, you first call `useReadNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readNotificationsMutation, { data, loading, error }] = useReadNotificationsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useReadNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<ReadNotificationsMutation, ReadNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReadNotificationsMutation, ReadNotificationsMutationVariables>(ReadNotificationsDocument, options);
      }
export type ReadNotificationsMutationHookResult = ReturnType<typeof useReadNotificationsMutation>;
export type ReadNotificationsMutationResult = Apollo.MutationResult<ReadNotificationsMutation>;
export type ReadNotificationsMutationOptions = Apollo.BaseMutationOptions<ReadNotificationsMutation, ReadNotificationsMutationVariables>;
export const DeleteNotificationDocument = gql`
    mutation deleteNotification($id: String!) {
  deleteNotification(id: $id) {
    _id
  }
}
    `;
export type DeleteNotificationMutationFn = Apollo.MutationFunction<DeleteNotificationMutation, DeleteNotificationMutationVariables>;

/**
 * __useDeleteNotificationMutation__
 *
 * To run a mutation, you first call `useDeleteNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotificationMutation, { data, loading, error }] = useDeleteNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNotificationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNotificationMutation, DeleteNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNotificationMutation, DeleteNotificationMutationVariables>(DeleteNotificationDocument, options);
      }
export type DeleteNotificationMutationHookResult = ReturnType<typeof useDeleteNotificationMutation>;
export type DeleteNotificationMutationResult = Apollo.MutationResult<DeleteNotificationMutation>;
export type DeleteNotificationMutationOptions = Apollo.BaseMutationOptions<DeleteNotificationMutation, DeleteNotificationMutationVariables>;
export const DeleteNotificationsDocument = gql`
    mutation deleteNotifications($ids: [String!]!) {
  deleteNotifications(ids: $ids)
}
    `;
export type DeleteNotificationsMutationFn = Apollo.MutationFunction<DeleteNotificationsMutation, DeleteNotificationsMutationVariables>;

/**
 * __useDeleteNotificationsMutation__
 *
 * To run a mutation, you first call `useDeleteNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotificationsMutation, { data, loading, error }] = useDeleteNotificationsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNotificationsMutation, DeleteNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNotificationsMutation, DeleteNotificationsMutationVariables>(DeleteNotificationsDocument, options);
      }
export type DeleteNotificationsMutationHookResult = ReturnType<typeof useDeleteNotificationsMutation>;
export type DeleteNotificationsMutationResult = Apollo.MutationResult<DeleteNotificationsMutation>;
export type DeleteNotificationsMutationOptions = Apollo.BaseMutationOptions<DeleteNotificationsMutation, DeleteNotificationsMutationVariables>;
export const NotificationsForPageDocument = gql`
    query notificationsForPage($studentId: String, $limit: Float, $page: Float) {
  notificationsForPage(studentId: $studentId, limit: $limit, page: $page) {
    notifications {
      _id
      profileImage
      fullName
      senderId
      parentId
      createdAt
      updatedAt
      title
      content
      description
      isPublic
    }
    pagination {
      pages
      page
      total
      limit
    }
  }
}
    `;

/**
 * __useNotificationsForPageQuery__
 *
 * To run a query within a React component, call `useNotificationsForPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsForPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsForPageQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useNotificationsForPageQuery(baseOptions?: Apollo.QueryHookOptions<NotificationsForPageQuery, NotificationsForPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsForPageQuery, NotificationsForPageQueryVariables>(NotificationsForPageDocument, options);
      }
export function useNotificationsForPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsForPageQuery, NotificationsForPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsForPageQuery, NotificationsForPageQueryVariables>(NotificationsForPageDocument, options);
        }
export type NotificationsForPageQueryHookResult = ReturnType<typeof useNotificationsForPageQuery>;
export type NotificationsForPageLazyQueryHookResult = ReturnType<typeof useNotificationsForPageLazyQuery>;
export type NotificationsForPageQueryResult = Apollo.QueryResult<NotificationsForPageQuery, NotificationsForPageQueryVariables>;
export const GetBannerAdvertisementsDocument = gql`
    query getBannerAdvertisements($studentId: String) {
  getBannerAdvertisements(studentId: $studentId) {
    _id
    title
    content
    description
    bannerImage
    advertisementImage
    destinationUrl
  }
}
    `;

/**
 * __useGetBannerAdvertisementsQuery__
 *
 * To run a query within a React component, call `useGetBannerAdvertisementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBannerAdvertisementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBannerAdvertisementsQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useGetBannerAdvertisementsQuery(baseOptions?: Apollo.QueryHookOptions<GetBannerAdvertisementsQuery, GetBannerAdvertisementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBannerAdvertisementsQuery, GetBannerAdvertisementsQueryVariables>(GetBannerAdvertisementsDocument, options);
      }
export function useGetBannerAdvertisementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBannerAdvertisementsQuery, GetBannerAdvertisementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBannerAdvertisementsQuery, GetBannerAdvertisementsQueryVariables>(GetBannerAdvertisementsDocument, options);
        }
export type GetBannerAdvertisementsQueryHookResult = ReturnType<typeof useGetBannerAdvertisementsQuery>;
export type GetBannerAdvertisementsLazyQueryHookResult = ReturnType<typeof useGetBannerAdvertisementsLazyQuery>;
export type GetBannerAdvertisementsQueryResult = Apollo.QueryResult<GetBannerAdvertisementsQuery, GetBannerAdvertisementsQueryVariables>;
export const GetOneTimeAdvertisementAndMarkReadDocument = gql`
    query getOneTimeAdvertisementAndMarkRead {
  getOneTimeAdvertisementAndMarkRead {
    _id
    title
    content
    description
    bannerImage
    advertisementImage
    destinationUrl
  }
}
    `;

/**
 * __useGetOneTimeAdvertisementAndMarkReadQuery__
 *
 * To run a query within a React component, call `useGetOneTimeAdvertisementAndMarkReadQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneTimeAdvertisementAndMarkReadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneTimeAdvertisementAndMarkReadQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOneTimeAdvertisementAndMarkReadQuery(baseOptions?: Apollo.QueryHookOptions<GetOneTimeAdvertisementAndMarkReadQuery, GetOneTimeAdvertisementAndMarkReadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOneTimeAdvertisementAndMarkReadQuery, GetOneTimeAdvertisementAndMarkReadQueryVariables>(GetOneTimeAdvertisementAndMarkReadDocument, options);
      }
export function useGetOneTimeAdvertisementAndMarkReadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOneTimeAdvertisementAndMarkReadQuery, GetOneTimeAdvertisementAndMarkReadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOneTimeAdvertisementAndMarkReadQuery, GetOneTimeAdvertisementAndMarkReadQueryVariables>(GetOneTimeAdvertisementAndMarkReadDocument, options);
        }
export type GetOneTimeAdvertisementAndMarkReadQueryHookResult = ReturnType<typeof useGetOneTimeAdvertisementAndMarkReadQuery>;
export type GetOneTimeAdvertisementAndMarkReadLazyQueryHookResult = ReturnType<typeof useGetOneTimeAdvertisementAndMarkReadLazyQuery>;
export type GetOneTimeAdvertisementAndMarkReadQueryResult = Apollo.QueryResult<GetOneTimeAdvertisementAndMarkReadQuery, GetOneTimeAdvertisementAndMarkReadQueryVariables>;
export const PlannerLessonsDocument = gql`
    query plannerLessons {
  plannerLessons {
    todayAndUncompleted {
      ...Lesson
    }
    completed {
      ...Lesson
    }
  }
}
    ${LessonFragmentDoc}`;

/**
 * __usePlannerLessonsQuery__
 *
 * To run a query within a React component, call `usePlannerLessonsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlannerLessonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlannerLessonsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePlannerLessonsQuery(baseOptions?: Apollo.QueryHookOptions<PlannerLessonsQuery, PlannerLessonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlannerLessonsQuery, PlannerLessonsQueryVariables>(PlannerLessonsDocument, options);
      }
export function usePlannerLessonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlannerLessonsQuery, PlannerLessonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlannerLessonsQuery, PlannerLessonsQueryVariables>(PlannerLessonsDocument, options);
        }
export type PlannerLessonsQueryHookResult = ReturnType<typeof usePlannerLessonsQuery>;
export type PlannerLessonsLazyQueryHookResult = ReturnType<typeof usePlannerLessonsLazyQuery>;
export type PlannerLessonsQueryResult = Apollo.QueryResult<PlannerLessonsQuery, PlannerLessonsQueryVariables>;
export const CheckForBadgeCelebrationDocument = gql`
    mutation checkForBadgeCelebration($childId: String!) {
  checkForBadgeCelebration(childId: $childId) {
    _id
    name
    progress
    badge
    courseId
  }
}
    `;
export type CheckForBadgeCelebrationMutationFn = Apollo.MutationFunction<CheckForBadgeCelebrationMutation, CheckForBadgeCelebrationMutationVariables>;

/**
 * __useCheckForBadgeCelebrationMutation__
 *
 * To run a mutation, you first call `useCheckForBadgeCelebrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckForBadgeCelebrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkForBadgeCelebrationMutation, { data, loading, error }] = useCheckForBadgeCelebrationMutation({
 *   variables: {
 *      childId: // value for 'childId'
 *   },
 * });
 */
export function useCheckForBadgeCelebrationMutation(baseOptions?: Apollo.MutationHookOptions<CheckForBadgeCelebrationMutation, CheckForBadgeCelebrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckForBadgeCelebrationMutation, CheckForBadgeCelebrationMutationVariables>(CheckForBadgeCelebrationDocument, options);
      }
export type CheckForBadgeCelebrationMutationHookResult = ReturnType<typeof useCheckForBadgeCelebrationMutation>;
export type CheckForBadgeCelebrationMutationResult = Apollo.MutationResult<CheckForBadgeCelebrationMutation>;
export type CheckForBadgeCelebrationMutationOptions = Apollo.BaseMutationOptions<CheckForBadgeCelebrationMutation, CheckForBadgeCelebrationMutationVariables>;
export const StartLessonDocument = gql`
    mutation startLesson($plannerLessonId: String!) {
  startLesson(plannerLessonId: $plannerLessonId) {
    _id
    status
    scheduleDate
    lessonData {
      ...LessonData
    }
    courseData {
      ...CourseData
    }
    notes {
      ...Notes
    }
    pdfs {
      ...Pdf
    }
  }
}
    ${LessonDataFragmentDoc}
${CourseDataFragmentDoc}
${NotesFragmentDoc}
${PdfFragmentDoc}`;
export type StartLessonMutationFn = Apollo.MutationFunction<StartLessonMutation, StartLessonMutationVariables>;

/**
 * __useStartLessonMutation__
 *
 * To run a mutation, you first call `useStartLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startLessonMutation, { data, loading, error }] = useStartLessonMutation({
 *   variables: {
 *      plannerLessonId: // value for 'plannerLessonId'
 *   },
 * });
 */
export function useStartLessonMutation(baseOptions?: Apollo.MutationHookOptions<StartLessonMutation, StartLessonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartLessonMutation, StartLessonMutationVariables>(StartLessonDocument, options);
      }
export type StartLessonMutationHookResult = ReturnType<typeof useStartLessonMutation>;
export type StartLessonMutationResult = Apollo.MutationResult<StartLessonMutation>;
export type StartLessonMutationOptions = Apollo.BaseMutationOptions<StartLessonMutation, StartLessonMutationVariables>;
export const StartParentLessonDocument = gql`
    mutation startParentLesson($lessonId: String!) {
  startParentLesson(lessonId: $lessonId) {
    _id
    lessonData {
      ...LessonData
    }
    courseData {
      ...CourseData
    }
    notes {
      ...Notes
    }
    pdfs {
      ...Pdf
    }
    quizData {
      ...QuizData
    }
  }
}
    ${LessonDataFragmentDoc}
${CourseDataFragmentDoc}
${NotesFragmentDoc}
${PdfFragmentDoc}
${QuizDataFragmentDoc}`;
export type StartParentLessonMutationFn = Apollo.MutationFunction<StartParentLessonMutation, StartParentLessonMutationVariables>;

/**
 * __useStartParentLessonMutation__
 *
 * To run a mutation, you first call `useStartParentLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartParentLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startParentLessonMutation, { data, loading, error }] = useStartParentLessonMutation({
 *   variables: {
 *      lessonId: // value for 'lessonId'
 *   },
 * });
 */
export function useStartParentLessonMutation(baseOptions?: Apollo.MutationHookOptions<StartParentLessonMutation, StartParentLessonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartParentLessonMutation, StartParentLessonMutationVariables>(StartParentLessonDocument, options);
      }
export type StartParentLessonMutationHookResult = ReturnType<typeof useStartParentLessonMutation>;
export type StartParentLessonMutationResult = Apollo.MutationResult<StartParentLessonMutation>;
export type StartParentLessonMutationOptions = Apollo.BaseMutationOptions<StartParentLessonMutation, StartParentLessonMutationVariables>;
export const FinishLessonDocument = gql`
    mutation finishLesson($finishLessonInput: FinishLessonInput!) {
  finishLesson(finishLessonInput: $finishLessonInput) {
    lessonData {
      _id
    }
  }
}
    `;
export type FinishLessonMutationFn = Apollo.MutationFunction<FinishLessonMutation, FinishLessonMutationVariables>;

/**
 * __useFinishLessonMutation__
 *
 * To run a mutation, you first call `useFinishLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishLessonMutation, { data, loading, error }] = useFinishLessonMutation({
 *   variables: {
 *      finishLessonInput: // value for 'finishLessonInput'
 *   },
 * });
 */
export function useFinishLessonMutation(baseOptions?: Apollo.MutationHookOptions<FinishLessonMutation, FinishLessonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinishLessonMutation, FinishLessonMutationVariables>(FinishLessonDocument, options);
      }
export type FinishLessonMutationHookResult = ReturnType<typeof useFinishLessonMutation>;
export type FinishLessonMutationResult = Apollo.MutationResult<FinishLessonMutation>;
export type FinishLessonMutationOptions = Apollo.BaseMutationOptions<FinishLessonMutation, FinishLessonMutationVariables>;
export const HistoryLessonsDocument = gql`
    query historyLessons {
  historyLessons {
    title
    slug
    lessons {
      _id
      finishedAt
      course {
        name
      }
      unit {
        name
      }
      lesson {
        name
      }
    }
  }
}
    `;

/**
 * __useHistoryLessonsQuery__
 *
 * To run a query within a React component, call `useHistoryLessonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoryLessonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoryLessonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useHistoryLessonsQuery(baseOptions?: Apollo.QueryHookOptions<HistoryLessonsQuery, HistoryLessonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HistoryLessonsQuery, HistoryLessonsQueryVariables>(HistoryLessonsDocument, options);
      }
export function useHistoryLessonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HistoryLessonsQuery, HistoryLessonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HistoryLessonsQuery, HistoryLessonsQueryVariables>(HistoryLessonsDocument, options);
        }
export type HistoryLessonsQueryHookResult = ReturnType<typeof useHistoryLessonsQuery>;
export type HistoryLessonsLazyQueryHookResult = ReturnType<typeof useHistoryLessonsLazyQuery>;
export type HistoryLessonsQueryResult = Apollo.QueryResult<HistoryLessonsQuery, HistoryLessonsQueryVariables>;
export const UnitsForBadgesDocument = gql`
    query unitsForBadges($childId: String!) {
  unitsForBadges(childId: $childId) {
    _id
    name
    courseTakenId
    progress
    units {
      _id
      name
      progress
      badge
    }
  }
}
    `;

/**
 * __useUnitsForBadgesQuery__
 *
 * To run a query within a React component, call `useUnitsForBadgesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitsForBadgesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitsForBadgesQuery({
 *   variables: {
 *      childId: // value for 'childId'
 *   },
 * });
 */
export function useUnitsForBadgesQuery(baseOptions: Apollo.QueryHookOptions<UnitsForBadgesQuery, UnitsForBadgesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnitsForBadgesQuery, UnitsForBadgesQueryVariables>(UnitsForBadgesDocument, options);
      }
export function useUnitsForBadgesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnitsForBadgesQuery, UnitsForBadgesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnitsForBadgesQuery, UnitsForBadgesQueryVariables>(UnitsForBadgesDocument, options);
        }
export type UnitsForBadgesQueryHookResult = ReturnType<typeof useUnitsForBadgesQuery>;
export type UnitsForBadgesLazyQueryHookResult = ReturnType<typeof useUnitsForBadgesLazyQuery>;
export type UnitsForBadgesQueryResult = Apollo.QueryResult<UnitsForBadgesQuery, UnitsForBadgesQueryVariables>;
export const SearchHistoryLessonsDocument = gql`
    query SearchHistoryLessons($query: String!) {
  searchHistoryLessons(query: $query) {
    title
    slug
    lessons {
      _id
      finishedAt
      course {
        name
      }
      unit {
        name
      }
      lesson {
        name
      }
    }
  }
}
    `;

/**
 * __useSearchHistoryLessonsQuery__
 *
 * To run a query within a React component, call `useSearchHistoryLessonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchHistoryLessonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchHistoryLessonsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchHistoryLessonsQuery(baseOptions: Apollo.QueryHookOptions<SearchHistoryLessonsQuery, SearchHistoryLessonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchHistoryLessonsQuery, SearchHistoryLessonsQueryVariables>(SearchHistoryLessonsDocument, options);
      }
export function useSearchHistoryLessonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchHistoryLessonsQuery, SearchHistoryLessonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchHistoryLessonsQuery, SearchHistoryLessonsQueryVariables>(SearchHistoryLessonsDocument, options);
        }
export type SearchHistoryLessonsQueryHookResult = ReturnType<typeof useSearchHistoryLessonsQuery>;
export type SearchHistoryLessonsLazyQueryHookResult = ReturnType<typeof useSearchHistoryLessonsLazyQuery>;
export type SearchHistoryLessonsQueryResult = Apollo.QueryResult<SearchHistoryLessonsQuery, SearchHistoryLessonsQueryVariables>;
export const ChangeLessonCompletionStatusDocument = gql`
    mutation changeLessonCompletionStatus($childId: String!, $assignLessonId: String!, $score: Float) {
  changeLessonCompletionStatus(
    childId: $childId
    assignLessonId: $assignLessonId
    score: $score
  )
}
    `;
export type ChangeLessonCompletionStatusMutationFn = Apollo.MutationFunction<ChangeLessonCompletionStatusMutation, ChangeLessonCompletionStatusMutationVariables>;

/**
 * __useChangeLessonCompletionStatusMutation__
 *
 * To run a mutation, you first call `useChangeLessonCompletionStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeLessonCompletionStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeLessonCompletionStatusMutation, { data, loading, error }] = useChangeLessonCompletionStatusMutation({
 *   variables: {
 *      childId: // value for 'childId'
 *      assignLessonId: // value for 'assignLessonId'
 *      score: // value for 'score'
 *   },
 * });
 */
export function useChangeLessonCompletionStatusMutation(baseOptions?: Apollo.MutationHookOptions<ChangeLessonCompletionStatusMutation, ChangeLessonCompletionStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeLessonCompletionStatusMutation, ChangeLessonCompletionStatusMutationVariables>(ChangeLessonCompletionStatusDocument, options);
      }
export type ChangeLessonCompletionStatusMutationHookResult = ReturnType<typeof useChangeLessonCompletionStatusMutation>;
export type ChangeLessonCompletionStatusMutationResult = Apollo.MutationResult<ChangeLessonCompletionStatusMutation>;
export type ChangeLessonCompletionStatusMutationOptions = Apollo.BaseMutationOptions<ChangeLessonCompletionStatusMutation, ChangeLessonCompletionStatusMutationVariables>;
export const UnitsByCourseAutoPilotDocument = gql`
    query unitsByCourseAutoPilot($courseId: String!) {
  unitsByCourseAutoPilot(courseId: $courseId) {
    isHidden
    lessons
    completedLessons
    name
    progress
    _id
  }
}
    `;

/**
 * __useUnitsByCourseAutoPilotQuery__
 *
 * To run a query within a React component, call `useUnitsByCourseAutoPilotQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitsByCourseAutoPilotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitsByCourseAutoPilotQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useUnitsByCourseAutoPilotQuery(baseOptions: Apollo.QueryHookOptions<UnitsByCourseAutoPilotQuery, UnitsByCourseAutoPilotQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnitsByCourseAutoPilotQuery, UnitsByCourseAutoPilotQueryVariables>(UnitsByCourseAutoPilotDocument, options);
      }
export function useUnitsByCourseAutoPilotLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnitsByCourseAutoPilotQuery, UnitsByCourseAutoPilotQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnitsByCourseAutoPilotQuery, UnitsByCourseAutoPilotQueryVariables>(UnitsByCourseAutoPilotDocument, options);
        }
export type UnitsByCourseAutoPilotQueryHookResult = ReturnType<typeof useUnitsByCourseAutoPilotQuery>;
export type UnitsByCourseAutoPilotLazyQueryHookResult = ReturnType<typeof useUnitsByCourseAutoPilotLazyQuery>;
export type UnitsByCourseAutoPilotQueryResult = Apollo.QueryResult<UnitsByCourseAutoPilotQuery, UnitsByCourseAutoPilotQueryVariables>;
export const DeleteUnitDocument = gql`
    mutation deleteUnit($id: String!, $childId: String!) {
  deleteUnit(id: $id, childId: $childId)
}
    `;
export type DeleteUnitMutationFn = Apollo.MutationFunction<DeleteUnitMutation, DeleteUnitMutationVariables>;

/**
 * __useDeleteUnitMutation__
 *
 * To run a mutation, you first call `useDeleteUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUnitMutation, { data, loading, error }] = useDeleteUnitMutation({
 *   variables: {
 *      id: // value for 'id'
 *      childId: // value for 'childId'
 *   },
 * });
 */
export function useDeleteUnitMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUnitMutation, DeleteUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUnitMutation, DeleteUnitMutationVariables>(DeleteUnitDocument, options);
      }
export type DeleteUnitMutationHookResult = ReturnType<typeof useDeleteUnitMutation>;
export type DeleteUnitMutationResult = Apollo.MutationResult<DeleteUnitMutation>;
export type DeleteUnitMutationOptions = Apollo.BaseMutationOptions<DeleteUnitMutation, DeleteUnitMutationVariables>;
export const ChangeNameUnitDocument = gql`
    mutation changeNameUnit($unitId: String!, $name: String!) {
  changeNameUnit(unitId: $unitId, name: $name)
}
    `;
export type ChangeNameUnitMutationFn = Apollo.MutationFunction<ChangeNameUnitMutation, ChangeNameUnitMutationVariables>;

/**
 * __useChangeNameUnitMutation__
 *
 * To run a mutation, you first call `useChangeNameUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeNameUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeNameUnitMutation, { data, loading, error }] = useChangeNameUnitMutation({
 *   variables: {
 *      unitId: // value for 'unitId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useChangeNameUnitMutation(baseOptions?: Apollo.MutationHookOptions<ChangeNameUnitMutation, ChangeNameUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeNameUnitMutation, ChangeNameUnitMutationVariables>(ChangeNameUnitDocument, options);
      }
export type ChangeNameUnitMutationHookResult = ReturnType<typeof useChangeNameUnitMutation>;
export type ChangeNameUnitMutationResult = Apollo.MutationResult<ChangeNameUnitMutation>;
export type ChangeNameUnitMutationOptions = Apollo.BaseMutationOptions<ChangeNameUnitMutation, ChangeNameUnitMutationVariables>;
export const ToggleUnitHideUnhideDocument = gql`
    mutation toggleUnitHideUnhide($unitId: String!, $isHidden: Boolean!, $childId: String!) {
  toggleUnitHideUnhide(unitId: $unitId, isHidden: $isHidden, childId: $childId)
}
    `;
export type ToggleUnitHideUnhideMutationFn = Apollo.MutationFunction<ToggleUnitHideUnhideMutation, ToggleUnitHideUnhideMutationVariables>;

/**
 * __useToggleUnitHideUnhideMutation__
 *
 * To run a mutation, you first call `useToggleUnitHideUnhideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleUnitHideUnhideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleUnitHideUnhideMutation, { data, loading, error }] = useToggleUnitHideUnhideMutation({
 *   variables: {
 *      unitId: // value for 'unitId'
 *      isHidden: // value for 'isHidden'
 *      childId: // value for 'childId'
 *   },
 * });
 */
export function useToggleUnitHideUnhideMutation(baseOptions?: Apollo.MutationHookOptions<ToggleUnitHideUnhideMutation, ToggleUnitHideUnhideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleUnitHideUnhideMutation, ToggleUnitHideUnhideMutationVariables>(ToggleUnitHideUnhideDocument, options);
      }
export type ToggleUnitHideUnhideMutationHookResult = ReturnType<typeof useToggleUnitHideUnhideMutation>;
export type ToggleUnitHideUnhideMutationResult = Apollo.MutationResult<ToggleUnitHideUnhideMutation>;
export type ToggleUnitHideUnhideMutationOptions = Apollo.BaseMutationOptions<ToggleUnitHideUnhideMutation, ToggleUnitHideUnhideMutationVariables>;
export const CreateUnitDocument = gql`
    mutation createUnit($childId: String!, $courseId: String!, $name: String!) {
  createUnit(childId: $childId, courseId: $courseId, name: $name) {
    _id
    name
  }
}
    `;
export type CreateUnitMutationFn = Apollo.MutationFunction<CreateUnitMutation, CreateUnitMutationVariables>;

/**
 * __useCreateUnitMutation__
 *
 * To run a mutation, you first call `useCreateUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUnitMutation, { data, loading, error }] = useCreateUnitMutation({
 *   variables: {
 *      childId: // value for 'childId'
 *      courseId: // value for 'courseId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateUnitMutation(baseOptions?: Apollo.MutationHookOptions<CreateUnitMutation, CreateUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUnitMutation, CreateUnitMutationVariables>(CreateUnitDocument, options);
      }
export type CreateUnitMutationHookResult = ReturnType<typeof useCreateUnitMutation>;
export type CreateUnitMutationResult = Apollo.MutationResult<CreateUnitMutation>;
export type CreateUnitMutationOptions = Apollo.BaseMutationOptions<CreateUnitMutation, CreateUnitMutationVariables>;
export const SignInDocument = gql`
    mutation signIn($authCredentials: SignInInput!) {
  signIn(authCredentials: $authCredentials) {
    user {
      _id
      username
      firstName
      lastName
      email
      lastMoodCheck {
        _id
        start
        end
      }
      role
      isOnFreemium
    }
    accessToken
    refreshToken
  }
}
    `;
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      authCredentials: // value for 'authCredentials'
 *   },
 * });
 */
export function useSignInMutation(baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, options);
      }
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<SignInMutation, SignInMutationVariables>;
export const SignUpDocument = gql`
    mutation signUp($input: SignUpInput!) {
  signUp(signUpInput: $input) {
    _id
    email
    user {
      _id
      username
      firstName
      lastName
      email
      lastMoodCheck {
        _id
        start
        end
      }
      role
      isOnFreemium
    }
    accessToken
    refreshToken
  }
}
    `;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const VerifyEmailDocument = gql`
    mutation verifyEmail($code: String!, $recaptchaToken: String!) {
  verifyEmail(code: $code, recaptchaToken: $recaptchaToken) {
    user {
      _id
      username
      firstName
      lastName
      email
      lastMoodCheck {
        _id
        start
        end
      }
      role
      isOnFreemium
    }
    accessToken
    refreshToken
  }
}
    `;
export type VerifyEmailMutationFn = Apollo.MutationFunction<VerifyEmailMutation, VerifyEmailMutationVariables>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      code: // value for 'code'
 *      recaptchaToken: // value for 'recaptchaToken'
 *   },
 * });
 */
export function useVerifyEmailMutation(baseOptions?: Apollo.MutationHookOptions<VerifyEmailMutation, VerifyEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(VerifyEmailDocument, options);
      }
export type VerifyEmailMutationHookResult = ReturnType<typeof useVerifyEmailMutation>;
export type VerifyEmailMutationResult = Apollo.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = Apollo.BaseMutationOptions<VerifyEmailMutation, VerifyEmailMutationVariables>;
export const ResendVerificationEmailDocument = gql`
    mutation resendVerificationEmail($usernameOrEmail: String!) {
  resendVerificationEmail(usernameOrEmail: $usernameOrEmail)
}
    `;
export type ResendVerificationEmailMutationFn = Apollo.MutationFunction<ResendVerificationEmailMutation, ResendVerificationEmailMutationVariables>;

/**
 * __useResendVerificationEmailMutation__
 *
 * To run a mutation, you first call `useResendVerificationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendVerificationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendVerificationEmailMutation, { data, loading, error }] = useResendVerificationEmailMutation({
 *   variables: {
 *      usernameOrEmail: // value for 'usernameOrEmail'
 *   },
 * });
 */
export function useResendVerificationEmailMutation(baseOptions?: Apollo.MutationHookOptions<ResendVerificationEmailMutation, ResendVerificationEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendVerificationEmailMutation, ResendVerificationEmailMutationVariables>(ResendVerificationEmailDocument, options);
      }
export type ResendVerificationEmailMutationHookResult = ReturnType<typeof useResendVerificationEmailMutation>;
export type ResendVerificationEmailMutationResult = Apollo.MutationResult<ResendVerificationEmailMutation>;
export type ResendVerificationEmailMutationOptions = Apollo.BaseMutationOptions<ResendVerificationEmailMutation, ResendVerificationEmailMutationVariables>;
export const RequestResetPasswordDocument = gql`
    mutation requestResetPassword($usernameOrEmail: String!, $recaptchaToken: String!) {
  requestResetPassword(
    usernameOrEmail: $usernameOrEmail
    recaptchaToken: $recaptchaToken
  ) {
    email
  }
}
    `;
export type RequestResetPasswordMutationFn = Apollo.MutationFunction<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>;

/**
 * __useRequestResetPasswordMutation__
 *
 * To run a mutation, you first call `useRequestResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestResetPasswordMutation, { data, loading, error }] = useRequestResetPasswordMutation({
 *   variables: {
 *      usernameOrEmail: // value for 'usernameOrEmail'
 *      recaptchaToken: // value for 'recaptchaToken'
 *   },
 * });
 */
export function useRequestResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>(RequestResetPasswordDocument, options);
      }
export type RequestResetPasswordMutationHookResult = ReturnType<typeof useRequestResetPasswordMutation>;
export type RequestResetPasswordMutationResult = Apollo.MutationResult<RequestResetPasswordMutation>;
export type RequestResetPasswordMutationOptions = Apollo.BaseMutationOptions<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($input: ResetPasswordInput!) {
  resetPassword(resetPasswordInput: $input)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const RefreshDocument = gql`
    mutation refresh {
  refresh {
    user {
      _id
      username
      firstName
      lastName
      email
      role
    }
    accessToken
    refreshToken
  }
}
    `;
export type RefreshMutationFn = Apollo.MutationFunction<RefreshMutation, RefreshMutationVariables>;

/**
 * __useRefreshMutation__
 *
 * To run a mutation, you first call `useRefreshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshMutation, { data, loading, error }] = useRefreshMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshMutation(baseOptions?: Apollo.MutationHookOptions<RefreshMutation, RefreshMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshMutation, RefreshMutationVariables>(RefreshDocument, options);
      }
export type RefreshMutationHookResult = ReturnType<typeof useRefreshMutation>;
export type RefreshMutationResult = Apollo.MutationResult<RefreshMutation>;
export type RefreshMutationOptions = Apollo.BaseMutationOptions<RefreshMutation, RefreshMutationVariables>;
export const CheckTokenDocument = gql`
    query checkToken {
  checkToken {
    _id
    username
    email
    role
    isOnFreemium
  }
}
    `;

/**
 * __useCheckTokenQuery__
 *
 * To run a query within a React component, call `useCheckTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckTokenQuery(baseOptions?: Apollo.QueryHookOptions<CheckTokenQuery, CheckTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckTokenQuery, CheckTokenQueryVariables>(CheckTokenDocument, options);
      }
export function useCheckTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckTokenQuery, CheckTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckTokenQuery, CheckTokenQueryVariables>(CheckTokenDocument, options);
        }
export type CheckTokenQueryHookResult = ReturnType<typeof useCheckTokenQuery>;
export type CheckTokenLazyQueryHookResult = ReturnType<typeof useCheckTokenLazyQuery>;
export type CheckTokenQueryResult = Apollo.QueryResult<CheckTokenQuery, CheckTokenQueryVariables>;
export const ChangeChildPasswordDocument = gql`
    mutation changeChildPassword($childId: String!, $password: String!) {
  changeChildPassword(childId: $childId, password: $password)
}
    `;
export type ChangeChildPasswordMutationFn = Apollo.MutationFunction<ChangeChildPasswordMutation, ChangeChildPasswordMutationVariables>;

/**
 * __useChangeChildPasswordMutation__
 *
 * To run a mutation, you first call `useChangeChildPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeChildPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeChildPasswordMutation, { data, loading, error }] = useChangeChildPasswordMutation({
 *   variables: {
 *      childId: // value for 'childId'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useChangeChildPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangeChildPasswordMutation, ChangeChildPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeChildPasswordMutation, ChangeChildPasswordMutationVariables>(ChangeChildPasswordDocument, options);
      }
export type ChangeChildPasswordMutationHookResult = ReturnType<typeof useChangeChildPasswordMutation>;
export type ChangeChildPasswordMutationResult = Apollo.MutationResult<ChangeChildPasswordMutation>;
export type ChangeChildPasswordMutationOptions = Apollo.BaseMutationOptions<ChangeChildPasswordMutation, ChangeChildPasswordMutationVariables>;
export const TermsAndConditionsDocument = gql`
    query termsAndConditions {
  termsAndConditions {
    ...agreementDetails
  }
}
    ${AgreementDetailsFragmentDoc}`;

/**
 * __useTermsAndConditionsQuery__
 *
 * To run a query within a React component, call `useTermsAndConditionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTermsAndConditionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTermsAndConditionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTermsAndConditionsQuery(baseOptions?: Apollo.QueryHookOptions<TermsAndConditionsQuery, TermsAndConditionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TermsAndConditionsQuery, TermsAndConditionsQueryVariables>(TermsAndConditionsDocument, options);
      }
export function useTermsAndConditionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TermsAndConditionsQuery, TermsAndConditionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TermsAndConditionsQuery, TermsAndConditionsQueryVariables>(TermsAndConditionsDocument, options);
        }
export type TermsAndConditionsQueryHookResult = ReturnType<typeof useTermsAndConditionsQuery>;
export type TermsAndConditionsLazyQueryHookResult = ReturnType<typeof useTermsAndConditionsLazyQuery>;
export type TermsAndConditionsQueryResult = Apollo.QueryResult<TermsAndConditionsQuery, TermsAndConditionsQueryVariables>;
export const PrivacyPolicyDocument = gql`
    query privacyPolicy {
  privacyPolicy {
    ...agreementDetails
  }
}
    ${AgreementDetailsFragmentDoc}`;

/**
 * __usePrivacyPolicyQuery__
 *
 * To run a query within a React component, call `usePrivacyPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrivacyPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrivacyPolicyQuery({
 *   variables: {
 *   },
 * });
 */
export function usePrivacyPolicyQuery(baseOptions?: Apollo.QueryHookOptions<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>(PrivacyPolicyDocument, options);
      }
export function usePrivacyPolicyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>(PrivacyPolicyDocument, options);
        }
export type PrivacyPolicyQueryHookResult = ReturnType<typeof usePrivacyPolicyQuery>;
export type PrivacyPolicyLazyQueryHookResult = ReturnType<typeof usePrivacyPolicyLazyQuery>;
export type PrivacyPolicyQueryResult = Apollo.QueryResult<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>;
export const GetDownloadDetailsTodayDocument = gql`
    mutation getDownloadDetailsToday($childId: String!) {
  getDownloadDetailsToday(childId: $childId) {
    ...DownloadDetails
  }
}
    ${DownloadDetailsFragmentDoc}`;
export type GetDownloadDetailsTodayMutationFn = Apollo.MutationFunction<GetDownloadDetailsTodayMutation, GetDownloadDetailsTodayMutationVariables>;

/**
 * __useGetDownloadDetailsTodayMutation__
 *
 * To run a mutation, you first call `useGetDownloadDetailsTodayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetDownloadDetailsTodayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getDownloadDetailsTodayMutation, { data, loading, error }] = useGetDownloadDetailsTodayMutation({
 *   variables: {
 *      childId: // value for 'childId'
 *   },
 * });
 */
export function useGetDownloadDetailsTodayMutation(baseOptions?: Apollo.MutationHookOptions<GetDownloadDetailsTodayMutation, GetDownloadDetailsTodayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetDownloadDetailsTodayMutation, GetDownloadDetailsTodayMutationVariables>(GetDownloadDetailsTodayDocument, options);
      }
export type GetDownloadDetailsTodayMutationHookResult = ReturnType<typeof useGetDownloadDetailsTodayMutation>;
export type GetDownloadDetailsTodayMutationResult = Apollo.MutationResult<GetDownloadDetailsTodayMutation>;
export type GetDownloadDetailsTodayMutationOptions = Apollo.BaseMutationOptions<GetDownloadDetailsTodayMutation, GetDownloadDetailsTodayMutationVariables>;
export const UpdateDownloadDetailsDocument = gql`
    mutation updateDownloadDetails($childId: String!) {
  updateDownloadDetails(childId: $childId) {
    ...DownloadDetails
  }
}
    ${DownloadDetailsFragmentDoc}`;
export type UpdateDownloadDetailsMutationFn = Apollo.MutationFunction<UpdateDownloadDetailsMutation, UpdateDownloadDetailsMutationVariables>;

/**
 * __useUpdateDownloadDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateDownloadDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDownloadDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDownloadDetailsMutation, { data, loading, error }] = useUpdateDownloadDetailsMutation({
 *   variables: {
 *      childId: // value for 'childId'
 *   },
 * });
 */
export function useUpdateDownloadDetailsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDownloadDetailsMutation, UpdateDownloadDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDownloadDetailsMutation, UpdateDownloadDetailsMutationVariables>(UpdateDownloadDetailsDocument, options);
      }
export type UpdateDownloadDetailsMutationHookResult = ReturnType<typeof useUpdateDownloadDetailsMutation>;
export type UpdateDownloadDetailsMutationResult = Apollo.MutationResult<UpdateDownloadDetailsMutation>;
export type UpdateDownloadDetailsMutationOptions = Apollo.BaseMutationOptions<UpdateDownloadDetailsMutation, UpdateDownloadDetailsMutationVariables>;
export const SaveFeedbackDocument = gql`
    mutation saveFeedback($input: SaveFeedbackInput!) {
  saveFeedback(input: $input)
}
    `;
export type SaveFeedbackMutationFn = Apollo.MutationFunction<SaveFeedbackMutation, SaveFeedbackMutationVariables>;

/**
 * __useSaveFeedbackMutation__
 *
 * To run a mutation, you first call `useSaveFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveFeedbackMutation, { data, loading, error }] = useSaveFeedbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<SaveFeedbackMutation, SaveFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveFeedbackMutation, SaveFeedbackMutationVariables>(SaveFeedbackDocument, options);
      }
export type SaveFeedbackMutationHookResult = ReturnType<typeof useSaveFeedbackMutation>;
export type SaveFeedbackMutationResult = Apollo.MutationResult<SaveFeedbackMutation>;
export type SaveFeedbackMutationOptions = Apollo.BaseMutationOptions<SaveFeedbackMutation, SaveFeedbackMutationVariables>;
export const SaveUnauthFeedbackDocument = gql`
    mutation saveUnauthFeedback($input: SaveUnauthFeedbackInput!) {
  saveUnauthFeedback(input: $input)
}
    `;
export type SaveUnauthFeedbackMutationFn = Apollo.MutationFunction<SaveUnauthFeedbackMutation, SaveUnauthFeedbackMutationVariables>;

/**
 * __useSaveUnauthFeedbackMutation__
 *
 * To run a mutation, you first call `useSaveUnauthFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUnauthFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUnauthFeedbackMutation, { data, loading, error }] = useSaveUnauthFeedbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveUnauthFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<SaveUnauthFeedbackMutation, SaveUnauthFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveUnauthFeedbackMutation, SaveUnauthFeedbackMutationVariables>(SaveUnauthFeedbackDocument, options);
      }
export type SaveUnauthFeedbackMutationHookResult = ReturnType<typeof useSaveUnauthFeedbackMutation>;
export type SaveUnauthFeedbackMutationResult = Apollo.MutationResult<SaveUnauthFeedbackMutation>;
export type SaveUnauthFeedbackMutationOptions = Apollo.BaseMutationOptions<SaveUnauthFeedbackMutation, SaveUnauthFeedbackMutationVariables>;
export const GradesDocument = gql`
    query grades {
  grades {
    _id
    slug
    title
  }
}
    `;

/**
 * __useGradesQuery__
 *
 * To run a query within a React component, call `useGradesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGradesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGradesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGradesQuery(baseOptions?: Apollo.QueryHookOptions<GradesQuery, GradesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GradesQuery, GradesQueryVariables>(GradesDocument, options);
      }
export function useGradesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GradesQuery, GradesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GradesQuery, GradesQueryVariables>(GradesDocument, options);
        }
export type GradesQueryHookResult = ReturnType<typeof useGradesQuery>;
export type GradesLazyQueryHookResult = ReturnType<typeof useGradesLazyQuery>;
export type GradesQueryResult = Apollo.QueryResult<GradesQuery, GradesQueryVariables>;
export const LanguagesDocument = gql`
    query languages {
  languages {
    _id
    slug
  }
}
    `;

/**
 * __useLanguagesQuery__
 *
 * To run a query within a React component, call `useLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLanguagesQuery(baseOptions?: Apollo.QueryHookOptions<LanguagesQuery, LanguagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LanguagesQuery, LanguagesQueryVariables>(LanguagesDocument, options);
      }
export function useLanguagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LanguagesQuery, LanguagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LanguagesQuery, LanguagesQueryVariables>(LanguagesDocument, options);
        }
export type LanguagesQueryHookResult = ReturnType<typeof useLanguagesQuery>;
export type LanguagesLazyQueryHookResult = ReturnType<typeof useLanguagesLazyQuery>;
export type LanguagesQueryResult = Apollo.QueryResult<LanguagesQuery, LanguagesQueryVariables>;
export const ScheduleCourseDocument = gql`
    mutation scheduleCourse($scheduleCourseInput: ScheduleCourseInput!) {
  scheduleCourse(scheduleCourseInput: $scheduleCourseInput)
}
    `;
export type ScheduleCourseMutationFn = Apollo.MutationFunction<ScheduleCourseMutation, ScheduleCourseMutationVariables>;

/**
 * __useScheduleCourseMutation__
 *
 * To run a mutation, you first call `useScheduleCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleCourseMutation, { data, loading, error }] = useScheduleCourseMutation({
 *   variables: {
 *      scheduleCourseInput: // value for 'scheduleCourseInput'
 *   },
 * });
 */
export function useScheduleCourseMutation(baseOptions?: Apollo.MutationHookOptions<ScheduleCourseMutation, ScheduleCourseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ScheduleCourseMutation, ScheduleCourseMutationVariables>(ScheduleCourseDocument, options);
      }
export type ScheduleCourseMutationHookResult = ReturnType<typeof useScheduleCourseMutation>;
export type ScheduleCourseMutationResult = Apollo.MutationResult<ScheduleCourseMutation>;
export type ScheduleCourseMutationOptions = Apollo.BaseMutationOptions<ScheduleCourseMutation, ScheduleCourseMutationVariables>;
export const RecurringEventDocument = gql`
    query recurringEvent($childId: String!, $courseId: String!, $unitId: String) {
  recurringEvent(childId: $childId, courseId: $courseId, unitId: $unitId) {
    recurringEventId
    scheduleTime
    interval
    childId
    courseId
    scheduleDays
  }
}
    `;

/**
 * __useRecurringEventQuery__
 *
 * To run a query within a React component, call `useRecurringEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecurringEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecurringEventQuery({
 *   variables: {
 *      childId: // value for 'childId'
 *      courseId: // value for 'courseId'
 *      unitId: // value for 'unitId'
 *   },
 * });
 */
export function useRecurringEventQuery(baseOptions: Apollo.QueryHookOptions<RecurringEventQuery, RecurringEventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecurringEventQuery, RecurringEventQueryVariables>(RecurringEventDocument, options);
      }
export function useRecurringEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecurringEventQuery, RecurringEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecurringEventQuery, RecurringEventQueryVariables>(RecurringEventDocument, options);
        }
export type RecurringEventQueryHookResult = ReturnType<typeof useRecurringEventQuery>;
export type RecurringEventLazyQueryHookResult = ReturnType<typeof useRecurringEventLazyQuery>;
export type RecurringEventQueryResult = Apollo.QueryResult<RecurringEventQuery, RecurringEventQueryVariables>;
export const EditScheduleEntireCourseDocument = gql`
    mutation editScheduleEntireCourse($recurringEventId: String!, $scheduleCourseInput: ScheduleCourseInput!) {
  editScheduleEntireCourse(
    recurringEventId: $recurringEventId
    scheduleCourseInput: $scheduleCourseInput
  )
}
    `;
export type EditScheduleEntireCourseMutationFn = Apollo.MutationFunction<EditScheduleEntireCourseMutation, EditScheduleEntireCourseMutationVariables>;

/**
 * __useEditScheduleEntireCourseMutation__
 *
 * To run a mutation, you first call `useEditScheduleEntireCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditScheduleEntireCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editScheduleEntireCourseMutation, { data, loading, error }] = useEditScheduleEntireCourseMutation({
 *   variables: {
 *      recurringEventId: // value for 'recurringEventId'
 *      scheduleCourseInput: // value for 'scheduleCourseInput'
 *   },
 * });
 */
export function useEditScheduleEntireCourseMutation(baseOptions?: Apollo.MutationHookOptions<EditScheduleEntireCourseMutation, EditScheduleEntireCourseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditScheduleEntireCourseMutation, EditScheduleEntireCourseMutationVariables>(EditScheduleEntireCourseDocument, options);
      }
export type EditScheduleEntireCourseMutationHookResult = ReturnType<typeof useEditScheduleEntireCourseMutation>;
export type EditScheduleEntireCourseMutationResult = Apollo.MutationResult<EditScheduleEntireCourseMutation>;
export type EditScheduleEntireCourseMutationOptions = Apollo.BaseMutationOptions<EditScheduleEntireCourseMutation, EditScheduleEntireCourseMutationVariables>;
export const DeleteRecurringEventDocument = gql`
    mutation deleteRecurringEvent($recurringEventId: String!) {
  deleteRecurringEvent(recurringEventId: $recurringEventId)
}
    `;
export type DeleteRecurringEventMutationFn = Apollo.MutationFunction<DeleteRecurringEventMutation, DeleteRecurringEventMutationVariables>;

/**
 * __useDeleteRecurringEventMutation__
 *
 * To run a mutation, you first call `useDeleteRecurringEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRecurringEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRecurringEventMutation, { data, loading, error }] = useDeleteRecurringEventMutation({
 *   variables: {
 *      recurringEventId: // value for 'recurringEventId'
 *   },
 * });
 */
export function useDeleteRecurringEventMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRecurringEventMutation, DeleteRecurringEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRecurringEventMutation, DeleteRecurringEventMutationVariables>(DeleteRecurringEventDocument, options);
      }
export type DeleteRecurringEventMutationHookResult = ReturnType<typeof useDeleteRecurringEventMutation>;
export type DeleteRecurringEventMutationResult = Apollo.MutationResult<DeleteRecurringEventMutation>;
export type DeleteRecurringEventMutationOptions = Apollo.BaseMutationOptions<DeleteRecurringEventMutation, DeleteRecurringEventMutationVariables>;
export const ScheduledLessonsForCalendarDocument = gql`
    query scheduledLessonsForCalendar($childId: String!, $from: String!, $to: String!) {
  scheduledLessonsForCalendar(childId: $childId, from: $from, to: $to) {
    childName
    scheduledLessons {
      plannerId
      courseId
      childId
      scheduleDate
      isCompleted
      lessonStatus
      duration
      title
      subjectName
    }
  }
}
    `;

/**
 * __useScheduledLessonsForCalendarQuery__
 *
 * To run a query within a React component, call `useScheduledLessonsForCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduledLessonsForCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduledLessonsForCalendarQuery({
 *   variables: {
 *      childId: // value for 'childId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useScheduledLessonsForCalendarQuery(baseOptions: Apollo.QueryHookOptions<ScheduledLessonsForCalendarQuery, ScheduledLessonsForCalendarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScheduledLessonsForCalendarQuery, ScheduledLessonsForCalendarQueryVariables>(ScheduledLessonsForCalendarDocument, options);
      }
export function useScheduledLessonsForCalendarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScheduledLessonsForCalendarQuery, ScheduledLessonsForCalendarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScheduledLessonsForCalendarQuery, ScheduledLessonsForCalendarQueryVariables>(ScheduledLessonsForCalendarDocument, options);
        }
export type ScheduledLessonsForCalendarQueryHookResult = ReturnType<typeof useScheduledLessonsForCalendarQuery>;
export type ScheduledLessonsForCalendarLazyQueryHookResult = ReturnType<typeof useScheduledLessonsForCalendarLazyQuery>;
export type ScheduledLessonsForCalendarQueryResult = Apollo.QueryResult<ScheduledLessonsForCalendarQuery, ScheduledLessonsForCalendarQueryVariables>;
export const CoursesForCalendarSelectDocument = gql`
    query coursesForCalendarSelect($childId: String!) {
  coursesForCalendarSelect(childId: $childId) {
    value
    label
    subject
    grade
  }
}
    `;

/**
 * __useCoursesForCalendarSelectQuery__
 *
 * To run a query within a React component, call `useCoursesForCalendarSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoursesForCalendarSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoursesForCalendarSelectQuery({
 *   variables: {
 *      childId: // value for 'childId'
 *   },
 * });
 */
export function useCoursesForCalendarSelectQuery(baseOptions: Apollo.QueryHookOptions<CoursesForCalendarSelectQuery, CoursesForCalendarSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CoursesForCalendarSelectQuery, CoursesForCalendarSelectQueryVariables>(CoursesForCalendarSelectDocument, options);
      }
export function useCoursesForCalendarSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CoursesForCalendarSelectQuery, CoursesForCalendarSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CoursesForCalendarSelectQuery, CoursesForCalendarSelectQueryVariables>(CoursesForCalendarSelectDocument, options);
        }
export type CoursesForCalendarSelectQueryHookResult = ReturnType<typeof useCoursesForCalendarSelectQuery>;
export type CoursesForCalendarSelectLazyQueryHookResult = ReturnType<typeof useCoursesForCalendarSelectLazyQuery>;
export type CoursesForCalendarSelectQueryResult = Apollo.QueryResult<CoursesForCalendarSelectQuery, CoursesForCalendarSelectQueryVariables>;
export const UnitsForCalendarSelectDocument = gql`
    query unitsForCalendarSelect($courseId: String!) {
  unitsForCalendarSelect(courseId: $courseId) {
    value
    label
  }
}
    `;

/**
 * __useUnitsForCalendarSelectQuery__
 *
 * To run a query within a React component, call `useUnitsForCalendarSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitsForCalendarSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitsForCalendarSelectQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useUnitsForCalendarSelectQuery(baseOptions: Apollo.QueryHookOptions<UnitsForCalendarSelectQuery, UnitsForCalendarSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnitsForCalendarSelectQuery, UnitsForCalendarSelectQueryVariables>(UnitsForCalendarSelectDocument, options);
      }
export function useUnitsForCalendarSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnitsForCalendarSelectQuery, UnitsForCalendarSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnitsForCalendarSelectQuery, UnitsForCalendarSelectQueryVariables>(UnitsForCalendarSelectDocument, options);
        }
export type UnitsForCalendarSelectQueryHookResult = ReturnType<typeof useUnitsForCalendarSelectQuery>;
export type UnitsForCalendarSelectLazyQueryHookResult = ReturnType<typeof useUnitsForCalendarSelectLazyQuery>;
export type UnitsForCalendarSelectQueryResult = Apollo.QueryResult<UnitsForCalendarSelectQuery, UnitsForCalendarSelectQueryVariables>;
export const LessonsForCalendarSelectDocument = gql`
    query lessonsForCalendarSelect($unitId: String!) {
  lessonsForCalendarSelect(unitId: $unitId) {
    value
    label
    isAlreadyScheduled
  }
}
    `;

/**
 * __useLessonsForCalendarSelectQuery__
 *
 * To run a query within a React component, call `useLessonsForCalendarSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useLessonsForCalendarSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLessonsForCalendarSelectQuery({
 *   variables: {
 *      unitId: // value for 'unitId'
 *   },
 * });
 */
export function useLessonsForCalendarSelectQuery(baseOptions: Apollo.QueryHookOptions<LessonsForCalendarSelectQuery, LessonsForCalendarSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LessonsForCalendarSelectQuery, LessonsForCalendarSelectQueryVariables>(LessonsForCalendarSelectDocument, options);
      }
export function useLessonsForCalendarSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LessonsForCalendarSelectQuery, LessonsForCalendarSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LessonsForCalendarSelectQuery, LessonsForCalendarSelectQueryVariables>(LessonsForCalendarSelectDocument, options);
        }
export type LessonsForCalendarSelectQueryHookResult = ReturnType<typeof useLessonsForCalendarSelectQuery>;
export type LessonsForCalendarSelectLazyQueryHookResult = ReturnType<typeof useLessonsForCalendarSelectLazyQuery>;
export type LessonsForCalendarSelectQueryResult = Apollo.QueryResult<LessonsForCalendarSelectQuery, LessonsForCalendarSelectQueryVariables>;
export const ScheduleLessonDocument = gql`
    mutation scheduleLesson($scheduleLessonInput: ScheduleLessonInput!) {
  scheduleLesson(scheduleLessonInput: $scheduleLessonInput) {
    _id
  }
}
    `;
export type ScheduleLessonMutationFn = Apollo.MutationFunction<ScheduleLessonMutation, ScheduleLessonMutationVariables>;

/**
 * __useScheduleLessonMutation__
 *
 * To run a mutation, you first call `useScheduleLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleLessonMutation, { data, loading, error }] = useScheduleLessonMutation({
 *   variables: {
 *      scheduleLessonInput: // value for 'scheduleLessonInput'
 *   },
 * });
 */
export function useScheduleLessonMutation(baseOptions?: Apollo.MutationHookOptions<ScheduleLessonMutation, ScheduleLessonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ScheduleLessonMutation, ScheduleLessonMutationVariables>(ScheduleLessonDocument, options);
      }
export type ScheduleLessonMutationHookResult = ReturnType<typeof useScheduleLessonMutation>;
export type ScheduleLessonMutationResult = Apollo.MutationResult<ScheduleLessonMutation>;
export type ScheduleLessonMutationOptions = Apollo.BaseMutationOptions<ScheduleLessonMutation, ScheduleLessonMutationVariables>;
export const UpdateScheduleLessonDocument = gql`
    mutation updateScheduleLesson($updateScheduleLessonInput: UpdateScheduledLessonInput!) {
  updateScheduleLesson(updateScheduleLessonInput: $updateScheduleLessonInput) {
    childId
    plannerId
    selectedCourse {
      label
      value
    }
    selectedUnit {
      label
      value
    }
    selectedLesson {
      label
      value
    }
    scheduleDate
  }
}
    `;
export type UpdateScheduleLessonMutationFn = Apollo.MutationFunction<UpdateScheduleLessonMutation, UpdateScheduleLessonMutationVariables>;

/**
 * __useUpdateScheduleLessonMutation__
 *
 * To run a mutation, you first call `useUpdateScheduleLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduleLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScheduleLessonMutation, { data, loading, error }] = useUpdateScheduleLessonMutation({
 *   variables: {
 *      updateScheduleLessonInput: // value for 'updateScheduleLessonInput'
 *   },
 * });
 */
export function useUpdateScheduleLessonMutation(baseOptions?: Apollo.MutationHookOptions<UpdateScheduleLessonMutation, UpdateScheduleLessonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateScheduleLessonMutation, UpdateScheduleLessonMutationVariables>(UpdateScheduleLessonDocument, options);
      }
export type UpdateScheduleLessonMutationHookResult = ReturnType<typeof useUpdateScheduleLessonMutation>;
export type UpdateScheduleLessonMutationResult = Apollo.MutationResult<UpdateScheduleLessonMutation>;
export type UpdateScheduleLessonMutationOptions = Apollo.BaseMutationOptions<UpdateScheduleLessonMutation, UpdateScheduleLessonMutationVariables>;
export const DeleteScheduledPlannerLessonDocument = gql`
    mutation deleteScheduledPlannerLesson($scheduledLessonId: String!) {
  deleteScheduledPlannerLesson(scheduledLessonId: $scheduledLessonId)
}
    `;
export type DeleteScheduledPlannerLessonMutationFn = Apollo.MutationFunction<DeleteScheduledPlannerLessonMutation, DeleteScheduledPlannerLessonMutationVariables>;

/**
 * __useDeleteScheduledPlannerLessonMutation__
 *
 * To run a mutation, you first call `useDeleteScheduledPlannerLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScheduledPlannerLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScheduledPlannerLessonMutation, { data, loading, error }] = useDeleteScheduledPlannerLessonMutation({
 *   variables: {
 *      scheduledLessonId: // value for 'scheduledLessonId'
 *   },
 * });
 */
export function useDeleteScheduledPlannerLessonMutation(baseOptions?: Apollo.MutationHookOptions<DeleteScheduledPlannerLessonMutation, DeleteScheduledPlannerLessonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteScheduledPlannerLessonMutation, DeleteScheduledPlannerLessonMutationVariables>(DeleteScheduledPlannerLessonDocument, options);
      }
export type DeleteScheduledPlannerLessonMutationHookResult = ReturnType<typeof useDeleteScheduledPlannerLessonMutation>;
export type DeleteScheduledPlannerLessonMutationResult = Apollo.MutationResult<DeleteScheduledPlannerLessonMutation>;
export type DeleteScheduledPlannerLessonMutationOptions = Apollo.BaseMutationOptions<DeleteScheduledPlannerLessonMutation, DeleteScheduledPlannerLessonMutationVariables>;
export const ScheduledPlannerLessonForCalendarDocument = gql`
    query scheduledPlannerLessonForCalendar($childId: String!, $scheduledLessonId: String!) {
  scheduledPlannerLessonForCalendar(
    childId: $childId
    scheduledLessonId: $scheduledLessonId
  ) {
    childId
    plannerId
    selectedCourse {
      label
      value
    }
    selectedUnit {
      label
      value
    }
    selectedLesson {
      label
      value
    }
    scheduleDate
    courses {
      label
      value
    }
    units {
      label
      value
    }
    lessons {
      label
      value
    }
  }
}
    `;

/**
 * __useScheduledPlannerLessonForCalendarQuery__
 *
 * To run a query within a React component, call `useScheduledPlannerLessonForCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduledPlannerLessonForCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduledPlannerLessonForCalendarQuery({
 *   variables: {
 *      childId: // value for 'childId'
 *      scheduledLessonId: // value for 'scheduledLessonId'
 *   },
 * });
 */
export function useScheduledPlannerLessonForCalendarQuery(baseOptions: Apollo.QueryHookOptions<ScheduledPlannerLessonForCalendarQuery, ScheduledPlannerLessonForCalendarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScheduledPlannerLessonForCalendarQuery, ScheduledPlannerLessonForCalendarQueryVariables>(ScheduledPlannerLessonForCalendarDocument, options);
      }
export function useScheduledPlannerLessonForCalendarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScheduledPlannerLessonForCalendarQuery, ScheduledPlannerLessonForCalendarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScheduledPlannerLessonForCalendarQuery, ScheduledPlannerLessonForCalendarQueryVariables>(ScheduledPlannerLessonForCalendarDocument, options);
        }
export type ScheduledPlannerLessonForCalendarQueryHookResult = ReturnType<typeof useScheduledPlannerLessonForCalendarQuery>;
export type ScheduledPlannerLessonForCalendarLazyQueryHookResult = ReturnType<typeof useScheduledPlannerLessonForCalendarLazyQuery>;
export type ScheduledPlannerLessonForCalendarQueryResult = Apollo.QueryResult<ScheduledPlannerLessonForCalendarQuery, ScheduledPlannerLessonForCalendarQueryVariables>;
export const ReferralSourceOptionsDocument = gql`
    query referralSourceOptions {
  referralSourceOptions {
    value
    label
  }
}
    `;

/**
 * __useReferralSourceOptionsQuery__
 *
 * To run a query within a React component, call `useReferralSourceOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralSourceOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralSourceOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReferralSourceOptionsQuery(baseOptions?: Apollo.QueryHookOptions<ReferralSourceOptionsQuery, ReferralSourceOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReferralSourceOptionsQuery, ReferralSourceOptionsQueryVariables>(ReferralSourceOptionsDocument, options);
      }
export function useReferralSourceOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReferralSourceOptionsQuery, ReferralSourceOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReferralSourceOptionsQuery, ReferralSourceOptionsQueryVariables>(ReferralSourceOptionsDocument, options);
        }
export type ReferralSourceOptionsQueryHookResult = ReturnType<typeof useReferralSourceOptionsQuery>;
export type ReferralSourceOptionsLazyQueryHookResult = ReturnType<typeof useReferralSourceOptionsLazyQuery>;
export type ReferralSourceOptionsQueryResult = Apollo.QueryResult<ReferralSourceOptionsQuery, ReferralSourceOptionsQueryVariables>;
export const LocationReportDataDocument = gql`
    query LocationReportData($endDate: String, $startDate: String) {
  locationReportData(endDate: $endDate, startDate: $startDate) {
    location
    value
  }
}
    `;

/**
 * __useLocationReportDataQuery__
 *
 * To run a query within a React component, call `useLocationReportDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationReportDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationReportDataQuery({
 *   variables: {
 *      endDate: // value for 'endDate'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useLocationReportDataQuery(baseOptions?: Apollo.QueryHookOptions<LocationReportDataQuery, LocationReportDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationReportDataQuery, LocationReportDataQueryVariables>(LocationReportDataDocument, options);
      }
export function useLocationReportDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationReportDataQuery, LocationReportDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationReportDataQuery, LocationReportDataQueryVariables>(LocationReportDataDocument, options);
        }
export type LocationReportDataQueryHookResult = ReturnType<typeof useLocationReportDataQuery>;
export type LocationReportDataLazyQueryHookResult = ReturnType<typeof useLocationReportDataLazyQuery>;
export type LocationReportDataQueryResult = Apollo.QueryResult<LocationReportDataQuery, LocationReportDataQueryVariables>;
export const MetricReportDataDocument = gql`
    query metricReportData($endDate: String, $startDate: String) {
  metricReportData(endDate: $endDate, startDate: $startDate) {
    newlyRegistered
    verifiedAccounts
    activeAccounts
    deadAccounts
    newActive
    newCancel
    newTrial
    newPastDue
    renewalTrial
    renewalActive
    renewalCancel
    renewalPastDue
  }
}
    `;

/**
 * __useMetricReportDataQuery__
 *
 * To run a query within a React component, call `useMetricReportDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetricReportDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetricReportDataQuery({
 *   variables: {
 *      endDate: // value for 'endDate'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useMetricReportDataQuery(baseOptions?: Apollo.QueryHookOptions<MetricReportDataQuery, MetricReportDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MetricReportDataQuery, MetricReportDataQueryVariables>(MetricReportDataDocument, options);
      }
export function useMetricReportDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MetricReportDataQuery, MetricReportDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MetricReportDataQuery, MetricReportDataQueryVariables>(MetricReportDataDocument, options);
        }
export type MetricReportDataQueryHookResult = ReturnType<typeof useMetricReportDataQuery>;
export type MetricReportDataLazyQueryHookResult = ReturnType<typeof useMetricReportDataLazyQuery>;
export type MetricReportDataQueryResult = Apollo.QueryResult<MetricReportDataQuery, MetricReportDataQueryVariables>;
export const UserCountriesReportDocument = gql`
    query userCountriesReport {
  userCountriesReport {
    country
  }
}
    `;

/**
 * __useUserCountriesReportQuery__
 *
 * To run a query within a React component, call `useUserCountriesReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCountriesReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCountriesReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserCountriesReportQuery(baseOptions?: Apollo.QueryHookOptions<UserCountriesReportQuery, UserCountriesReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserCountriesReportQuery, UserCountriesReportQueryVariables>(UserCountriesReportDocument, options);
      }
export function useUserCountriesReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserCountriesReportQuery, UserCountriesReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserCountriesReportQuery, UserCountriesReportQueryVariables>(UserCountriesReportDocument, options);
        }
export type UserCountriesReportQueryHookResult = ReturnType<typeof useUserCountriesReportQuery>;
export type UserCountriesReportLazyQueryHookResult = ReturnType<typeof useUserCountriesReportLazyQuery>;
export type UserCountriesReportQueryResult = Apollo.QueryResult<UserCountriesReportQuery, UserCountriesReportQueryVariables>;
export const CountryReportDataDocument = gql`
    query countryReportData($country: String!, $location: String!) {
  countryReportData(country: $country, location: $location) {
    location
    value
  }
}
    `;

/**
 * __useCountryReportDataQuery__
 *
 * To run a query within a React component, call `useCountryReportDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountryReportDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountryReportDataQuery({
 *   variables: {
 *      country: // value for 'country'
 *      location: // value for 'location'
 *   },
 * });
 */
export function useCountryReportDataQuery(baseOptions: Apollo.QueryHookOptions<CountryReportDataQuery, CountryReportDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountryReportDataQuery, CountryReportDataQueryVariables>(CountryReportDataDocument, options);
      }
export function useCountryReportDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountryReportDataQuery, CountryReportDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountryReportDataQuery, CountryReportDataQueryVariables>(CountryReportDataDocument, options);
        }
export type CountryReportDataQueryHookResult = ReturnType<typeof useCountryReportDataQuery>;
export type CountryReportDataLazyQueryHookResult = ReturnType<typeof useCountryReportDataLazyQuery>;
export type CountryReportDataQueryResult = Apollo.QueryResult<CountryReportDataQuery, CountryReportDataQueryVariables>;
export const LocationReportsByDateDocument = gql`
    query LocationReportsByDate($country: String, $endDate: String, $startDate: String) {
  locationReportsByDate(
    country: $country
    endDate: $endDate
    startDate: $startDate
  ) {
    date
    value
  }
}
    `;

/**
 * __useLocationReportsByDateQuery__
 *
 * To run a query within a React component, call `useLocationReportsByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationReportsByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationReportsByDateQuery({
 *   variables: {
 *      country: // value for 'country'
 *      endDate: // value for 'endDate'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useLocationReportsByDateQuery(baseOptions?: Apollo.QueryHookOptions<LocationReportsByDateQuery, LocationReportsByDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationReportsByDateQuery, LocationReportsByDateQueryVariables>(LocationReportsByDateDocument, options);
      }
export function useLocationReportsByDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationReportsByDateQuery, LocationReportsByDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationReportsByDateQuery, LocationReportsByDateQueryVariables>(LocationReportsByDateDocument, options);
        }
export type LocationReportsByDateQueryHookResult = ReturnType<typeof useLocationReportsByDateQuery>;
export type LocationReportsByDateLazyQueryHookResult = ReturnType<typeof useLocationReportsByDateLazyQuery>;
export type LocationReportsByDateQueryResult = Apollo.QueryResult<LocationReportsByDateQuery, LocationReportsByDateQueryVariables>;
export const SubscriptionsByDateDocument = gql`
    query SubscriptionsByDate($endDate: String, $startDate: String, $status: String) {
  subscriptionsByDate(endDate: $endDate, startDate: $startDate, status: $status) {
    date
    status
    value
  }
}
    `;

/**
 * __useSubscriptionsByDateQuery__
 *
 * To run a query within a React component, call `useSubscriptionsByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionsByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionsByDateQuery({
 *   variables: {
 *      endDate: // value for 'endDate'
 *      startDate: // value for 'startDate'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSubscriptionsByDateQuery(baseOptions?: Apollo.QueryHookOptions<SubscriptionsByDateQuery, SubscriptionsByDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionsByDateQuery, SubscriptionsByDateQueryVariables>(SubscriptionsByDateDocument, options);
      }
export function useSubscriptionsByDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionsByDateQuery, SubscriptionsByDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionsByDateQuery, SubscriptionsByDateQueryVariables>(SubscriptionsByDateDocument, options);
        }
export type SubscriptionsByDateQueryHookResult = ReturnType<typeof useSubscriptionsByDateQuery>;
export type SubscriptionsByDateLazyQueryHookResult = ReturnType<typeof useSubscriptionsByDateLazyQuery>;
export type SubscriptionsByDateQueryResult = Apollo.QueryResult<SubscriptionsByDateQuery, SubscriptionsByDateQueryVariables>;
export const ParentsMetricStatsDocument = gql`
    query ParentsMetricStats($endDate: String, $startDate: String) {
  parentsMetricStats(endDate: $endDate, startDate: $startDate) {
    newlyRegistered
    verifiedAccounts
    activeAccounts
    deadAccounts
    newActive
    newCancel
    newTrial
    newPastDue
  }
}
    `;

/**
 * __useParentsMetricStatsQuery__
 *
 * To run a query within a React component, call `useParentsMetricStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useParentsMetricStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParentsMetricStatsQuery({
 *   variables: {
 *      endDate: // value for 'endDate'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useParentsMetricStatsQuery(baseOptions?: Apollo.QueryHookOptions<ParentsMetricStatsQuery, ParentsMetricStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ParentsMetricStatsQuery, ParentsMetricStatsQueryVariables>(ParentsMetricStatsDocument, options);
      }
export function useParentsMetricStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ParentsMetricStatsQuery, ParentsMetricStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ParentsMetricStatsQuery, ParentsMetricStatsQueryVariables>(ParentsMetricStatsDocument, options);
        }
export type ParentsMetricStatsQueryHookResult = ReturnType<typeof useParentsMetricStatsQuery>;
export type ParentsMetricStatsLazyQueryHookResult = ReturnType<typeof useParentsMetricStatsLazyQuery>;
export type ParentsMetricStatsQueryResult = Apollo.QueryResult<ParentsMetricStatsQuery, ParentsMetricStatsQueryVariables>;
export const ChildMetricStatsDocument = gql`
    query ChildMetricStats($endDate: String, $startDate: String) {
  childMetricStats(endDate: $endDate, startDate: $startDate) {
    activeSubscriptions
    cancelledSubscriptions
    trialSubscriptions
    pastDueSubscriptions
  }
}
    `;

/**
 * __useChildMetricStatsQuery__
 *
 * To run a query within a React component, call `useChildMetricStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChildMetricStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChildMetricStatsQuery({
 *   variables: {
 *      endDate: // value for 'endDate'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useChildMetricStatsQuery(baseOptions?: Apollo.QueryHookOptions<ChildMetricStatsQuery, ChildMetricStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChildMetricStatsQuery, ChildMetricStatsQueryVariables>(ChildMetricStatsDocument, options);
      }
export function useChildMetricStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChildMetricStatsQuery, ChildMetricStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChildMetricStatsQuery, ChildMetricStatsQueryVariables>(ChildMetricStatsDocument, options);
        }
export type ChildMetricStatsQueryHookResult = ReturnType<typeof useChildMetricStatsQuery>;
export type ChildMetricStatsLazyQueryHookResult = ReturnType<typeof useChildMetricStatsLazyQuery>;
export type ChildMetricStatsQueryResult = Apollo.QueryResult<ChildMetricStatsQuery, ChildMetricStatsQueryVariables>;
export const GetPaymentsByDateDocument = gql`
    query GetPaymentsByDate($paying: Boolean!, $endDate: String, $startDate: String) {
  getPaymentsByDate(paying: $paying, endDate: $endDate, startDate: $startDate) {
    date
    value
  }
}
    `;

/**
 * __useGetPaymentsByDateQuery__
 *
 * To run a query within a React component, call `useGetPaymentsByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentsByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentsByDateQuery({
 *   variables: {
 *      paying: // value for 'paying'
 *      endDate: // value for 'endDate'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useGetPaymentsByDateQuery(baseOptions: Apollo.QueryHookOptions<GetPaymentsByDateQuery, GetPaymentsByDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentsByDateQuery, GetPaymentsByDateQueryVariables>(GetPaymentsByDateDocument, options);
      }
export function useGetPaymentsByDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentsByDateQuery, GetPaymentsByDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentsByDateQuery, GetPaymentsByDateQueryVariables>(GetPaymentsByDateDocument, options);
        }
export type GetPaymentsByDateQueryHookResult = ReturnType<typeof useGetPaymentsByDateQuery>;
export type GetPaymentsByDateLazyQueryHookResult = ReturnType<typeof useGetPaymentsByDateLazyQuery>;
export type GetPaymentsByDateQueryResult = Apollo.QueryResult<GetPaymentsByDateQuery, GetPaymentsByDateQueryVariables>;
export const ChildSubscriptionTypesCountDocument = gql`
    query ChildSubscriptionTypesCount($endDate: String, $startDate: String) {
  childSubscriptionTypesCount(endDate: $endDate, startDate: $startDate) {
    familyPlan
    individualMonth
    individualYear
  }
}
    `;

/**
 * __useChildSubscriptionTypesCountQuery__
 *
 * To run a query within a React component, call `useChildSubscriptionTypesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useChildSubscriptionTypesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChildSubscriptionTypesCountQuery({
 *   variables: {
 *      endDate: // value for 'endDate'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useChildSubscriptionTypesCountQuery(baseOptions?: Apollo.QueryHookOptions<ChildSubscriptionTypesCountQuery, ChildSubscriptionTypesCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChildSubscriptionTypesCountQuery, ChildSubscriptionTypesCountQueryVariables>(ChildSubscriptionTypesCountDocument, options);
      }
export function useChildSubscriptionTypesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChildSubscriptionTypesCountQuery, ChildSubscriptionTypesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChildSubscriptionTypesCountQuery, ChildSubscriptionTypesCountQueryVariables>(ChildSubscriptionTypesCountDocument, options);
        }
export type ChildSubscriptionTypesCountQueryHookResult = ReturnType<typeof useChildSubscriptionTypesCountQuery>;
export type ChildSubscriptionTypesCountLazyQueryHookResult = ReturnType<typeof useChildSubscriptionTypesCountLazyQuery>;
export type ChildSubscriptionTypesCountQueryResult = Apollo.QueryResult<ChildSubscriptionTypesCountQuery, ChildSubscriptionTypesCountQueryVariables>;
export const RegistrationsByDateDocument = gql`
    query RegistrationsByDate($endDate: String, $startDate: String) {
  registrationsByDate(endDate: $endDate, startDate: $startDate) {
    date
    status
    value
  }
}
    `;

/**
 * __useRegistrationsByDateQuery__
 *
 * To run a query within a React component, call `useRegistrationsByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistrationsByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistrationsByDateQuery({
 *   variables: {
 *      endDate: // value for 'endDate'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useRegistrationsByDateQuery(baseOptions?: Apollo.QueryHookOptions<RegistrationsByDateQuery, RegistrationsByDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RegistrationsByDateQuery, RegistrationsByDateQueryVariables>(RegistrationsByDateDocument, options);
      }
export function useRegistrationsByDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RegistrationsByDateQuery, RegistrationsByDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RegistrationsByDateQuery, RegistrationsByDateQueryVariables>(RegistrationsByDateDocument, options);
        }
export type RegistrationsByDateQueryHookResult = ReturnType<typeof useRegistrationsByDateQuery>;
export type RegistrationsByDateLazyQueryHookResult = ReturnType<typeof useRegistrationsByDateLazyQuery>;
export type RegistrationsByDateQueryResult = Apollo.QueryResult<RegistrationsByDateQuery, RegistrationsByDateQueryVariables>;
export const GetCsvForSubscriptionStatusDocument = gql`
    query GetCsvForSubscriptionStatus($status: String!, $startDate: String, $endDate: String) {
  getCsvForSubscriptionStatus(
    status: $status
    startDate: $startDate
    endDate: $endDate
  ) {
    firstName
    lastName
    email
    phone
    interval
    isFamilyPlan
    status
    subscription_start_date
    current_period_start_date
    current_period_end_date
    cancellation_reason
    _id
  }
}
    `;

/**
 * __useGetCsvForSubscriptionStatusQuery__
 *
 * To run a query within a React component, call `useGetCsvForSubscriptionStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCsvForSubscriptionStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCsvForSubscriptionStatusQuery({
 *   variables: {
 *      status: // value for 'status'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetCsvForSubscriptionStatusQuery(baseOptions: Apollo.QueryHookOptions<GetCsvForSubscriptionStatusQuery, GetCsvForSubscriptionStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCsvForSubscriptionStatusQuery, GetCsvForSubscriptionStatusQueryVariables>(GetCsvForSubscriptionStatusDocument, options);
      }
export function useGetCsvForSubscriptionStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCsvForSubscriptionStatusQuery, GetCsvForSubscriptionStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCsvForSubscriptionStatusQuery, GetCsvForSubscriptionStatusQueryVariables>(GetCsvForSubscriptionStatusDocument, options);
        }
export type GetCsvForSubscriptionStatusQueryHookResult = ReturnType<typeof useGetCsvForSubscriptionStatusQuery>;
export type GetCsvForSubscriptionStatusLazyQueryHookResult = ReturnType<typeof useGetCsvForSubscriptionStatusLazyQuery>;
export type GetCsvForSubscriptionStatusQueryResult = Apollo.QueryResult<GetCsvForSubscriptionStatusQuery, GetCsvForSubscriptionStatusQueryVariables>;
export const GetCsvForVerifiedEducatorsDocument = gql`
    query getCsvForVerifiedEducators($status: String!, $startDate: String, $endDate: String) {
  getCsvForVerifiedEducators(
    status: $status
    startDate: $startDate
    endDate: $endDate
  ) {
    firstName
    lastName
    email
    phone
    _id
  }
}
    `;

/**
 * __useGetCsvForVerifiedEducatorsQuery__
 *
 * To run a query within a React component, call `useGetCsvForVerifiedEducatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCsvForVerifiedEducatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCsvForVerifiedEducatorsQuery({
 *   variables: {
 *      status: // value for 'status'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetCsvForVerifiedEducatorsQuery(baseOptions: Apollo.QueryHookOptions<GetCsvForVerifiedEducatorsQuery, GetCsvForVerifiedEducatorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCsvForVerifiedEducatorsQuery, GetCsvForVerifiedEducatorsQueryVariables>(GetCsvForVerifiedEducatorsDocument, options);
      }
export function useGetCsvForVerifiedEducatorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCsvForVerifiedEducatorsQuery, GetCsvForVerifiedEducatorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCsvForVerifiedEducatorsQuery, GetCsvForVerifiedEducatorsQueryVariables>(GetCsvForVerifiedEducatorsDocument, options);
        }
export type GetCsvForVerifiedEducatorsQueryHookResult = ReturnType<typeof useGetCsvForVerifiedEducatorsQuery>;
export type GetCsvForVerifiedEducatorsLazyQueryHookResult = ReturnType<typeof useGetCsvForVerifiedEducatorsLazyQuery>;
export type GetCsvForVerifiedEducatorsQueryResult = Apollo.QueryResult<GetCsvForVerifiedEducatorsQuery, GetCsvForVerifiedEducatorsQueryVariables>;
export const GetStudentsByGradeCountryDocument = gql`
    query getStudentsByGradeCountry($grade: String!, $country: String!) {
  getStudentsByGradeCountry(grade: $grade, country: $country) {
    parentFirstName
    parentLastName
    email
    phone
    status
    subscriptionStartDate
    country
    grade
    studentFirstName
    studentLastName
    _id
  }
}
    `;

/**
 * __useGetStudentsByGradeCountryQuery__
 *
 * To run a query within a React component, call `useGetStudentsByGradeCountryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentsByGradeCountryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentsByGradeCountryQuery({
 *   variables: {
 *      grade: // value for 'grade'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useGetStudentsByGradeCountryQuery(baseOptions: Apollo.QueryHookOptions<GetStudentsByGradeCountryQuery, GetStudentsByGradeCountryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentsByGradeCountryQuery, GetStudentsByGradeCountryQueryVariables>(GetStudentsByGradeCountryDocument, options);
      }
export function useGetStudentsByGradeCountryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentsByGradeCountryQuery, GetStudentsByGradeCountryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentsByGradeCountryQuery, GetStudentsByGradeCountryQueryVariables>(GetStudentsByGradeCountryDocument, options);
        }
export type GetStudentsByGradeCountryQueryHookResult = ReturnType<typeof useGetStudentsByGradeCountryQuery>;
export type GetStudentsByGradeCountryLazyQueryHookResult = ReturnType<typeof useGetStudentsByGradeCountryLazyQuery>;
export type GetStudentsByGradeCountryQueryResult = Apollo.QueryResult<GetStudentsByGradeCountryQuery, GetStudentsByGradeCountryQueryVariables>;
export const CourseIconsDocument = gql`
    query courseIcons {
  courseIcons
}
    `;

/**
 * __useCourseIconsQuery__
 *
 * To run a query within a React component, call `useCourseIconsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseIconsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseIconsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCourseIconsQuery(baseOptions?: Apollo.QueryHookOptions<CourseIconsQuery, CourseIconsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CourseIconsQuery, CourseIconsQueryVariables>(CourseIconsDocument, options);
      }
export function useCourseIconsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CourseIconsQuery, CourseIconsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CourseIconsQuery, CourseIconsQueryVariables>(CourseIconsDocument, options);
        }
export type CourseIconsQueryHookResult = ReturnType<typeof useCourseIconsQuery>;
export type CourseIconsLazyQueryHookResult = ReturnType<typeof useCourseIconsLazyQuery>;
export type CourseIconsQueryResult = Apollo.QueryResult<CourseIconsQuery, CourseIconsQueryVariables>;
export const CoursePlanetsDocument = gql`
    query coursePlanets {
  coursePlanets
}
    `;

/**
 * __useCoursePlanetsQuery__
 *
 * To run a query within a React component, call `useCoursePlanetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoursePlanetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoursePlanetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCoursePlanetsQuery(baseOptions?: Apollo.QueryHookOptions<CoursePlanetsQuery, CoursePlanetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CoursePlanetsQuery, CoursePlanetsQueryVariables>(CoursePlanetsDocument, options);
      }
export function useCoursePlanetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CoursePlanetsQuery, CoursePlanetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CoursePlanetsQuery, CoursePlanetsQueryVariables>(CoursePlanetsDocument, options);
        }
export type CoursePlanetsQueryHookResult = ReturnType<typeof useCoursePlanetsQuery>;
export type CoursePlanetsLazyQueryHookResult = ReturnType<typeof useCoursePlanetsLazyQuery>;
export type CoursePlanetsQueryResult = Apollo.QueryResult<CoursePlanetsQuery, CoursePlanetsQueryVariables>;
export const SubjectsDocument = gql`
    query subjects {
  subjects {
    _id
    slug
    title
  }
}
    `;

/**
 * __useSubjectsQuery__
 *
 * To run a query within a React component, call `useSubjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubjectsQuery(baseOptions?: Apollo.QueryHookOptions<SubjectsQuery, SubjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubjectsQuery, SubjectsQueryVariables>(SubjectsDocument, options);
      }
export function useSubjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubjectsQuery, SubjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubjectsQuery, SubjectsQueryVariables>(SubjectsDocument, options);
        }
export type SubjectsQueryHookResult = ReturnType<typeof useSubjectsQuery>;
export type SubjectsLazyQueryHookResult = ReturnType<typeof useSubjectsLazyQuery>;
export type SubjectsQueryResult = Apollo.QueryResult<SubjectsQuery, SubjectsQueryVariables>;
export const MarketplaceCoursesDocument = gql`
    query marketplaceCourses($input: GetForMarketplaceInput!) {
  marketplaceCourses(input: $input) {
    courses {
      _id
      name
      image
      price
      grade {
        _id
        slug
      }
      subject {
        _id
        slug
      }
      isBundleCourse
      MSCategory
    }
    pagination {
      pages
      page
      total
      limit
    }
  }
}
    `;

/**
 * __useMarketplaceCoursesQuery__
 *
 * To run a query within a React component, call `useMarketplaceCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketplaceCoursesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarketplaceCoursesQuery(baseOptions: Apollo.QueryHookOptions<MarketplaceCoursesQuery, MarketplaceCoursesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketplaceCoursesQuery, MarketplaceCoursesQueryVariables>(MarketplaceCoursesDocument, options);
      }
export function useMarketplaceCoursesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketplaceCoursesQuery, MarketplaceCoursesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketplaceCoursesQuery, MarketplaceCoursesQueryVariables>(MarketplaceCoursesDocument, options);
        }
export type MarketplaceCoursesQueryHookResult = ReturnType<typeof useMarketplaceCoursesQuery>;
export type MarketplaceCoursesLazyQueryHookResult = ReturnType<typeof useMarketplaceCoursesLazyQuery>;
export type MarketplaceCoursesQueryResult = Apollo.QueryResult<MarketplaceCoursesQuery, MarketplaceCoursesQueryVariables>;
export const OpenMarketplaceCoursesDocument = gql`
    query openMarketplaceCourses($input: GetForOpenMarketplaceInput!) {
  openMarketplaceCourses(input: $input) {
    courses {
      _id
      name
      image
      price
      grade {
        _id
        slug
      }
      subject {
        _id
        slug
      }
      isBundleCourse
    }
    pagination {
      pages
      page
      total
      limit
    }
  }
}
    `;

/**
 * __useOpenMarketplaceCoursesQuery__
 *
 * To run a query within a React component, call `useOpenMarketplaceCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpenMarketplaceCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpenMarketplaceCoursesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOpenMarketplaceCoursesQuery(baseOptions: Apollo.QueryHookOptions<OpenMarketplaceCoursesQuery, OpenMarketplaceCoursesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OpenMarketplaceCoursesQuery, OpenMarketplaceCoursesQueryVariables>(OpenMarketplaceCoursesDocument, options);
      }
export function useOpenMarketplaceCoursesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OpenMarketplaceCoursesQuery, OpenMarketplaceCoursesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OpenMarketplaceCoursesQuery, OpenMarketplaceCoursesQueryVariables>(OpenMarketplaceCoursesDocument, options);
        }
export type OpenMarketplaceCoursesQueryHookResult = ReturnType<typeof useOpenMarketplaceCoursesQuery>;
export type OpenMarketplaceCoursesLazyQueryHookResult = ReturnType<typeof useOpenMarketplaceCoursesLazyQuery>;
export type OpenMarketplaceCoursesQueryResult = Apollo.QueryResult<OpenMarketplaceCoursesQuery, OpenMarketplaceCoursesQueryVariables>;
export const AssignCoursesDocument = gql`
    mutation assignCourses($childCourseIds: [AssignCourseInput!]!) {
  assignCourses(childCourseIds: $childCourseIds) {
    success
    paymentUrl
    sessionId
  }
}
    `;
export type AssignCoursesMutationFn = Apollo.MutationFunction<AssignCoursesMutation, AssignCoursesMutationVariables>;

/**
 * __useAssignCoursesMutation__
 *
 * To run a mutation, you first call `useAssignCoursesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignCoursesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignCoursesMutation, { data, loading, error }] = useAssignCoursesMutation({
 *   variables: {
 *      childCourseIds: // value for 'childCourseIds'
 *   },
 * });
 */
export function useAssignCoursesMutation(baseOptions?: Apollo.MutationHookOptions<AssignCoursesMutation, AssignCoursesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignCoursesMutation, AssignCoursesMutationVariables>(AssignCoursesDocument, options);
      }
export type AssignCoursesMutationHookResult = ReturnType<typeof useAssignCoursesMutation>;
export type AssignCoursesMutationResult = Apollo.MutationResult<AssignCoursesMutation>;
export type AssignCoursesMutationOptions = Apollo.BaseMutationOptions<AssignCoursesMutation, AssignCoursesMutationVariables>;
export const CheckCoursesPurchaseDocument = gql`
    mutation checkCoursesPurchase($sessionId: String!) {
  checkCoursesPurchase(sessionId: $sessionId) {
    success
    sessionStatus
    currentSessionUrl
  }
}
    `;
export type CheckCoursesPurchaseMutationFn = Apollo.MutationFunction<CheckCoursesPurchaseMutation, CheckCoursesPurchaseMutationVariables>;

/**
 * __useCheckCoursesPurchaseMutation__
 *
 * To run a mutation, you first call `useCheckCoursesPurchaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckCoursesPurchaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkCoursesPurchaseMutation, { data, loading, error }] = useCheckCoursesPurchaseMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useCheckCoursesPurchaseMutation(baseOptions?: Apollo.MutationHookOptions<CheckCoursesPurchaseMutation, CheckCoursesPurchaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckCoursesPurchaseMutation, CheckCoursesPurchaseMutationVariables>(CheckCoursesPurchaseDocument, options);
      }
export type CheckCoursesPurchaseMutationHookResult = ReturnType<typeof useCheckCoursesPurchaseMutation>;
export type CheckCoursesPurchaseMutationResult = Apollo.MutationResult<CheckCoursesPurchaseMutation>;
export type CheckCoursesPurchaseMutationOptions = Apollo.BaseMutationOptions<CheckCoursesPurchaseMutation, CheckCoursesPurchaseMutationVariables>;
export const CourseDetailsForMarketplaceDocument = gql`
    query courseDetailsForMarketplace($id: String!) {
  courseDetailsForMarketplace(id: $id) {
    _id
    name
    image
    courseInfoVideoLink
    units {
      _id
      name
      lessons {
        _id
        name
      }
    }
    description
  }
}
    `;

/**
 * __useCourseDetailsForMarketplaceQuery__
 *
 * To run a query within a React component, call `useCourseDetailsForMarketplaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseDetailsForMarketplaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseDetailsForMarketplaceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCourseDetailsForMarketplaceQuery(baseOptions: Apollo.QueryHookOptions<CourseDetailsForMarketplaceQuery, CourseDetailsForMarketplaceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CourseDetailsForMarketplaceQuery, CourseDetailsForMarketplaceQueryVariables>(CourseDetailsForMarketplaceDocument, options);
      }
export function useCourseDetailsForMarketplaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CourseDetailsForMarketplaceQuery, CourseDetailsForMarketplaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CourseDetailsForMarketplaceQuery, CourseDetailsForMarketplaceQueryVariables>(CourseDetailsForMarketplaceDocument, options);
        }
export type CourseDetailsForMarketplaceQueryHookResult = ReturnType<typeof useCourseDetailsForMarketplaceQuery>;
export type CourseDetailsForMarketplaceLazyQueryHookResult = ReturnType<typeof useCourseDetailsForMarketplaceLazyQuery>;
export type CourseDetailsForMarketplaceQueryResult = Apollo.QueryResult<CourseDetailsForMarketplaceQuery, CourseDetailsForMarketplaceQueryVariables>;
export const SubscriptionPlansDocument = gql`
    query subscriptionPlans {
  subscriptionPlans {
    _id
    stripePriceId
    interval
    price
    prePriceText
    postPriceText
    isFamilyPlan
  }
}
    `;

/**
 * __useSubscriptionPlansQuery__
 *
 * To run a query within a React component, call `useSubscriptionPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubscriptionPlansQuery(baseOptions?: Apollo.QueryHookOptions<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>(SubscriptionPlansDocument, options);
      }
export function useSubscriptionPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>(SubscriptionPlansDocument, options);
        }
export type SubscriptionPlansQueryHookResult = ReturnType<typeof useSubscriptionPlansQuery>;
export type SubscriptionPlansLazyQueryHookResult = ReturnType<typeof useSubscriptionPlansLazyQuery>;
export type SubscriptionPlansQueryResult = Apollo.QueryResult<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>;
export const SubscriptionPlansMscBasedDocument = gql`
    query subscriptionPlansMSCBased {
  subscriptionPlansMSCBased {
    _id
    stripePriceId
    interval
    price
    prePriceText
    postPriceText
    isMSCategoryBased
    MSCategory
  }
}
    `;

/**
 * __useSubscriptionPlansMscBasedQuery__
 *
 * To run a query within a React component, call `useSubscriptionPlansMscBasedQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionPlansMscBasedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionPlansMscBasedQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubscriptionPlansMscBasedQuery(baseOptions?: Apollo.QueryHookOptions<SubscriptionPlansMscBasedQuery, SubscriptionPlansMscBasedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionPlansMscBasedQuery, SubscriptionPlansMscBasedQueryVariables>(SubscriptionPlansMscBasedDocument, options);
      }
export function useSubscriptionPlansMscBasedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionPlansMscBasedQuery, SubscriptionPlansMscBasedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionPlansMscBasedQuery, SubscriptionPlansMscBasedQueryVariables>(SubscriptionPlansMscBasedDocument, options);
        }
export type SubscriptionPlansMscBasedQueryHookResult = ReturnType<typeof useSubscriptionPlansMscBasedQuery>;
export type SubscriptionPlansMscBasedLazyQueryHookResult = ReturnType<typeof useSubscriptionPlansMscBasedLazyQuery>;
export type SubscriptionPlansMscBasedQueryResult = Apollo.QueryResult<SubscriptionPlansMscBasedQuery, SubscriptionPlansMscBasedQueryVariables>;
export const SubscriptionFamilyPlansDocument = gql`
    query subscriptionFamilyPlans {
  subscriptionFamilyPlans {
    _id
    stripePriceId
    interval
    price
    prePriceText
    postPriceText
    isFamilyPlan
  }
}
    `;

/**
 * __useSubscriptionFamilyPlansQuery__
 *
 * To run a query within a React component, call `useSubscriptionFamilyPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionFamilyPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionFamilyPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubscriptionFamilyPlansQuery(baseOptions?: Apollo.QueryHookOptions<SubscriptionFamilyPlansQuery, SubscriptionFamilyPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionFamilyPlansQuery, SubscriptionFamilyPlansQueryVariables>(SubscriptionFamilyPlansDocument, options);
      }
export function useSubscriptionFamilyPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionFamilyPlansQuery, SubscriptionFamilyPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionFamilyPlansQuery, SubscriptionFamilyPlansQueryVariables>(SubscriptionFamilyPlansDocument, options);
        }
export type SubscriptionFamilyPlansQueryHookResult = ReturnType<typeof useSubscriptionFamilyPlansQuery>;
export type SubscriptionFamilyPlansLazyQueryHookResult = ReturnType<typeof useSubscriptionFamilyPlansLazyQuery>;
export type SubscriptionFamilyPlansQueryResult = Apollo.QueryResult<SubscriptionFamilyPlansQuery, SubscriptionFamilyPlansQueryVariables>;
export const AnnualPromotionDocument = gql`
    query annualPromotion {
  annualPromotion {
    _id
    stripeCouponId
    interval
    labelForFirstSubscription
    labelForUpcomingSubscription
    description
    isPersonalPromotion
  }
}
    `;

/**
 * __useAnnualPromotionQuery__
 *
 * To run a query within a React component, call `useAnnualPromotionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnnualPromotionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnnualPromotionQuery({
 *   variables: {
 *   },
 * });
 */
export function useAnnualPromotionQuery(baseOptions?: Apollo.QueryHookOptions<AnnualPromotionQuery, AnnualPromotionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnnualPromotionQuery, AnnualPromotionQueryVariables>(AnnualPromotionDocument, options);
      }
export function useAnnualPromotionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnnualPromotionQuery, AnnualPromotionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnnualPromotionQuery, AnnualPromotionQueryVariables>(AnnualPromotionDocument, options);
        }
export type AnnualPromotionQueryHookResult = ReturnType<typeof useAnnualPromotionQuery>;
export type AnnualPromotionLazyQueryHookResult = ReturnType<typeof useAnnualPromotionLazyQuery>;
export type AnnualPromotionQueryResult = Apollo.QueryResult<AnnualPromotionQuery, AnnualPromotionQueryVariables>;
export const AnnualFamilyPromotionDocument = gql`
    query annualFamilyPromotion {
  annualFamilyPromotion {
    _id
    stripeCouponId
    interval
    labelForFirstSubscription
    labelForUpcomingSubscription
    description
    isPersonalPromotion
  }
}
    `;

/**
 * __useAnnualFamilyPromotionQuery__
 *
 * To run a query within a React component, call `useAnnualFamilyPromotionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnnualFamilyPromotionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnnualFamilyPromotionQuery({
 *   variables: {
 *   },
 * });
 */
export function useAnnualFamilyPromotionQuery(baseOptions?: Apollo.QueryHookOptions<AnnualFamilyPromotionQuery, AnnualFamilyPromotionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnnualFamilyPromotionQuery, AnnualFamilyPromotionQueryVariables>(AnnualFamilyPromotionDocument, options);
      }
export function useAnnualFamilyPromotionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnnualFamilyPromotionQuery, AnnualFamilyPromotionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnnualFamilyPromotionQuery, AnnualFamilyPromotionQueryVariables>(AnnualFamilyPromotionDocument, options);
        }
export type AnnualFamilyPromotionQueryHookResult = ReturnType<typeof useAnnualFamilyPromotionQuery>;
export type AnnualFamilyPromotionLazyQueryHookResult = ReturnType<typeof useAnnualFamilyPromotionLazyQuery>;
export type AnnualFamilyPromotionQueryResult = Apollo.QueryResult<AnnualFamilyPromotionQuery, AnnualFamilyPromotionQueryVariables>;
export const CreatePaymentSessionDocument = gql`
    mutation createPaymentSession($childId: String!, $priceId: String!, $couponId: String) {
  createPaymentSession(childId: $childId, priceId: $priceId, couponId: $couponId)
}
    `;
export type CreatePaymentSessionMutationFn = Apollo.MutationFunction<CreatePaymentSessionMutation, CreatePaymentSessionMutationVariables>;

/**
 * __useCreatePaymentSessionMutation__
 *
 * To run a mutation, you first call `useCreatePaymentSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentSessionMutation, { data, loading, error }] = useCreatePaymentSessionMutation({
 *   variables: {
 *      childId: // value for 'childId'
 *      priceId: // value for 'priceId'
 *      couponId: // value for 'couponId'
 *   },
 * });
 */
export function useCreatePaymentSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentSessionMutation, CreatePaymentSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentSessionMutation, CreatePaymentSessionMutationVariables>(CreatePaymentSessionDocument, options);
      }
export type CreatePaymentSessionMutationHookResult = ReturnType<typeof useCreatePaymentSessionMutation>;
export type CreatePaymentSessionMutationResult = Apollo.MutationResult<CreatePaymentSessionMutation>;
export type CreatePaymentSessionMutationOptions = Apollo.BaseMutationOptions<CreatePaymentSessionMutation, CreatePaymentSessionMutationVariables>;
export const CancelSubscriptionDocument = gql`
    mutation cancelSubscription($childId: String!, $reason: String!) {
  cancelSubscription(childId: $childId, reason: $reason)
}
    `;
export type CancelSubscriptionMutationFn = Apollo.MutationFunction<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>;

/**
 * __useCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubscriptionMutation, { data, loading, error }] = useCancelSubscriptionMutation({
 *   variables: {
 *      childId: // value for 'childId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useCancelSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>(CancelSubscriptionDocument, options);
      }
export type CancelSubscriptionMutationHookResult = ReturnType<typeof useCancelSubscriptionMutation>;
export type CancelSubscriptionMutationResult = Apollo.MutationResult<CancelSubscriptionMutation>;
export type CancelSubscriptionMutationOptions = Apollo.BaseMutationOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>;
export const ReactivateSubscriptionDocument = gql`
    mutation reactivateSubscription($childId: String!) {
  reactivateSubscription(childId: $childId)
}
    `;
export type ReactivateSubscriptionMutationFn = Apollo.MutationFunction<ReactivateSubscriptionMutation, ReactivateSubscriptionMutationVariables>;

/**
 * __useReactivateSubscriptionMutation__
 *
 * To run a mutation, you first call `useReactivateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateSubscriptionMutation, { data, loading, error }] = useReactivateSubscriptionMutation({
 *   variables: {
 *      childId: // value for 'childId'
 *   },
 * });
 */
export function useReactivateSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<ReactivateSubscriptionMutation, ReactivateSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReactivateSubscriptionMutation, ReactivateSubscriptionMutationVariables>(ReactivateSubscriptionDocument, options);
      }
export type ReactivateSubscriptionMutationHookResult = ReturnType<typeof useReactivateSubscriptionMutation>;
export type ReactivateSubscriptionMutationResult = Apollo.MutationResult<ReactivateSubscriptionMutation>;
export type ReactivateSubscriptionMutationOptions = Apollo.BaseMutationOptions<ReactivateSubscriptionMutation, ReactivateSubscriptionMutationVariables>;
export const UpgradeToAnnualDocument = gql`
    mutation upgradeToAnnual($childId: String!) {
  upgradeToAnnual(childId: $childId)
}
    `;
export type UpgradeToAnnualMutationFn = Apollo.MutationFunction<UpgradeToAnnualMutation, UpgradeToAnnualMutationVariables>;

/**
 * __useUpgradeToAnnualMutation__
 *
 * To run a mutation, you first call `useUpgradeToAnnualMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpgradeToAnnualMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upgradeToAnnualMutation, { data, loading, error }] = useUpgradeToAnnualMutation({
 *   variables: {
 *      childId: // value for 'childId'
 *   },
 * });
 */
export function useUpgradeToAnnualMutation(baseOptions?: Apollo.MutationHookOptions<UpgradeToAnnualMutation, UpgradeToAnnualMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpgradeToAnnualMutation, UpgradeToAnnualMutationVariables>(UpgradeToAnnualDocument, options);
      }
export type UpgradeToAnnualMutationHookResult = ReturnType<typeof useUpgradeToAnnualMutation>;
export type UpgradeToAnnualMutationResult = Apollo.MutationResult<UpgradeToAnnualMutation>;
export type UpgradeToAnnualMutationOptions = Apollo.BaseMutationOptions<UpgradeToAnnualMutation, UpgradeToAnnualMutationVariables>;
export const UpgradeToAnnualMscDocument = gql`
    mutation upgradeToAnnualMSC($childId: String!) {
  upgradeToAnnualMSC(childId: $childId)
}
    `;
export type UpgradeToAnnualMscMutationFn = Apollo.MutationFunction<UpgradeToAnnualMscMutation, UpgradeToAnnualMscMutationVariables>;

/**
 * __useUpgradeToAnnualMscMutation__
 *
 * To run a mutation, you first call `useUpgradeToAnnualMscMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpgradeToAnnualMscMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upgradeToAnnualMscMutation, { data, loading, error }] = useUpgradeToAnnualMscMutation({
 *   variables: {
 *      childId: // value for 'childId'
 *   },
 * });
 */
export function useUpgradeToAnnualMscMutation(baseOptions?: Apollo.MutationHookOptions<UpgradeToAnnualMscMutation, UpgradeToAnnualMscMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpgradeToAnnualMscMutation, UpgradeToAnnualMscMutationVariables>(UpgradeToAnnualMscDocument, options);
      }
export type UpgradeToAnnualMscMutationHookResult = ReturnType<typeof useUpgradeToAnnualMscMutation>;
export type UpgradeToAnnualMscMutationResult = Apollo.MutationResult<UpgradeToAnnualMscMutation>;
export type UpgradeToAnnualMscMutationOptions = Apollo.BaseMutationOptions<UpgradeToAnnualMscMutation, UpgradeToAnnualMscMutationVariables>;
export const SwitchMscPlanDocument = gql`
    mutation switchMSCPlan($childId: String!, $priceId: String!) {
  switchMSCPlan(childId: $childId, priceId: $priceId)
}
    `;
export type SwitchMscPlanMutationFn = Apollo.MutationFunction<SwitchMscPlanMutation, SwitchMscPlanMutationVariables>;

/**
 * __useSwitchMscPlanMutation__
 *
 * To run a mutation, you first call `useSwitchMscPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchMscPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchMscPlanMutation, { data, loading, error }] = useSwitchMscPlanMutation({
 *   variables: {
 *      childId: // value for 'childId'
 *      priceId: // value for 'priceId'
 *   },
 * });
 */
export function useSwitchMscPlanMutation(baseOptions?: Apollo.MutationHookOptions<SwitchMscPlanMutation, SwitchMscPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SwitchMscPlanMutation, SwitchMscPlanMutationVariables>(SwitchMscPlanDocument, options);
      }
export type SwitchMscPlanMutationHookResult = ReturnType<typeof useSwitchMscPlanMutation>;
export type SwitchMscPlanMutationResult = Apollo.MutationResult<SwitchMscPlanMutation>;
export type SwitchMscPlanMutationOptions = Apollo.BaseMutationOptions<SwitchMscPlanMutation, SwitchMscPlanMutationVariables>;
export const SubscriptionDetailsDocument = gql`
    query subscriptionDetails($childId: String!) {
  subscriptionDetails(childId: $childId) {
    _id
    interval
    price
    nextInvoice
    reactivable
  }
}
    `;

/**
 * __useSubscriptionDetailsQuery__
 *
 * To run a query within a React component, call `useSubscriptionDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionDetailsQuery({
 *   variables: {
 *      childId: // value for 'childId'
 *   },
 * });
 */
export function useSubscriptionDetailsQuery(baseOptions: Apollo.QueryHookOptions<SubscriptionDetailsQuery, SubscriptionDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionDetailsQuery, SubscriptionDetailsQueryVariables>(SubscriptionDetailsDocument, options);
      }
export function useSubscriptionDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionDetailsQuery, SubscriptionDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionDetailsQuery, SubscriptionDetailsQueryVariables>(SubscriptionDetailsDocument, options);
        }
export type SubscriptionDetailsQueryHookResult = ReturnType<typeof useSubscriptionDetailsQuery>;
export type SubscriptionDetailsLazyQueryHookResult = ReturnType<typeof useSubscriptionDetailsLazyQuery>;
export type SubscriptionDetailsQueryResult = Apollo.QueryResult<SubscriptionDetailsQuery, SubscriptionDetailsQueryVariables>;
export const SubscriptionSwitchAndUpgradeDetailsForMscDocument = gql`
    query SubscriptionSwitchAndUpgradeDetailsForMSC($childId: String!) {
  subscriptionSwitchAndUpgradeDetailsForMSC(childId: $childId) {
    switchPlanOptions {
      ...MscPlanDetails
    }
    annualPlanOption {
      ...MscPlanDetails
    }
  }
}
    ${MscPlanDetailsFragmentDoc}`;

/**
 * __useSubscriptionSwitchAndUpgradeDetailsForMscQuery__
 *
 * To run a query within a React component, call `useSubscriptionSwitchAndUpgradeDetailsForMscQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionSwitchAndUpgradeDetailsForMscQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionSwitchAndUpgradeDetailsForMscQuery({
 *   variables: {
 *      childId: // value for 'childId'
 *   },
 * });
 */
export function useSubscriptionSwitchAndUpgradeDetailsForMscQuery(baseOptions: Apollo.QueryHookOptions<SubscriptionSwitchAndUpgradeDetailsForMscQuery, SubscriptionSwitchAndUpgradeDetailsForMscQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionSwitchAndUpgradeDetailsForMscQuery, SubscriptionSwitchAndUpgradeDetailsForMscQueryVariables>(SubscriptionSwitchAndUpgradeDetailsForMscDocument, options);
      }
export function useSubscriptionSwitchAndUpgradeDetailsForMscLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionSwitchAndUpgradeDetailsForMscQuery, SubscriptionSwitchAndUpgradeDetailsForMscQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionSwitchAndUpgradeDetailsForMscQuery, SubscriptionSwitchAndUpgradeDetailsForMscQueryVariables>(SubscriptionSwitchAndUpgradeDetailsForMscDocument, options);
        }
export type SubscriptionSwitchAndUpgradeDetailsForMscQueryHookResult = ReturnType<typeof useSubscriptionSwitchAndUpgradeDetailsForMscQuery>;
export type SubscriptionSwitchAndUpgradeDetailsForMscLazyQueryHookResult = ReturnType<typeof useSubscriptionSwitchAndUpgradeDetailsForMscLazyQuery>;
export type SubscriptionSwitchAndUpgradeDetailsForMscQueryResult = Apollo.QueryResult<SubscriptionSwitchAndUpgradeDetailsForMscQuery, SubscriptionSwitchAndUpgradeDetailsForMscQueryVariables>;
export const UserChildProfileDocument = gql`
    query userChildProfile {
  userChildProfile {
    _id
    username
    firstName
    lastName
    profilePic
    role {
      _id
      role
    }
    email
    parentEmail
    hasMetaverse
    lastMoodCheck {
      _id
      start
      end
    }
    grade {
      _id
      title
      slug
    }
    learningMode
  }
}
    `;

/**
 * __useUserChildProfileQuery__
 *
 * To run a query within a React component, call `useUserChildProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserChildProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserChildProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserChildProfileQuery(baseOptions?: Apollo.QueryHookOptions<UserChildProfileQuery, UserChildProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserChildProfileQuery, UserChildProfileQueryVariables>(UserChildProfileDocument, options);
      }
export function useUserChildProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserChildProfileQuery, UserChildProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserChildProfileQuery, UserChildProfileQueryVariables>(UserChildProfileDocument, options);
        }
export type UserChildProfileQueryHookResult = ReturnType<typeof useUserChildProfileQuery>;
export type UserChildProfileLazyQueryHookResult = ReturnType<typeof useUserChildProfileLazyQuery>;
export type UserChildProfileQueryResult = Apollo.QueryResult<UserChildProfileQuery, UserChildProfileQueryVariables>;
export const VerifyUserDetailsDocument = gql`
    mutation verifyUserDetails {
  verifyUserDetails {
    parent {
      _id
      country
      state
      city
      phoneNumber
    }
    child {
      _id
      userName
      password
      firstName
      lastName
      dateOfBirth
      grade
    }
  }
}
    `;
export type VerifyUserDetailsMutationFn = Apollo.MutationFunction<VerifyUserDetailsMutation, VerifyUserDetailsMutationVariables>;

/**
 * __useVerifyUserDetailsMutation__
 *
 * To run a mutation, you first call `useVerifyUserDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyUserDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyUserDetailsMutation, { data, loading, error }] = useVerifyUserDetailsMutation({
 *   variables: {
 *   },
 * });
 */
export function useVerifyUserDetailsMutation(baseOptions?: Apollo.MutationHookOptions<VerifyUserDetailsMutation, VerifyUserDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyUserDetailsMutation, VerifyUserDetailsMutationVariables>(VerifyUserDetailsDocument, options);
      }
export type VerifyUserDetailsMutationHookResult = ReturnType<typeof useVerifyUserDetailsMutation>;
export type VerifyUserDetailsMutationResult = Apollo.MutationResult<VerifyUserDetailsMutation>;
export type VerifyUserDetailsMutationOptions = Apollo.BaseMutationOptions<VerifyUserDetailsMutation, VerifyUserDetailsMutationVariables>;
export const UserParentProfileDocument = gql`
    query userParentProfile {
  userParentProfile {
    ...ParentProfile
  }
}
    ${ParentProfileFragmentDoc}`;

/**
 * __useUserParentProfileQuery__
 *
 * To run a query within a React component, call `useUserParentProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserParentProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserParentProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserParentProfileQuery(baseOptions?: Apollo.QueryHookOptions<UserParentProfileQuery, UserParentProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserParentProfileQuery, UserParentProfileQueryVariables>(UserParentProfileDocument, options);
      }
export function useUserParentProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserParentProfileQuery, UserParentProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserParentProfileQuery, UserParentProfileQueryVariables>(UserParentProfileDocument, options);
        }
export type UserParentProfileQueryHookResult = ReturnType<typeof useUserParentProfileQuery>;
export type UserParentProfileLazyQueryHookResult = ReturnType<typeof useUserParentProfileLazyQuery>;
export type UserParentProfileQueryResult = Apollo.QueryResult<UserParentProfileQuery, UserParentProfileQueryVariables>;
export const UserSchoolProfileDocument = gql`
    query userSchoolProfile {
  userSchoolProfile {
    ...SchoolProfile
  }
}
    ${SchoolProfileFragmentDoc}`;

/**
 * __useUserSchoolProfileQuery__
 *
 * To run a query within a React component, call `useUserSchoolProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSchoolProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSchoolProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserSchoolProfileQuery(baseOptions?: Apollo.QueryHookOptions<UserSchoolProfileQuery, UserSchoolProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserSchoolProfileQuery, UserSchoolProfileQueryVariables>(UserSchoolProfileDocument, options);
      }
export function useUserSchoolProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserSchoolProfileQuery, UserSchoolProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserSchoolProfileQuery, UserSchoolProfileQueryVariables>(UserSchoolProfileDocument, options);
        }
export type UserSchoolProfileQueryHookResult = ReturnType<typeof useUserSchoolProfileQuery>;
export type UserSchoolProfileLazyQueryHookResult = ReturnType<typeof useUserSchoolProfileLazyQuery>;
export type UserSchoolProfileQueryResult = Apollo.QueryResult<UserSchoolProfileQuery, UserSchoolProfileQueryVariables>;
export const SetLastMoodCheckDocument = gql`
    mutation setLastMoodCheck($input: SetLastMoodCheckInput!) {
  setLastMoodCheck(input: $input) {
    _id
    start {
      date
    }
    end {
      date
    }
  }
}
    `;
export type SetLastMoodCheckMutationFn = Apollo.MutationFunction<SetLastMoodCheckMutation, SetLastMoodCheckMutationVariables>;

/**
 * __useSetLastMoodCheckMutation__
 *
 * To run a mutation, you first call `useSetLastMoodCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetLastMoodCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setLastMoodCheckMutation, { data, loading, error }] = useSetLastMoodCheckMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetLastMoodCheckMutation(baseOptions?: Apollo.MutationHookOptions<SetLastMoodCheckMutation, SetLastMoodCheckMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetLastMoodCheckMutation, SetLastMoodCheckMutationVariables>(SetLastMoodCheckDocument, options);
      }
export type SetLastMoodCheckMutationHookResult = ReturnType<typeof useSetLastMoodCheckMutation>;
export type SetLastMoodCheckMutationResult = Apollo.MutationResult<SetLastMoodCheckMutation>;
export type SetLastMoodCheckMutationOptions = Apollo.BaseMutationOptions<SetLastMoodCheckMutation, SetLastMoodCheckMutationVariables>;
export const SetUserProfileDocument = gql`
    mutation setUserProfile($input: SetProfileInput!) {
  setUserProfile(setProfileInput: $input) {
    ...ParentProfile
  }
}
    ${ParentProfileFragmentDoc}`;
export type SetUserProfileMutationFn = Apollo.MutationFunction<SetUserProfileMutation, SetUserProfileMutationVariables>;

/**
 * __useSetUserProfileMutation__
 *
 * To run a mutation, you first call `useSetUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserProfileMutation, { data, loading, error }] = useSetUserProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<SetUserProfileMutation, SetUserProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserProfileMutation, SetUserProfileMutationVariables>(SetUserProfileDocument, options);
      }
export type SetUserProfileMutationHookResult = ReturnType<typeof useSetUserProfileMutation>;
export type SetUserProfileMutationResult = Apollo.MutationResult<SetUserProfileMutation>;
export type SetUserProfileMutationOptions = Apollo.BaseMutationOptions<SetUserProfileMutation, SetUserProfileMutationVariables>;
export const SetSchoolProfileDocument = gql`
    mutation setSchoolProfile($input: SetSchoolProfileInput!) {
  setSchoolProfile(setSchoolProfileInput: $input) {
    ...SchoolProfile
  }
}
    ${SchoolProfileFragmentDoc}`;
export type SetSchoolProfileMutationFn = Apollo.MutationFunction<SetSchoolProfileMutation, SetSchoolProfileMutationVariables>;

/**
 * __useSetSchoolProfileMutation__
 *
 * To run a mutation, you first call `useSetSchoolProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSchoolProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSchoolProfileMutation, { data, loading, error }] = useSetSchoolProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetSchoolProfileMutation(baseOptions?: Apollo.MutationHookOptions<SetSchoolProfileMutation, SetSchoolProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetSchoolProfileMutation, SetSchoolProfileMutationVariables>(SetSchoolProfileDocument, options);
      }
export type SetSchoolProfileMutationHookResult = ReturnType<typeof useSetSchoolProfileMutation>;
export type SetSchoolProfileMutationResult = Apollo.MutationResult<SetSchoolProfileMutation>;
export type SetSchoolProfileMutationOptions = Apollo.BaseMutationOptions<SetSchoolProfileMutation, SetSchoolProfileMutationVariables>;
export const SetVerifiedUserProfileDocument = gql`
    mutation setVerifiedUserProfile($input: VerifyParentDetailsInput!) {
  setVerifiedUserProfile(verifyParentDetailsInput: $input) {
    ...ParentProfile
  }
}
    ${ParentProfileFragmentDoc}`;
export type SetVerifiedUserProfileMutationFn = Apollo.MutationFunction<SetVerifiedUserProfileMutation, SetVerifiedUserProfileMutationVariables>;

/**
 * __useSetVerifiedUserProfileMutation__
 *
 * To run a mutation, you first call `useSetVerifiedUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetVerifiedUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setVerifiedUserProfileMutation, { data, loading, error }] = useSetVerifiedUserProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetVerifiedUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<SetVerifiedUserProfileMutation, SetVerifiedUserProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetVerifiedUserProfileMutation, SetVerifiedUserProfileMutationVariables>(SetVerifiedUserProfileDocument, options);
      }
export type SetVerifiedUserProfileMutationHookResult = ReturnType<typeof useSetVerifiedUserProfileMutation>;
export type SetVerifiedUserProfileMutationResult = Apollo.MutationResult<SetVerifiedUserProfileMutation>;
export type SetVerifiedUserProfileMutationOptions = Apollo.BaseMutationOptions<SetVerifiedUserProfileMutation, SetVerifiedUserProfileMutationVariables>;
export const IsEmailAvailableDocument = gql`
    query isEmailAvailable($email: String!) {
  isEmailAvailable(email: $email)
}
    `;

/**
 * __useIsEmailAvailableQuery__
 *
 * To run a query within a React component, call `useIsEmailAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsEmailAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsEmailAvailableQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useIsEmailAvailableQuery(baseOptions: Apollo.QueryHookOptions<IsEmailAvailableQuery, IsEmailAvailableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsEmailAvailableQuery, IsEmailAvailableQueryVariables>(IsEmailAvailableDocument, options);
      }
export function useIsEmailAvailableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsEmailAvailableQuery, IsEmailAvailableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsEmailAvailableQuery, IsEmailAvailableQueryVariables>(IsEmailAvailableDocument, options);
        }
export type IsEmailAvailableQueryHookResult = ReturnType<typeof useIsEmailAvailableQuery>;
export type IsEmailAvailableLazyQueryHookResult = ReturnType<typeof useIsEmailAvailableLazyQuery>;
export type IsEmailAvailableQueryResult = Apollo.QueryResult<IsEmailAvailableQuery, IsEmailAvailableQueryVariables>;
export const IsUsernameAvailableDocument = gql`
    query isUsernameAvailable($userName: String!) {
  isUsernameAvailable(userName: $userName)
}
    `;

/**
 * __useIsUsernameAvailableQuery__
 *
 * To run a query within a React component, call `useIsUsernameAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsUsernameAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsUsernameAvailableQuery({
 *   variables: {
 *      userName: // value for 'userName'
 *   },
 * });
 */
export function useIsUsernameAvailableQuery(baseOptions: Apollo.QueryHookOptions<IsUsernameAvailableQuery, IsUsernameAvailableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsUsernameAvailableQuery, IsUsernameAvailableQueryVariables>(IsUsernameAvailableDocument, options);
      }
export function useIsUsernameAvailableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsUsernameAvailableQuery, IsUsernameAvailableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsUsernameAvailableQuery, IsUsernameAvailableQueryVariables>(IsUsernameAvailableDocument, options);
        }
export type IsUsernameAvailableQueryHookResult = ReturnType<typeof useIsUsernameAvailableQuery>;
export type IsUsernameAvailableLazyQueryHookResult = ReturnType<typeof useIsUsernameAvailableLazyQuery>;
export type IsUsernameAvailableQueryResult = Apollo.QueryResult<IsUsernameAvailableQuery, IsUsernameAvailableQueryVariables>;
export const IsPhoneNumberAvailableDocument = gql`
    query isPhoneNumberAvailable($userId: String!, $phoneNumber: String!) {
  isPhoneNumberAvailable(userId: $userId, phoneNumber: $phoneNumber)
}
    `;

/**
 * __useIsPhoneNumberAvailableQuery__
 *
 * To run a query within a React component, call `useIsPhoneNumberAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsPhoneNumberAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsPhoneNumberAvailableQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useIsPhoneNumberAvailableQuery(baseOptions: Apollo.QueryHookOptions<IsPhoneNumberAvailableQuery, IsPhoneNumberAvailableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsPhoneNumberAvailableQuery, IsPhoneNumberAvailableQueryVariables>(IsPhoneNumberAvailableDocument, options);
      }
export function useIsPhoneNumberAvailableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsPhoneNumberAvailableQuery, IsPhoneNumberAvailableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsPhoneNumberAvailableQuery, IsPhoneNumberAvailableQueryVariables>(IsPhoneNumberAvailableDocument, options);
        }
export type IsPhoneNumberAvailableQueryHookResult = ReturnType<typeof useIsPhoneNumberAvailableQuery>;
export type IsPhoneNumberAvailableLazyQueryHookResult = ReturnType<typeof useIsPhoneNumberAvailableLazyQuery>;
export type IsPhoneNumberAvailableQueryResult = Apollo.QueryResult<IsPhoneNumberAvailableQuery, IsPhoneNumberAvailableQueryVariables>;
export const ChildrenDocument = gql`
    query children {
  children {
    _id
    fullName
    firstName
    lastName
    profilePic
    grade {
      _id
      title
      slug
    }
    isSubscribed
    subscriptionStatus
    trialEndAt
    isOnFreemium
    isDefaultChild
    learningMode
    MSCategory
  }
}
    `;

/**
 * __useChildrenQuery__
 *
 * To run a query within a React component, call `useChildrenQuery` and pass it any options that fit your needs.
 * When your component renders, `useChildrenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChildrenQuery({
 *   variables: {
 *   },
 * });
 */
export function useChildrenQuery(baseOptions?: Apollo.QueryHookOptions<ChildrenQuery, ChildrenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChildrenQuery, ChildrenQueryVariables>(ChildrenDocument, options);
      }
export function useChildrenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChildrenQuery, ChildrenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChildrenQuery, ChildrenQueryVariables>(ChildrenDocument, options);
        }
export type ChildrenQueryHookResult = ReturnType<typeof useChildrenQuery>;
export type ChildrenLazyQueryHookResult = ReturnType<typeof useChildrenLazyQuery>;
export type ChildrenQueryResult = Apollo.QueryResult<ChildrenQuery, ChildrenQueryVariables>;
export const DisableMetaverseDocument = gql`
    mutation disableMetaverse($childId: String!) {
  disableMetaverse(childId: $childId)
}
    `;
export type DisableMetaverseMutationFn = Apollo.MutationFunction<DisableMetaverseMutation, DisableMetaverseMutationVariables>;

/**
 * __useDisableMetaverseMutation__
 *
 * To run a mutation, you first call `useDisableMetaverseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableMetaverseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableMetaverseMutation, { data, loading, error }] = useDisableMetaverseMutation({
 *   variables: {
 *      childId: // value for 'childId'
 *   },
 * });
 */
export function useDisableMetaverseMutation(baseOptions?: Apollo.MutationHookOptions<DisableMetaverseMutation, DisableMetaverseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableMetaverseMutation, DisableMetaverseMutationVariables>(DisableMetaverseDocument, options);
      }
export type DisableMetaverseMutationHookResult = ReturnType<typeof useDisableMetaverseMutation>;
export type DisableMetaverseMutationResult = Apollo.MutationResult<DisableMetaverseMutation>;
export type DisableMetaverseMutationOptions = Apollo.BaseMutationOptions<DisableMetaverseMutation, DisableMetaverseMutationVariables>;
export const AddChildDocument = gql`
    mutation addChild($input: AddChildInput!) {
  addChild(addChildInput: $input)
}
    `;
export type AddChildMutationFn = Apollo.MutationFunction<AddChildMutation, AddChildMutationVariables>;

/**
 * __useAddChildMutation__
 *
 * To run a mutation, you first call `useAddChildMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddChildMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addChildMutation, { data, loading, error }] = useAddChildMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddChildMutation(baseOptions?: Apollo.MutationHookOptions<AddChildMutation, AddChildMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddChildMutation, AddChildMutationVariables>(AddChildDocument, options);
      }
export type AddChildMutationHookResult = ReturnType<typeof useAddChildMutation>;
export type AddChildMutationResult = Apollo.MutationResult<AddChildMutation>;
export type AddChildMutationOptions = Apollo.BaseMutationOptions<AddChildMutation, AddChildMutationVariables>;
export const ToggleChildAutoDocument = gql`
    mutation toggleChildAuto($childId: String!, $learningMode: String!) {
  toggleChildAuto(childId: $childId, learningMode: $learningMode) {
    _id
    userName
    firstName
    learningMode
    lastName
    dateOfBirth
    grade
    phoneNumber
    email
    image
    studentId
  }
}
    `;
export type ToggleChildAutoMutationFn = Apollo.MutationFunction<ToggleChildAutoMutation, ToggleChildAutoMutationVariables>;

/**
 * __useToggleChildAutoMutation__
 *
 * To run a mutation, you first call `useToggleChildAutoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleChildAutoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleChildAutoMutation, { data, loading, error }] = useToggleChildAutoMutation({
 *   variables: {
 *      childId: // value for 'childId'
 *      learningMode: // value for 'learningMode'
 *   },
 * });
 */
export function useToggleChildAutoMutation(baseOptions?: Apollo.MutationHookOptions<ToggleChildAutoMutation, ToggleChildAutoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleChildAutoMutation, ToggleChildAutoMutationVariables>(ToggleChildAutoDocument, options);
      }
export type ToggleChildAutoMutationHookResult = ReturnType<typeof useToggleChildAutoMutation>;
export type ToggleChildAutoMutationResult = Apollo.MutationResult<ToggleChildAutoMutation>;
export type ToggleChildAutoMutationOptions = Apollo.BaseMutationOptions<ToggleChildAutoMutation, ToggleChildAutoMutationVariables>;
export const ToggleSchoolEnrollmentDocument = gql`
    mutation toggleSchoolEnrollment {
  toggleSchoolEnrollment {
    ...SchoolProfile
  }
}
    ${SchoolProfileFragmentDoc}`;
export type ToggleSchoolEnrollmentMutationFn = Apollo.MutationFunction<ToggleSchoolEnrollmentMutation, ToggleSchoolEnrollmentMutationVariables>;

/**
 * __useToggleSchoolEnrollmentMutation__
 *
 * To run a mutation, you first call `useToggleSchoolEnrollmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleSchoolEnrollmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleSchoolEnrollmentMutation, { data, loading, error }] = useToggleSchoolEnrollmentMutation({
 *   variables: {
 *   },
 * });
 */
export function useToggleSchoolEnrollmentMutation(baseOptions?: Apollo.MutationHookOptions<ToggleSchoolEnrollmentMutation, ToggleSchoolEnrollmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleSchoolEnrollmentMutation, ToggleSchoolEnrollmentMutationVariables>(ToggleSchoolEnrollmentDocument, options);
      }
export type ToggleSchoolEnrollmentMutationHookResult = ReturnType<typeof useToggleSchoolEnrollmentMutation>;
export type ToggleSchoolEnrollmentMutationResult = Apollo.MutationResult<ToggleSchoolEnrollmentMutation>;
export type ToggleSchoolEnrollmentMutationOptions = Apollo.BaseMutationOptions<ToggleSchoolEnrollmentMutation, ToggleSchoolEnrollmentMutationVariables>;
export const ToggleShowOnTranscriptDocument = gql`
    mutation toggleShowOnTranscript {
  toggleShowOnTranscript {
    ...SchoolProfile
  }
}
    ${SchoolProfileFragmentDoc}`;
export type ToggleShowOnTranscriptMutationFn = Apollo.MutationFunction<ToggleShowOnTranscriptMutation, ToggleShowOnTranscriptMutationVariables>;

/**
 * __useToggleShowOnTranscriptMutation__
 *
 * To run a mutation, you first call `useToggleShowOnTranscriptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleShowOnTranscriptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleShowOnTranscriptMutation, { data, loading, error }] = useToggleShowOnTranscriptMutation({
 *   variables: {
 *   },
 * });
 */
export function useToggleShowOnTranscriptMutation(baseOptions?: Apollo.MutationHookOptions<ToggleShowOnTranscriptMutation, ToggleShowOnTranscriptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleShowOnTranscriptMutation, ToggleShowOnTranscriptMutationVariables>(ToggleShowOnTranscriptDocument, options);
      }
export type ToggleShowOnTranscriptMutationHookResult = ReturnType<typeof useToggleShowOnTranscriptMutation>;
export type ToggleShowOnTranscriptMutationResult = Apollo.MutationResult<ToggleShowOnTranscriptMutation>;
export type ToggleShowOnTranscriptMutationOptions = Apollo.BaseMutationOptions<ToggleShowOnTranscriptMutation, ToggleShowOnTranscriptMutationVariables>;
export const DeleteChildDocument = gql`
    mutation deleteChild($childId: String!) {
  deleteChild(childId: $childId)
}
    `;
export type DeleteChildMutationFn = Apollo.MutationFunction<DeleteChildMutation, DeleteChildMutationVariables>;

/**
 * __useDeleteChildMutation__
 *
 * To run a mutation, you first call `useDeleteChildMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChildMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChildMutation, { data, loading, error }] = useDeleteChildMutation({
 *   variables: {
 *      childId: // value for 'childId'
 *   },
 * });
 */
export function useDeleteChildMutation(baseOptions?: Apollo.MutationHookOptions<DeleteChildMutation, DeleteChildMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteChildMutation, DeleteChildMutationVariables>(DeleteChildDocument, options);
      }
export type DeleteChildMutationHookResult = ReturnType<typeof useDeleteChildMutation>;
export type DeleteChildMutationResult = Apollo.MutationResult<DeleteChildMutation>;
export type DeleteChildMutationOptions = Apollo.BaseMutationOptions<DeleteChildMutation, DeleteChildMutationVariables>;
export const EditChildDocument = gql`
    mutation editChild($input: EditChildInput!) {
  editChild(editChildInput: $input) {
    _id
    grade {
      _id
      title
    }
  }
}
    `;
export type EditChildMutationFn = Apollo.MutationFunction<EditChildMutation, EditChildMutationVariables>;

/**
 * __useEditChildMutation__
 *
 * To run a mutation, you first call `useEditChildMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditChildMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editChildMutation, { data, loading, error }] = useEditChildMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditChildMutation(baseOptions?: Apollo.MutationHookOptions<EditChildMutation, EditChildMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditChildMutation, EditChildMutationVariables>(EditChildDocument, options);
      }
export type EditChildMutationHookResult = ReturnType<typeof useEditChildMutation>;
export type EditChildMutationResult = Apollo.MutationResult<EditChildMutation>;
export type EditChildMutationOptions = Apollo.BaseMutationOptions<EditChildMutation, EditChildMutationVariables>;
export const ChildDocument = gql`
    query child($id: String!) {
  child(id: $id) {
    _id
    userName
    firstName
    learningMode
    lastName
    dateOfBirth
    grade
    phoneNumber
    email
    image
    studentId
  }
}
    `;

/**
 * __useChildQuery__
 *
 * To run a query within a React component, call `useChildQuery` and pass it any options that fit your needs.
 * When your component renders, `useChildQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChildQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChildQuery(baseOptions: Apollo.QueryHookOptions<ChildQuery, ChildQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChildQuery, ChildQueryVariables>(ChildDocument, options);
      }
export function useChildLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChildQuery, ChildQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChildQuery, ChildQueryVariables>(ChildDocument, options);
        }
export type ChildQueryHookResult = ReturnType<typeof useChildQuery>;
export type ChildLazyQueryHookResult = ReturnType<typeof useChildLazyQuery>;
export type ChildQueryResult = Apollo.QueryResult<ChildQuery, ChildQueryVariables>;
export const CustomerPortalLinkDocument = gql`
    query customerPortalLink {
  customerPortalLink
}
    `;

/**
 * __useCustomerPortalLinkQuery__
 *
 * To run a query within a React component, call `useCustomerPortalLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerPortalLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerPortalLinkQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerPortalLinkQuery(baseOptions?: Apollo.QueryHookOptions<CustomerPortalLinkQuery, CustomerPortalLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerPortalLinkQuery, CustomerPortalLinkQueryVariables>(CustomerPortalLinkDocument, options);
      }
export function useCustomerPortalLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerPortalLinkQuery, CustomerPortalLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerPortalLinkQuery, CustomerPortalLinkQueryVariables>(CustomerPortalLinkDocument, options);
        }
export type CustomerPortalLinkQueryHookResult = ReturnType<typeof useCustomerPortalLinkQuery>;
export type CustomerPortalLinkLazyQueryHookResult = ReturnType<typeof useCustomerPortalLinkLazyQuery>;
export type CustomerPortalLinkQueryResult = Apollo.QueryResult<CustomerPortalLinkQuery, CustomerPortalLinkQueryVariables>;
export const NameExistOnProfileDocument = gql`
    query nameExistOnProfile {
  nameExistOnProfile
}
    `;

/**
 * __useNameExistOnProfileQuery__
 *
 * To run a query within a React component, call `useNameExistOnProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useNameExistOnProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNameExistOnProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useNameExistOnProfileQuery(baseOptions?: Apollo.QueryHookOptions<NameExistOnProfileQuery, NameExistOnProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NameExistOnProfileQuery, NameExistOnProfileQueryVariables>(NameExistOnProfileDocument, options);
      }
export function useNameExistOnProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NameExistOnProfileQuery, NameExistOnProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NameExistOnProfileQuery, NameExistOnProfileQueryVariables>(NameExistOnProfileDocument, options);
        }
export type NameExistOnProfileQueryHookResult = ReturnType<typeof useNameExistOnProfileQuery>;
export type NameExistOnProfileLazyQueryHookResult = ReturnType<typeof useNameExistOnProfileLazyQuery>;
export type NameExistOnProfileQueryResult = Apollo.QueryResult<NameExistOnProfileQuery, NameExistOnProfileQueryVariables>;
export const SaveEducatorNamesDocument = gql`
    mutation saveEducatorNames($input: AddEducatorRequiredInfoInput!) {
  saveEducatorNames(AddEducatorRequiredInfoInput: $input)
}
    `;
export type SaveEducatorNamesMutationFn = Apollo.MutationFunction<SaveEducatorNamesMutation, SaveEducatorNamesMutationVariables>;

/**
 * __useSaveEducatorNamesMutation__
 *
 * To run a mutation, you first call `useSaveEducatorNamesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveEducatorNamesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveEducatorNamesMutation, { data, loading, error }] = useSaveEducatorNamesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveEducatorNamesMutation(baseOptions?: Apollo.MutationHookOptions<SaveEducatorNamesMutation, SaveEducatorNamesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveEducatorNamesMutation, SaveEducatorNamesMutationVariables>(SaveEducatorNamesDocument, options);
      }
export type SaveEducatorNamesMutationHookResult = ReturnType<typeof useSaveEducatorNamesMutation>;
export type SaveEducatorNamesMutationResult = Apollo.MutationResult<SaveEducatorNamesMutation>;
export type SaveEducatorNamesMutationOptions = Apollo.BaseMutationOptions<SaveEducatorNamesMutation, SaveEducatorNamesMutationVariables>;
export const MarkParentTourCompletedDocument = gql`
    mutation markParentTourCompleted {
  markParentTourCompleted
}
    `;
export type MarkParentTourCompletedMutationFn = Apollo.MutationFunction<MarkParentTourCompletedMutation, MarkParentTourCompletedMutationVariables>;

/**
 * __useMarkParentTourCompletedMutation__
 *
 * To run a mutation, you first call `useMarkParentTourCompletedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkParentTourCompletedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markParentTourCompletedMutation, { data, loading, error }] = useMarkParentTourCompletedMutation({
 *   variables: {
 *   },
 * });
 */
export function useMarkParentTourCompletedMutation(baseOptions?: Apollo.MutationHookOptions<MarkParentTourCompletedMutation, MarkParentTourCompletedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkParentTourCompletedMutation, MarkParentTourCompletedMutationVariables>(MarkParentTourCompletedDocument, options);
      }
export type MarkParentTourCompletedMutationHookResult = ReturnType<typeof useMarkParentTourCompletedMutation>;
export type MarkParentTourCompletedMutationResult = Apollo.MutationResult<MarkParentTourCompletedMutation>;
export type MarkParentTourCompletedMutationOptions = Apollo.BaseMutationOptions<MarkParentTourCompletedMutation, MarkParentTourCompletedMutationVariables>;
export const GetAccountTypeDocument = gql`
    query getAccountType {
  getAccountType {
    accountType
  }
}
    `;

/**
 * __useGetAccountTypeQuery__
 *
 * To run a query within a React component, call `useGetAccountTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountTypeQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountTypeQuery, GetAccountTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountTypeQuery, GetAccountTypeQueryVariables>(GetAccountTypeDocument, options);
      }
export function useGetAccountTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountTypeQuery, GetAccountTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountTypeQuery, GetAccountTypeQueryVariables>(GetAccountTypeDocument, options);
        }
export type GetAccountTypeQueryHookResult = ReturnType<typeof useGetAccountTypeQuery>;
export type GetAccountTypeLazyQueryHookResult = ReturnType<typeof useGetAccountTypeLazyQuery>;
export type GetAccountTypeQueryResult = Apollo.QueryResult<GetAccountTypeQuery, GetAccountTypeQueryVariables>;
export const SetAccountTypeDocument = gql`
    mutation setAccountType($accountType: String!) {
  setAccountType(accountType: $accountType) {
    accountType
  }
}
    `;
export type SetAccountTypeMutationFn = Apollo.MutationFunction<SetAccountTypeMutation, SetAccountTypeMutationVariables>;

/**
 * __useSetAccountTypeMutation__
 *
 * To run a mutation, you first call `useSetAccountTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAccountTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAccountTypeMutation, { data, loading, error }] = useSetAccountTypeMutation({
 *   variables: {
 *      accountType: // value for 'accountType'
 *   },
 * });
 */
export function useSetAccountTypeMutation(baseOptions?: Apollo.MutationHookOptions<SetAccountTypeMutation, SetAccountTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetAccountTypeMutation, SetAccountTypeMutationVariables>(SetAccountTypeDocument, options);
      }
export type SetAccountTypeMutationHookResult = ReturnType<typeof useSetAccountTypeMutation>;
export type SetAccountTypeMutationResult = Apollo.MutationResult<SetAccountTypeMutation>;
export type SetAccountTypeMutationOptions = Apollo.BaseMutationOptions<SetAccountTypeMutation, SetAccountTypeMutationVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    