export const paginationLimit = 25
export const coursesPaginationLimit = 25
export const notificationLimit = 15
export const metaverseName = 'metaverse'
export const defaultPage = 1
export const purchaseSessionIdKey = 'purchaseSessionId'
export const schoolioBookStoreUrl = 'https://schoolio.com/'
export const typeform = 'https://1y5zbc9c81z.typeform.com/to/gvcUMdsn'
export const schoolioPricingUrl = 'https://schoolio.com/schoolio-pricing/'
export const notionPublicReleasesUrl =
  'https://schoolioeducation.notion.site/Schoolio-Public-Releases-ea397c5e6af942b096c0b10c1c161d35'

export enum SubscriptionStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Cancelled = 'Canceled',
  Trial = 'Trial',
  PastDue = 'PastDue',
}

export enum LearningMode {
  AutoPilot = 'AutoPilot',
  Scheduled = 'Scheduled',
}

export enum AccountType {
  Family = 'Family',
  Individual = 'Individual',
}

export const maxFamilyChildren = 3

export const globalTrialDays = 7
